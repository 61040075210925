import group from "./group";
import user from "./user";
import flow from "./flow";
import groupWorkHours from "./groupWorkHours";
import teraClientExists from "./teraClientExists";
import teraClientMetadata from "./teraClientMetadata";
import teraBoundUser from "./teraBoundUser";
import userById from "./userById";

export default {
  group,
  user,
  flow,
  groupWorkHours,
  teraClientExists,
  teraClientMetadata,
  teraBoundUser,
  userById,
};
