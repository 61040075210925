import { NodeType } from '../../../types/NodeType'

const instance: NodeType = {
  type: 'itemByIndex',
  label: 'Obter item',
  description: 'Obtém um item pelo índice',
  category: 'Lista',
  inputs() {
    return [
      { type: 'list', name: 'list', label: 'Lista' },
      { type: 'number', name: 'index', label: 'Índice' },
      { type: 'portType', name: 'portType', label: 'Tipo de item' }
    ]
  },
  outputs(inputValues) {
    const result: any[] = [
    ]

    if (inputValues?.portType?.id) {
      result.push({ type: inputValues.portType.id, name: 'item', label: 'Item' })
    }

    return result
  },
  async resolveOutputs(inputs) {
    return { item: inputs.list?.[inputs.index] }
  }
}

export default instance