import { useEffect } from 'react';
import CampoTexto from './CampoTexto';
import CampoNumero from './CampoNumero';
import CampoCheckbox from './CampoCheckbox';
import CampoMultiTexto from './CampoMultiTexto';

type Props = {
  label?: string;
  type: string;
  value: any;
  onChange: (value: any) => void;
  onTypeChanged?: () => void;
  error?: string;
  disabled?: boolean;
};

export const CampoPersonalizacao = ({
  label,
  type,
  value,
  onChange,
  onTypeChanged,
  error,
  disabled,
}: Props) => {
  useEffect(() => {
    onTypeChanged?.();
  }, [type]);

  switch (type) {
    case 'texto':
      return (
        <CampoTexto
          label={label}
          value={value}
          onChange={onChange}
          error={error}
          disabled={disabled}
        />
      );
    case 'numero':
      return (
        <CampoNumero
          label={label}
          value={value}
          onChange={onChange}
          error={error}
          disabled={disabled}
        />
      );
    case 'data':
      return (
        <CampoTexto
          label={label}
          value={value}
          onChange={onChange}
          error={error}
          disabled={disabled}
        />
      );
    case 'booleano':
      return (
        <CampoCheckbox
          label={label}
          text="Sim"
          value={value}
          onChange={onChange}
          error={error}
          disabled={disabled}
        />
      );
    case 'lista':
      return (
        <CampoMultiTexto
          label={label}
          value={value}
          onChange={onChange}
          error={error}
          disabled={disabled}
        />
      );
    default:
      return (
        <CampoTexto
          label={label}
          value={value}
          onChange={onChange}
          error={error}
          disabled={disabled}
        />
      );
  }
};
