import Titulo from '../../components/tipografia/Titulo';
import styles from './page.module.css';
import LayoutUsuario from '../../components/navegacao/LayoutUsuario';
import Descricao from '../../components/tipografia/Descricao';
import HBox from '../../components/layout/HBox';
import Botao from '../../components/Botao';
import VBox from '../../components/layout/VBox';
import CampoTexto from '../../components/Formulario/CampoTexto';
import Icon from '@mdi/react';
import { mdiMagnify, mdiPlus, mdiTrashCan, mdiTrashCanOutline } from '@mdi/js';
import Grid, { GridItem } from '../../components/layout/Grid';
import { useCallback, useEffect, useState } from 'react';
import { useMutation, useQuery } from 'react-query';
import Calendar from 'react-calendar';
import CampoRadio from '../../components/Formulario/CampoRadio';
import CampoSeletor from '../../components/Formulario/CampoSeletor';

import 'react-calendar/dist/Calendar.css';

function capitalizeFirstLetter(string: string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

type PageProps = {};

const Page = ({}: PageProps) => {
  const receiveMessage = useCallback((event: any) => {
    const { data } = event;

    if (data?.origin != window.location.origin) {
      return;
    }

    listQuery.refetch();
  }, []);

  useEffect(() => {
    window.addEventListener('message', receiveMessage, false);

    return () => {
      window.removeEventListener('message', receiveMessage);
    };
  }, []);

  const listQuery = useQuery(['calendario'], async () => {
    const res = await fetch('/api/calendario');
    return res.json();
  });

  const sendObjMutator = useMutation(
    async (obj: any) => {
      setFieldErrors({});
      const res = await fetch('/api/calendario', {
        method: obj?.id > 0 ? 'PUT' : 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(obj),
      });
      return res.json();
    },
    {
      onSuccess: data => {
        if (data.status == 200) {
          listQuery.refetch();
          sendObjMutator.reset();
          setSelectedEvent(data.result);
        } else {
          setFieldErrors(data.errors);
        }
      },
    },
  );

  const delObjMutator = useMutation(
    async (id: number) => {
      const res = await fetch('/api/calendario/' + id, {
        method: 'DELETE',
      });
      return res.json();
    },
    {
      onSuccess: () => {
        listQuery.refetch();
        sendObjMutator.reset();
      },
    },
  );

  const [selectedEvent, setSelectedEvent] = useState<any>(null); // { nome: string, data_evento: Date, data_evento_fim: Date?, acao_evento: 'incluir' | 'excluir' }
  const [selectedDate, setSelectedDate] = useState<
    [Date | null, Date | null] | null
  >([null, null]);
  const [fieldErrors, setFieldErrors] = useState<any>({});
  const [startWithMonth, setStartWithMonth] = useState(new Date().getMonth());

  const months = Array.from(
    { length: 12 },
    (_, index) => startWithMonth + index,
  );

  const eventsHash =
    listQuery.data?.result?.reduce((acc: any, event: any) => {
      for (
        let dt = new Date(event.data_evento);
        dt <= new Date(event.data_evento_fim || event.data_evento);
        dt.setDate(dt.getDate() + 1)
      ) {
        acc[dt.getTime()] = event;
      }

      return acc;
    }, {}) ?? {};

  const handleDateChange = useCallback(
    (date: any) => {
      setFieldErrors({});
      setSelectedDate(date);
      const event = listQuery.data?.result?.find(
        (event: any) =>
          new Date(event.data_evento).getTime() === date[0].getTime(),
      );

      setSelectedEvent(
        event
          ? { ...event }
          : {
              nome: '',
              data_evento: date[0],
              data_evento_fim: date[1],
              acao_evento: 'excluir',
            },
      );
    },
    [listQuery.data?.result],
  );

  const handleSaveEvent = useCallback(() => {
    if (!selectedEvent) return;

    const saveEvent = {
      ...selectedEvent,
      data_evento_fim:
        selectedEvent.data_evento_fim || selectedEvent.data_evento,
    };

    sendObjMutator.mutate(saveEvent);
  }, [selectedEvent]);

  const tileClassName = ({ date, view }: { date: Date; view: string }) => {
    const className: string[] = [];

    const ev = eventsHash[date.getTime()];
    if (ev) {
      if (ev.acao_evento === 'excluir') {
        className.push(styles.excludedDay);
      } else {
        className.push(styles.includedDay);
      }
    } else if (
      view === 'month' &&
      (date.getDay() === 0 || date.getDay() === 6)
    ) {
      className.push(styles.weekendDay);
    }
    // if (view === 'month' && selectedDate && date.getTime() === selectedDate.getTime()) {
    //   className.push('selected-day');
    // }

    return className.join(' ');
  };

  return (
    <LayoutUsuario>
      <style>
        {`.react-calendar__tile--rangeStart {
          border-top-left-radius: 4px;
          border-bottom-left-radius: 4px;
        }
        .react-calendar__tile--rangeEnd {
          border-top-right-radius: 4px;
          border-bottom-right-radius: 4px;
        }`}
      </style>
      <VBox gap="16px" style={{ height: 'calc(100vh - 32px)' }}>
        {/* Header */}
        <HBox style={{ marginBottom: '32px' }}>
          <VBox>
            <Titulo>Calendário</Titulo>
            <Descricao>
              Informe aqui as datas de recesso e de funcionamento excepcional
              para que o chatbot identifique se há ou não expediente
            </Descricao>
          </VBox>
        </HBox>

        {/* Conteudo */}
        <Grid>
          <GridItem sm={12} md={9} lg={10}>
            <Grid
              style={{
                borderBottom: '1px solid var(--tc-color-gray-300)',
                borderTop: '1px solid var(--tc-color-gray-300)',
                padding: '8px 0',
              }}
            >
              <GridItem sm={12} md={6} lg={3}>
                <div>Evento selecionado:</div>
                <div
                  className={[
                    'form-control',
                    fieldErrors?.data_evento ? 'is-invalid' : '',
                  ].join(' ')}
                >
                  {selectedDate &&
                    selectedDate[0] &&
                    !selectedDate[1] &&
                    selectedDate[0].toLocaleDateString()}
                  {selectedDate &&
                    selectedDate[0] &&
                    selectedDate[1] &&
                    `de ${selectedDate[0].toLocaleDateString()} a ${selectedDate[1].toLocaleDateString()}`}

                  {!selectedDate?.[0] && '(Nenhum evento selecionado)'}
                </div>
                {fieldErrors?.data_evento && (
                  <div className="invalid-feedback d-block">
                    {fieldErrors.data_evento}
                  </div>
                )}
              </GridItem>
              <GridItem sm={12} md={6} lg={3}>
                <CampoTexto
                  label="Nome do evento"
                  value={selectedEvent?.nome ?? ''}
                  onChange={v =>
                    setSelectedEvent({
                      ...selectedEvent,
                      nome: v,
                    })
                  }
                  error={fieldErrors?.nome}
                />
              </GridItem>
              <GridItem sm={12} md={6} lg={3}>
                <CampoSeletor
                  label="Tipo de evento"
                  value={selectedEvent?.acao_evento ?? ''}
                  onChange={v =>
                    setSelectedEvent({ ...selectedEvent, acao_evento: v })
                  }
                  options={[
                    { id: 'excluir', label: 'Feriado / Recesso' },
                    { id: 'incluir', label: 'Evento / dia útil' },
                  ]}
                  error={fieldErrors?.acao_evento}
                />
              </GridItem>
              <GridItem sm={12} md={6} lg={3}>
                <div>&nbsp;</div>
                <div>
                  <Botao
                    onClick={handleSaveEvent}
                    disabled={
                      sendObjMutator.isLoading || delObjMutator.isLoading
                    }
                    icon={mdiPlus}
                  >
                    Adicionar
                  </Botao>
                </div>
              </GridItem>
            </Grid>

            <div style={{ marginTop: '16px' }}>
              <label>Começar por</label>
              <HBox>
                <CampoRadio
                  inline
                  id="comecar_por_mes"
                  name="comecar_por"
                  label="Mês atual"
                  checked={startWithMonth == new Date().getMonth()}
                  onChange={() => setStartWithMonth(new Date().getMonth())}
                />
                <CampoRadio
                  inline
                  id="comecar_por_ano"
                  name="comecar_por"
                  label="Ano atual"
                  checked={startWithMonth == 0}
                  onChange={() => setStartWithMonth(0)}
                />
              </HBox>
            </div>

            <Grid>
              {months.map((month, index) => {
                const dt = new Date(new Date().getFullYear(), month, 1);
                return (
                  <GridItem sm={12} md={6} lg={4} key={month}>
                    <h2>
                      {capitalizeFirstLetter(
                        new Intl.DateTimeFormat('pt-BR', {
                          month: 'long',
                        }).format(dt),
                      ) +
                        (month % 12 == 0 || index == 0
                          ? ' ' + dt.getFullYear()
                          : '')}
                    </h2>
                    <Calendar
                      activeStartDate={dt}
                      view="month"
                      showNavigation={false}
                      value={selectedDate}
                      onChange={handleDateChange}
                      tileClassName={tileClassName}
                      allowPartialRange={true}
                      selectRange={true}
                    />
                  </GridItem>
                );
              })}
            </Grid>
          </GridItem>
          <GridItem sm={12} md={3} lg={2}>
            <VBox style={{ position: 'sticky', top: 0 }}>
              <h3>Lista de Eventos:</h3>

              {listQuery.isLoading ? (
                <p style={{ fontSize: '0.8rem' }} className="text-muted">
                  Carregando...
                </p>
              ) : !listQuery.data?.result?.length ? (
                <p style={{ fontSize: '0.8rem' }} className="text-muted">
                  Nenhum resultado encontrado.
                </p>
              ) : listQuery.data?.result?.length == 1 ? (
                <p style={{ fontSize: '0.8rem' }} className="text-muted">
                  Mostrando 1 resultado
                </p>
              ) : (
                <p style={{ fontSize: '0.8rem' }} className="text-muted">
                  Mostrando primeiros {listQuery.data?.result?.length}{' '}
                  resultados
                </p>
              )}

              <div>
                {listQuery.data?.result?.map((event: any, index: number) => {
                  const diaIni = new Date(
                    event.data_evento,
                  ).toLocaleDateString();
                  const diaFim = event.data_evento_fim
                    ? new Date(event.data_evento_fim).toLocaleDateString()
                    : '';

                  const diaStr = diaIni + (diaFim ? ' - ' + diaFim : '');
                  return (
                    <div
                      key={index}
                      style={{
                        padding: '0.25rem',
                        fontSize: '0.9rem',
                      }}
                      className={
                        event.acao_evento == 'incluir'
                          ? styles.includedEvent
                          : styles.excludedEvent
                      }
                    >
                      <div style={{ display: 'flex' }}>
                        <div style={{ flex: 1 }}>
                          <div
                            style={{
                              fontSize: '16px',
                              fontWeight: 600,
                              marginLeft: '1rem',
                            }}
                          >
                            {event.nome}
                          </div>
                          <div style={{ fontSize: '14px' }}>{diaStr}</div>
                          <div style={{ fontSize: '14px' }}>
                            {event.acao_evento == 'incluir'
                              ? 'Expediente'
                              : 'Recesso'}
                          </div>
                        </div>
                        <div>
                          <Botao
                            variant="none-danger"
                            size="sm"
                            disabled={delObjMutator.isLoading}
                            onClick={() => delObjMutator.mutate(event.id)}
                          >
                            <Icon path={mdiTrashCan} size={0.75} />
                          </Botao>
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
            </VBox>
          </GridItem>
        </Grid>
      </VBox>
    </LayoutUsuario>
  );
};

export default Page;
