import { mdiCamera } from '@mdi/js';
import Icon from '@mdi/react';
import React from 'react';
import VBox from './layout/VBox';

const Avatar = ({ imgSrc, thumb }: { imgSrc?: any; thumb?: boolean }) => {
  const [hasAvatar, setHasAvatar] = React.useState<boolean>(false);

  const _imgSrc = imgSrc ?? '/api/auth/me/avatar' + (thumb ? '?thumb' : '');

  React.useEffect(() => {
    const fetchAvatarStatus = async () => {
      const res = await fetch(_imgSrc);
      setHasAvatar(res.status >= 200 && res.status < 300);
    };
    fetchAvatarStatus();
  }, [_imgSrc]);

  if (hasAvatar === false) {
    if (thumb) return null;

    return (
      <VBox center vcenter>
        <div>
          <VBox center>
            <Icon path={mdiCamera} size={2} color="#999" />
            <p className="text-muted">Sem foto</p>
          </VBox>
        </div>
      </VBox>
    );
  } else if (hasAvatar === true) {
    if (thumb)
      return (
        <img
          src={_imgSrc}
          alt="avatar"
          style={{
            width: '2rem',
            height: '2rem',
            objectFit: 'cover',
            borderRadius: '50%',
          }}
        />
      );

    return (
      <img
        src={_imgSrc}
        alt="avatar"
        style={{ width: '100%', height: '100%', objectFit: 'cover' }}
      />
    );
  } else {
    return null;
  }
};

export default Avatar;
