import React from 'react';
import styles from './FooterFinalizado.module.css';
import Icon from '@mdi/react';
import { mdiCheckCircle } from '@mdi/js';

type FooterFinalizadoProps = {};

const FooterFinalizado = ({}:FooterFinalizadoProps) => {
    return (
        <div className={styles[`footer-status-finalizado`]}>
            <div style={{ fontWeight: '600', display: 'flex', flexDirection: 'row', alignItems: 'center', gap: '4px'}}>
                <Icon path={mdiCheckCircle} size="20px" />
                Atendimento finalizado
            </div>
            <div>
                Se precisar, clique em Reabrir para iniciar o atendimento humano.
            </div>
        </div>
    )
};

export default FooterFinalizado;