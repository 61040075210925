import portProps from '@tera/shared/src/fluxoConfig/ports/timestamp'

type Props = {
  value: string | null
  onChange: (value: string) => void
}

const Port = ({ value, onChange }: Props) => {
  return <input type="text" style={{ width: '100%' }} value={value ?? ''} onChange={(e) => onChange(e.target.value)} placeholder="0d 00:00:00" />
}

const port = {
  ...portProps,
  render({ value, onChange }: Props) {
    return <Port value={value} onChange={onChange} />
  }
}

export default port