import CampoTexto from '../../components/Formulario/CampoTexto';
import Grid, { GridItem } from '../../components/layout/Grid';
import axios from 'axios';
import { useQuery } from 'react-query';
import CampoSeletor from '../../components/Formulario/CampoSeletor';
import CampoSenha from '../../components/Formulario/CampoSenha';
import CampoMultiSeletor from '@/components/Formulario/CampoMultiSeletor';
import HBox from '@/components/layout/HBox';
import { CampoSlider } from '@/components/Formulario/CampoSlider';
import CampoMemo from '@/components/Formulario/CampoMemo';

type CorProps = {
  value: string;
  selected: boolean;
  onClick: () => void;
};
const Cor = ({ value, selected, onClick }: CorProps) => {
  return (
    <div
      style={{
        width: '24px',
        height: '24px',
        backgroundColor: value,
        borderRadius: '50%',
        border: '1px solid var(--tc-color-gray-300)',
        outline: selected
          ? '4px solid var(--tc-color-primary-300)'
          : '0px solid var(--tc-color-primary-300)',
        cursor: 'pointer',
        transition: 'outline 100ms',
      }}
      onClick={onClick}
    />
  );
};

// crie cores baseadas nas seguintes: 2f8719, cc3b33, ffaf19, 207edd

const cores = [
  '#b580e3',
  '#c49c73',
  '#80e0e0',
  '#a3cfee',
  '#ffe0a3',
  '#f0a3a3',
  '#a3d4a3',
];

export const FormIAAgente = ({
  value,
  onChange,
  fieldErrors,
}: {
  value: any;
  onChange: any;
  fieldErrors: any;
}) => {
  const newObj = !value?.id || value?.id < 0;

  const gruposQuery = useQuery(['grupos/box'], async () => {
    const res = await axios.get('/api/usuario_grupo/box', {
      withCredentials: true,
    });

    return res.data.result;
  });

  const gruposOptions =
    gruposQuery.data?.map((g: any) => ({ id: g.id, label: g.nome })) ?? [];

  const apisQuery = useQuery(['integracao_api_host/box'], async () => {
    const res = await axios.get('/api/integracao_api_host/box', {
      withCredentials: true,
    });

    return res.data.result;
  });

  const apisOptions = apisQuery.data?.map((g: any) => ({
    id: g.id,
    label: g.nome,
  }));

  const docsQuery = useQuery(['base_conhecimento/box'], async () => {
    const res = await axios.get('/api/base_conhecimento/box', {
      withCredentials: true,
    });

    return res.data.result;
  });

  const docsOptions = docsQuery.data?.map((g: any) => ({
    id: g.id,
    label: g.titulo,
  }));

  return (
    <Grid>
      <div style={{ position: 'absolute', height: 0 }}>
        <input
          type="text"
          name="username"
          style={{
            height: 0,
            width: 0,
            border: 'none',
            outline: 'none',
            margin: 0,
            padding: 0,
          }}
        />
        <input
          type="password"
          name="password"
          style={{
            height: 0,
            width: 0,
            border: 'none',
            outline: 'none',
            margin: 0,
            padding: 0,
          }}
        />
      </div>
      <GridItem sm={12}>
        <CampoTexto
          value={value?.nome ?? ''}
          onChange={v => onChange({ ...value, nome: v })}
          label="Nome do agente"
          error={fieldErrors?.nome}
        />
      </GridItem>
      <GridItem sm={12} md={6}>
        <span>Comportamento</span>
        <div style={{ minWidth: '320px' }}>
          <CampoSeletor
            noSearch
            options={[
              { id: '1', label: 'Conservador: Mais previsível' },
              { id: '2', label: 'Moderado: Mais equilibrado' },
              { id: '3', label: 'Criativo: Mais inovador' },
            ]}
            value={value?.comportamento?.toString()}
            onChange={v => onChange({ ...value, comportamento: parseFloat(v) })}
          />

          {fieldErrors?.comportamento && (
            <small>{fieldErrors.comportamento}</small>
          )}
        </div>
      </GridItem>

      <GridItem sm={12} md={6}>
        <CampoSeletor
          value={value?.grupo_id}
          onChange={v => onChange({ ...value, grupo_id: v })}
          label="Grupo / Departamento"
          options={gruposOptions ?? []}
          error={fieldErrors?.grupo_id}
        />
      </GridItem>

      <GridItem sm={12}>
        <CampoMultiSeletor
          value={value?.apis_permitidas?.map((a: any) => a.id)}
          onChange={v =>
            onChange({ ...value, apis_permitidas: v?.map(it => ({ id: it })) })
          }
          label="APIs permitidas"
          placeholder="(Nenhuma)"
          options={apisOptions ?? []}
          error={fieldErrors?.apis_permitidas}
        />
      </GridItem>

      <GridItem sm={12}>
        <CampoMultiSeletor
          value={value?.docs_permitidos?.map((a: any) => a.id)}
          onChange={v =>
            onChange({ ...value, docs_permitidos: v?.map(it => ({ id: it })) })
          }
          label="Documentos permitidos"
          placeholder="(Nenhum)"
          options={docsOptions ?? []}
          error={fieldErrors?.docs_permitidos}
        />
      </GridItem>

      <GridItem sm={12}>
        <label>
          Quais são as responsabilidades do agente? O que ele pode fazer?
        </label>
        <CampoMemo
          placeholder="Você pode / deve ..."
          value={value?.pode_fazer}
          onChange={v => onChange({ ...value, pode_fazer: v })}
          error={fieldErrors?.pode_fazer}
          rows={3}
        />
      </GridItem>
      <GridItem sm={12}>
        <label>O que o agente NÃO pode fazer?</label>
        <CampoMemo
          placeholder="Você nunca poderá / deverá ..."
          value={value?.nao_pode_fazer}
          onChange={v => onChange({ ...value, nao_pode_fazer: v })}
          error={fieldErrors?.nao_pode_fazer}
          rows={3}
        />
      </GridItem>
    </Grid>
  );
};
