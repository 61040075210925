import { ConteudoTexto } from './ConteudoTexto';
import { ConteudoImagem } from './ConteudoImagem';
import ConteudoMidia from './ConteudoMidia';
import ConteudoVideo from './ConteudoVideo';
import ConteudoAudio from './ConteudoAudio';
import { ChatMensagemDto } from '@tera/shared/src/dto/ChatMensagemDto';

export const Conteudo = ({
  mime,
  value,
}: {
  mime: string;
  value: ChatMensagemDto;
}) => {
  switch (mime) {
    case 'text/message':
      return <ConteudoTexto value={value.conteudo_ou_url} />;
    case 'image/jpeg':
    case 'image/jpg':
    case 'image/png':
    case 'image/gif':
    case 'image/webp':
    case 'image/svg+xml':
      return <ConteudoImagem value={value.conteudo_ou_url} />;
    case 'video/mp4':
      return <ConteudoVideo mime={mime} value={value.conteudo_ou_url} />;
    case 'audio/ogg':
    case 'audio/mp3':
    case 'audio/mpeg':
      return <ConteudoAudio mime={mime} value={value} />;
    default:
      return <ConteudoMidia mime={mime} value={value.conteudo_ou_url} />;
  }
};
