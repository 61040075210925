import { NodeType } from '../../types/NodeType'
import { PortType } from '../../types/PortType'
import { NodeValues } from '../../types/NodeValues'

const instance: NodeType = {
  type: 'api',
  label: 'API (OBSOLETO)',
  description: 'Realiza uma chamada API a um sistema externo e traz os resultados. \n Nota: Este nó está obsoleto, utilize o nó "API" ao invés deste.',
  category: 'API',
  inputs(inputValues?: NodeValues): PortType[] {
    console.log('API inputs inputValues', inputValues)

    const ret: PortType[] = [
      { type: 'route', name: 'route', label: 'Entr. fluxo' },
      { type: 'api', name: 'api', label: 'API', hidePort: true }
    ]

    if (inputValues) {
      const { api } = inputValues
      if (api) {
        const { url } = api
        const regexTemplate = /\{\{([^\}]+?)\}\}/g

        let match;
        while ((match = regexTemplate.exec(url)) !== null) {
          const param = match[1]

          ret.push({ type: 'string', name: param, label: param })
        }

        for (const entrada of api.entradas) {
          ret.push({ type: entrada.tipo, name: entrada.nome, label: entrada.nome, defaultValue: entrada.valorPadrao })
        }
      }
    }

    return ret
  },
  outputs(inputValues) {
    console.log('API outputs inputValues', inputValues)

    const ret: PortType[] = []

    if (inputValues) {
      const { api } = inputValues
      if (api) {
        for (const saida of api.saidas) {
          ret.push({ type: saida.tipo, name: saida.nome, label: saida.nome, defaultValue: saida.valorPadrao })
        }
      }

      ret.push({ type: 'route', name: 'route', label: 'Saída fluxo' })
    }

    return ret
  },
  async resolveOutputs() {
    return null
  }
}

export default instance