import React from 'react';
import styles from './CodeBlock.module.css';
import Icon from '@mdi/react';
import { mdiContentCopy } from '@mdi/js';
import Botao from '../Botao';

type CodeBlockProps = {
    value: string;
};

const CodeBlock = ({ value }:CodeBlockProps) => {
    const [copied, setCopied] = React.useState<boolean>(false);

    return (
        <div className={styles.container}>
            <div className={styles.copyCode}>
                <Botao variant={ copied ? 'outline-primary' : 'outline-secondary' } onClick={() => {
                     navigator.clipboard.writeText(value);
                     setCopied(true);
                        setTimeout(() => setCopied(false), 2000);
                }}>
                    <Icon path={mdiContentCopy} size="1em" />
                    {copied ? 'Copiado!' : 'Copiar'	}
                </Botao>
            </div>
            <pre>{value}</pre>
        </div>
    );
};

export default CodeBlock;