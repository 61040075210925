import { NodeType } from '../../../types/NodeType'

const instance: NodeType = {
  type: 'datetimeCompare',
  label: 'Condicional (Data/hora)',
  description: 'Compara duas datas/horas',
  category: 'Data/hora',
  inputs() {
    return [
      { type: 'route', name: 'route', label: 'Entr. fluxo' },
      { type: 'datetime', name: 'datetime1', label: 'valor 1' },
      { type: 'datetimeOperator', name: 'operator', label: 'operador', hidePort: true },
      { type: 'datetime', name: 'datetime2', label: 'valor 2' }
    ]
  },
  outputs() {
    return [
      { type: 'route', name: 'route-true', label: 'Verdadeiro' },
      { type: 'route', name: 'route-false', label: 'Falso' },
    ]
  },
  async resolveOutputs(inputs) {
    if (inputs.datetime1 == null || !inputs.datetime2 == null) return { route: 'route-false' }

    switch (inputs.datetimeOperator) {
      case 'equals':
        if (inputs.datetime1 == inputs.datetime2) return { route: 'route-true' }
        break
      case 'notEquals':
        if (inputs.datetime1 != inputs.datetime2) return { route: 'route-true' }
        break
      case 'greaterThan':
        if (inputs.datetime1 > inputs.datetime2) return { route: 'route-true' }
        break
      case 'lessThan':
        if (inputs.datetime1 < inputs.datetime2) return { route: 'route-true' }
        break
      case 'greaterThanOrEqual':
        if (inputs.datetime1 >= inputs.datetime2) return { route: 'route-true' }
        break
      case 'lessThanOrEqual':
        if (inputs.datetime1 <= inputs.datetime2) return { route: 'route-true' }
        break
    }
    return { route: 'route-false' }
  }
}

export default instance