import { NodeType } from '../../../types/NodeType'

const instance: NodeType = {
  type: 'objectUnmount',
  label: 'Desestruturador',
  description: 'Converte uma estrutura de dados para valores primitivos',
  category: 'Estrutura de dados',
  inputs() {

    const result = [
      { type: 'objectType', name: 'objectType', label: 'Estrutura esperada' },
      { type: 'object', name: 'object', label: 'Estrutura' },
    ]

    return result
  },
  outputs(inputs) {

    const result: any[] = []

    const metadados = inputs?.objectType?.metadados
    if (metadados) {
      for (const meta of metadados) {
        switch (meta.tipo) {
          case 'string':
          case 'number':
          case 'boolean':
          case 'datetime':
          case 'timestamp':
          case 'object':
          case 'list':
            result.push({ type: meta.tipo, name: meta.nome, label: meta.nome })
            break;
        }
      }
    }


    return result
  },
  async resolveOutputs(inputs) {
    const obj = inputs['object']
    const primitives = {}
    for (const key in obj) {
      primitives[key] = obj[key]
    }

    return primitives
  }
}

export default instance