import React from "react";
import portProps from '@tera/shared/src/fluxoConfig/ports/document'

type Props = {
  value: {
    filename: string
    size: number
    url: string
    mimetype: string
  } | null
  onChange: (value: {
    filename: string
    size: number
    url: string
    mimetype: string
  }) => void
}

const Port = ({ value, onChange }: Props) => {
  const [loading, setLoading] = React.useState(false)

  const uploadFile = React.useCallback(() => {
    const input = document.createElement("input");
    input.type = "file";
    input.accept = ".doc, .docx, .pdf, .xls, .xlsx, .ppt, .pptx";
    input.onchange = (e) => {
      const file = (e?.target as HTMLInputElement)?.files?.[0];
      const reader = new FileReader();
      reader.onload = () => {
        // upload to server
        const formData = new FormData();
        formData.append('file', file as Blob);
        fetch('/api/upload/dialogo', {
          method: 'POST',
          body: formData
        })
          .then(response => response.json())
          .then(data => {
            console.log('Success:', data);
            onChange({
              filename: data.filename,
              size: data.size,
              url: data.location,
              mimetype: data.mimetype,
            })
            setLoading(false)
          })
          .catch((error) => {
            console.error('Error:', error);
            setLoading(false)
          });
      }
      reader.readAsDataURL(file as Blob);
    }
    input.click();
    input.remove();
  }, [])

  return (
    <div>
      <button style={{ width: '100%' }} disabled={loading} onClick={() => uploadFile()}>Upload</button>
      <img src={value?.url} style={{ width: '100%' }} />
    </div>
  )
}

const port = {
  ...portProps,
  render({ value, onChange }: Props) {
    return <Port value={value} onChange={onChange} />

  }
}

export default port