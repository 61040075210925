import { NodeType } from '../../../types/NodeType'

export const objDef = {
  idchamado: { type: 'string', name: 'idchamado', label: 'ID chamado' },
  protocolo: { type: 'string', name: 'protocolo', label: 'Protocolo' },
  titulo: { type: 'string', name: 'titulo', label: 'Título' },
  mensagem: { type: 'string', name: 'mensagem', label: 'Mensagem' },
  mimetype: { type: 'string', name: 'mimetype', label: 'Mimetype' },
  email_cliente: { type: 'string', name: 'email_cliente', label: 'Email cliente' },
  prioridade: { type: 'string', name: 'prioridade', label: 'Prioridade' },
  id_cliente: { type: 'string', name: 'id_cliente', label: 'ID cliente' },
  nomecliente: { type: 'string', name: 'nomecliente', label: 'Nome cliente' },
  departamento: { type: 'string', name: 'departamento', label: 'Departamento' },
  atendente: { type: 'string', name: 'atendente', label: 'Atendente' },
  data_criacao: { type: 'string', name: 'data_criacao', label: 'Data criação' },
  dataultimasituacao: { type: 'string', name: 'dataultimasituacao', label: 'Data última situação' },
  dataencerramento: { type: 'string', name: 'dataencerramento', label: 'Data encerramento' },
  descsituacao: { type: 'string', name: 'descsituacao', label: 'Situação' },
  avaliadoproblemaresolvido: { type: 'string', name: 'avaliadoproblemaresolvido', label: 'Avaliado problema resolvido' },
  avaliadoatendimento: { type: 'string', name: 'avaliadoatendimento', label: 'Avaliado atendimento' },
  ultimasituacao: { type: 'string', name: 'ultimasituacao', label: 'Última situação' },
  deadline: { type: 'datetime', name: 'deadline', label: 'Deadline' },
}

const instance: NodeType = {
  type: 'ticketMount',
  label: '[Tomticket] Estruturar chamado',
  description: 'Estrutura um chamado do TomTicket.',
  category: 'Tomticket',
  inputs() {
    const result: any[] = []

    for (const key in objDef) {
      result.push(objDef[key])
    }
    return result
  },
  outputs() {
    return [
      { type: 'object', name: 'object', label: 'Chamado' },
    ]
  },
  async resolveOutputs(inputs) {
    return { object: inputs }
  }
}

export default instance