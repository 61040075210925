import boolean from './boolean';
import datetime from './datetime';
import list from './list';
import number from './number';
import object from './object';
import string from './string';
import timestamp from './timestamp';

import portProps from '@tera/shared/src/fluxoConfig/ports/portType';

type Props = {
  value: { id: string; nome: string } | null;
  onChange: (value: { id: string; nome: string } | null) => void;
};

const portTypes = [boolean, datetime, list, number, object, string, timestamp];

export const typesList = portTypes.map(it => ({
  id: it.type,
  nome: it.label,
}));

const Port = ({ value, onChange }: Props) => {
  return (
    <div>
      <select
        style={{ width: '100%' }}
        value={value?.id ?? ''}
        onChange={e =>
          onChange(typesList.find(it => it.id === e.target.value) ?? null)
        }
      >
        <option value="">(Selecione)</option>
        {typesList.map(item => (
          <option key={item.id} value={item.id}>
            {item.nome}
          </option>
        ))}
      </select>
    </div>
  );
};

const port = {
  ...portProps,
  render({ value, onChange }: Props) {
    return (
      <div>
        <select
          style={{ width: '100%' }}
          value={value?.id ?? ''}
          onChange={e =>
            onChange(typesList.find(it => it.id === e.target.value) ?? null)
          }
        >
          <option value="">(Selecione)</option>
          {typesList.map(item => (
            <option key={item.id} value={item.id}>
              {item.nome}
            </option>
          ))}
        </select>
      </div>
    );
  },
};

export default port;
