import { NodeType } from '../../../types/NodeType'

const instance: NodeType = {
  type: 'datetimeNow',
  label: 'Data/hora atual',
  description: 'Data/hora atual',
  category: 'Data/hora',
  inputs() {
    return []
  },
  outputs() {
    return [
      { type: 'datetime', name: 'datetime', label: 'Data/hora' }
    ]
  },
  async resolveOutputs() {
    return { datetime: new Date().toISOString() }
  }
}

export default instance