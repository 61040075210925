import React from 'react';
import styles from './Descricao.module.css';

type DescricaoProps = {
    children: React.ReactNode;
};

const Descricao = ({children}:DescricaoProps) => {
    return (
        <div className={styles.container}>{children}</div>
    );
};

export default Descricao;