import React from 'react';
import styles from './FooterChatbot.module.css';
import Icon from '@mdi/react';
import { mdiRobot } from '@mdi/js';

type FooterChatbotProps = {};

const FooterChatbot = ({}:FooterChatbotProps) => {
    return (
        <div className={styles[`footer-status-chatbot`]}>
            <div style={{ fontWeight: '600', display: 'flex', flexDirection: 'row', alignItems: 'center', gap: '4px'}}>
                <Icon path={mdiRobot} size="20px" />
                Chatbot em ação
            </div>
            <div>
                Se precisar, clique em Atender para iniciar o atendimento humano.
            </div>
        </div>
    )
};

export default FooterChatbot;