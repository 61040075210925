import { mdiInformationSlabCircleOutline } from '@mdi/js';
import HBox from '../layout/HBox';
import { Tooltip } from '../layout/Tooltip';
import styles from './CampoTexto.module.css';
import Icon from '@mdi/react';
import React from 'react';

type CampoTextoProps = {
  value?: string;
  onChange?: (value: string) => void;
  label?: string;
  placeholder?: string;
  type?: string;
  required?: boolean;
  prepend?: React.ReactNode;
  append?: React.ReactNode;
  error?: string;
  tooltipText?: React.ReactNode;
  tooltipPosition?: 'top' | 'bottom' | 'left' | 'right';
  noBorder?: boolean;
  maxLength?: number;
  [key: string]: any;
};

export default function CampoTexto({
  value,
  onChange,
  label,
  placeholder,
  type = 'text',
  required,
  prepend,
  append,
  error,
  tooltipText,
  tooltipPosition,
  noBorder,
  maxLength,
  ...props
}: Readonly<CampoTextoProps>) {
  return (
    <div style={{ width: '100%' }}>
      <label>
        <HBox stretch>
          <span>{label}</span>
          {tooltipText && (
            <Tooltip text={tooltipText} position={tooltipPosition}>
              <Icon
                path={mdiInformationSlabCircleOutline}
                size="14px"
                color="var(--tc-color-info)"
              />
            </Tooltip>
          )}
        </HBox>
      </label>
      <div
        className={[styles.container, error ? styles.error : null].join(' ')}
        style={{ border: noBorder ? 'none' : undefined }}
      >
        {prepend && <div className={styles.prepend}>{prepend}</div>}
        <input
          {...props}
          type={type}
          value={value}
          onChange={e =>
            onChange?.(
              !maxLength || maxLength >= e.target.value.length
                ? e.target.value
                : e.target.value.substring(0, maxLength),
            )
          }
          placeholder={placeholder}
          required={required}
        />
        {append && <div className={styles.append}>{append}</div>}
      </div>
      {maxLength && (
        <div
          className={[
            value?.length && value?.length >= maxLength - 15
              ? styles.error
              : null,
            styles.counter,
          ].join(' ')}
        >
          {value?.length || '0'} / {maxLength} caract.
        </div>
      )}
      {error && <div className={styles.error}>{error}</div>}
    </div>
  );
}
