import { NodeType } from '../../types/NodeType'

const instance: NodeType = {
  type: 'start',
  label: 'Início',
  description: 'Inicia um fluxo de atendimento',
  root: true,
  inputs() {
    return []
  },
  outputs() {
    return [
      { type: 'string', name: 'tipoCanal', label: 'tipo Canal' },
      { type: 'string', name: 'nomeUsuarioCanal', label: 'Nome usuário' },
      { type: 'string', name: 'mensagem', label: 'Mensagem' },
      { type: 'string', name: 'idCanalCliente', label: 'ID usuário' },
      { type: 'route', name: 'route', label: 'Fluxo' }
    ]
  },
  async resolveOutputs(data, outputConnections, context) {
    console.log('[start] inputs', data)
    return {
      tipoCanal: context.canal?.tipo ?? '',
      nomeUsuarioCanal: context.nomeUsuarioCanal ?? '',
      mensagem: context.mensagem ?? '',
      idCanalCliente: context.idCanalCliente ?? '',
    }
  }
}

export default instance