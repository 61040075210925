import styles from './Cabecalho.module.css';

const Cabecalho = ({
    children,
    }: Readonly<{
    children: React.ReactNode;
    }>) => {
    return (
        <h2 className={styles.container}>{children}</h2>
    )
}

export default Cabecalho;