import { NodeType } from '../../../types/NodeType'

const instance: NodeType = {
  type: 'activitySendImage',
  label: 'Enviar documento',
  description: 'Envia um documento para o cliente',
  category: 'Chat',
  inputs(inputValues) {

    const result = [
      { type: 'route', name: 'route', label: 'Entr. fluxo' },
      { type: 'document', name: 'documento', label: 'Documento' },
      { type: 'string', name: 'mensagem', label: 'Mensagem' }
    ]

    if (inputValues?.mensagem) {
      const template = inputValues.mensagem
      const re = /\{\{([^\}]+?)\}\}/g

      const ids: string[] = []
      let res: RegExpExecArray | null
      while ((res = re.exec(template)) !== null) {
        if (!ids.includes(res[1])) ids.push(res[1]);
      }
      if (ids.length)
        result.push(...ids.map(id => ({ type: 'string', name: id, label: id })))
    }

    return result
  },
  outputs(/* inputValues, context */) {
    return [
      { type: 'route', name: 'route', label: 'Saída fluxo' }
    ]
  },
  async resolveOutputs() {
    return null
  }
}

export default instance