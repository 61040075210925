import {
  Bar,
  BarChart,
  CartesianGrid,
  Legend,
  Line,
  LineChart,
  ResponsiveContainer,
  XAxis,
  YAxis,
  Tooltip,
} from 'recharts';
import { useCallback, useState } from 'react';
import { useQuery } from 'react-query';
import axios from 'axios';
import HBox from '../../components/layout/HBox';
import Skeleton from '../../components/layout/Skeleton';
import { translateFilter } from './util';

type Props = {
  colorPalette: string[];
  filterCanal: number[];
  periodo: [string, string, string];
};

export const GraficoMarcadores = ({
  colorPalette,
  periodo,
  filterCanal,
}: Props) => {
  const q = useQuery(
    ['marcadores', periodo, filterCanal],
    async () => {
      const qs = new URLSearchParams();

      const f = translateFilter({
        periodo,
        canal_id: filterCanal,
      });

      // qs.append('facts', 'qtd_atendimentos_humanos');
      // qs.append('dimensions', 'data_criacao_mes,usuario_responsavel_id');
      qs.append('filter', f);

      const res = await axios.get(
        '/api/relatorios/marcadores?' + qs.toString(),
      );

      return res.data;
    },
    { refetchOnWindowFocus: false },
  );

  const marcadores =
    q.data?.todosMarcadores?.map((m: any) =>
      m.replace(/[^0-9a-zA-Z]/g, '_').toLowerCase(),
    ) ?? [];

  const dados = q.data
    ? q.data.result.reduce((acc: any[], row: any) => {
        const mes = row.periodo_mes.substring(0, 7);
        if (!acc[acc.length - 1] || acc[acc.length - 1].mes != mes)
          acc.push({ mes, marcadores: {} });
        const curr = acc[acc.length - 1];

        const marcador = row.marcador
          .replace(/[^0-9a-zA-Z]/g, '_')
          .toLowerCase();

        curr.marcadores[marcador] = {
          clientes: +row.clientes,
          conversas: +row.conversas,
          percentual: (+row.conversas * 100) / +row.clientes,
        };

        return acc;
      }, [])
    : [];

  // const dados = q.data ? q.data.reduce((acc: any, row: any) => {
  //   const mes = row.periodo_mes.toISOString().substring(0, 7);
  //   if (!acc[acc.length - 1] || acc[acc.length - 1].mes != mes)
  //     acc.push({
  //       mes,
  //       total: 0,
  //       conversas: row.conversas ?? 0,
  //     });

  //   }, [])
  // : [];

  const [foco, setFoco] = useState<string | null>(null);

  const [abrirModal, setAbrirModal] = useState(false);

  const CustomTooltip = useCallback(
    ({ active, payload, label, separator }: any) => {
      if (active && payload && payload.length) {
        const v = payload.find((p: any) => p.name === label);
        return (
          <div
            style={{
              backgroundColor: '#fff8',
              border: '1px solid #ccc',
              padding: '0.5rem',
            }}
          >
            {JSON.stringify(label)}
            {label != '0' && (
              <p>
                <b>{label}</b>
              </p>
            )}
            <p>
              {label}
              {separator}
              {v}
            </p>
          </div>
        );
      }

      return null;
    },
    [foco],
  );

  const renderDot = ({
    cx,
    cy,
    fill,
    stroke,
    strokeWidth,
    strokeOpacity,
    dataKey,
  }: any) => {
    const r = 7;
    return (
      <svg
        x={cx - r * 4}
        y={cy - r}
        width={r * 8}
        height={r * 2}
        viewBox={`0 0 ${r * 8} ${r * 2}`}
        onMouseEnter={() => setFoco(dataKey)}
        onMouseLeave={() => setFoco(null)}
      >
        <rect x={0} y={0} width={r * 8} height={r * 2} fill="transparent" />
        <circle
          cx={r * 4}
          cy={r}
          r={r}
          fill={fill}
          stroke={stroke}
          strokeWidth={strokeWidth}
          opacity={!foco || dataKey === foco ? strokeOpacity : 0.2}
        />
      </svg>
    );
  };

  const handleMouseEnter = (o: any) => {
    const { dataKey } = o;
    setFoco(dataKey);
  };

  const handleMouseLeave = () => {
    setFoco(null);
  };

  if (q.isFetching) {
    return <Skeleton format="box" />;
  }

  // if (!q.isFetching) {
  //   return (
  //     <div>
  //       <pre>{JSON.stringify(q.data, null, 2)}</pre>
  //     </div>
  //   );
  // }

  return (
    <div style={{ width: '100%' }}>
      {q.isSuccess && dados.length === 0 && (
        <div
          style={{
            width: '100%',
            height: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <p>Nenhum dado encontrado</p>
        </div>
      )}
      {q.isSuccess && dados?.length === 1 && (
        <ResponsiveContainer width="100%" height={250}>
          <BarChart
            margin={{
              top: 5,
              right: 5,
              left: 5,
              bottom: 5,
            }}
            data={dados}
          >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="mes" interval={1} />
            <YAxis />
            <Tooltip
              cursor={{ fill: '#8882' }}
              itemSorter={(item: any) => {
                return -item.value as number;
              }}
              itemStyle={{ padding: 0, fontSize: '0.9rem' }}
              labelStyle={{ fontWeight: 'bold' }}
              // content={<CustomTooltip />}
            />
            <Legend
              onMouseEnter={handleMouseEnter}
              onMouseLeave={handleMouseLeave}
              formatter={(v, e: any, i) => {
                const { dataKey } = e;

                return (
                  <span
                    style={{
                      color: colorPalette[i % colorPalette.length],
                      opacity: !foco || foco === dataKey ? 1 : 0.2,
                      fontWeight: foco && foco === dataKey ? 'bold' : 'normal',
                    }}
                  >
                    {v}
                  </span>
                );
              }}
            />

            {marcadores.map((m: any, index: number) => (
              <Bar
                type="monotone"
                // stackId="a"
                key={m}
                dataKey={`marcadores.${m}.clientes`}
                name={m}
                fill={colorPalette[index % colorPalette.length]}
                maxBarSize={50}
                opacity={!foco || foco === `marcadores.${m}.clientes` ? 1 : 0.2}
                onMouseOver={() => setFoco(`marcadores.${m}.clientes`)}
                onMouseOut={() => setFoco(null)}
              />
            ))}
          </BarChart>
        </ResponsiveContainer>
      )}
      {q.isSuccess && dados?.length > 1 && (
        <HBox>
          <ResponsiveContainer height={200}>
            <LineChart
              margin={{
                top: 5,
                right: 5,
                left: 5,
                bottom: 5,
              }}
              data={dados}
            >
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="mes" interval={1} />
              <YAxis interval={1} />
              <Tooltip
                cursor={{ fill: '#8882' }}
                itemSorter={(item: any) => {
                  return -item.value as number;
                }}
                itemStyle={{ padding: 0, fontSize: '0.9rem' }}
                labelStyle={{ fontWeight: 'bold' }}
                // content={<CustomTooltip />}
              />
              <Legend
                onMouseEnter={handleMouseEnter}
                onMouseLeave={handleMouseLeave}
                formatter={(v, e: any, i) => {
                  const { dataKey } = e;

                  return (
                    <span
                      style={{
                        color: colorPalette[i % colorPalette.length],
                        opacity: !foco || foco === dataKey ? 1 : 0.2,
                        fontWeight:
                          foco && foco === dataKey ? 'bold' : 'normal',
                      }}
                    >
                      {v}
                    </span>
                  );
                }}
              />

              {marcadores.map((m: any, index: number) => (
                <Line
                  type="monotone"
                  // stackId="a"
                  key={m}
                  dataKey={`marcadores.${m}.clientes`}
                  name={m}
                  stroke={colorPalette[index % colorPalette.length]}
                  strokeOpacity={
                    !foco || foco === `marcadores.${m}.clientes` ? 1 : 0.2
                  }
                  strokeDasharray={
                    !foco || foco === `marcadores.${m}.clientes` ? `0` : `5 5`
                  }
                  activeDot={renderDot}
                />
              ))}
            </LineChart>
          </ResponsiveContainer>
        </HBox>
      )}
    </div>
  );
};
