import Titulo from '../../components/tipografia/Titulo';
import LayoutUsuario from '../../components/navegacao/LayoutUsuario';
import VBox from '../../components/layout/VBox';
import { useQuery } from 'react-query';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import Subtitulo from '@/components/tipografia/Subtitulo';
import { format } from 'date-fns';
import HBox from '@/components/layout/HBox';

type Response = {
  status: number;
  ok?: boolean;
  result: any;
};

type Props = {};

const Page = ({}: Props) => {
  const { id } = useParams();

  const queryList = useQuery(['base_conhecimento', id], async () => {
    const res = await axios.get<Response>(`/api/base_conhecimento/${id}`, {
      withCredentials: true,
    });

    return res.data;
  });

  const item = queryList.data?.result;
  const dt = item?.data_ultima_atualizacao
    ? format(item.data_ultima_atualizacao, 'dd/MM/yyyy HH:mm')
    : '';

  return (
    <LayoutUsuario>
      {item && (
        <VBox gap="16px" style={{ height: 'calc(100vh - 32px)' }}>
          <HBox stretch>
            <Titulo>{item.titulo}</Titulo>
            <div style={{ color: 'var(--tc-color-gray-500)' }}>
              Última atualização: {dt}
            </div>
          </HBox>

          {item.secoes?.map((secao: any) => (
            <div
              key={secao.id}
              id={`secao-${secao.id}`}
              style={{ marginBottom: '16px' }}
            >
              <Subtitulo>{secao.titulo}</Subtitulo>

              <p>{secao.conteudo}</p>
            </div>
          ))}
        </VBox>
      )}
    </LayoutUsuario>
  );
};

export default Page;
