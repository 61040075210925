import React from 'react';
import styles from './ArquivoAnexo.module.css';
import Botao from '../../components/Botao';
import Icon from '@mdi/react';
import { mdiCloseCircle } from '@mdi/js';

type ArquivoAnexoProps = {
    arquivo: File
    onRemove: () => void
}

const ArquivoAnexo = ({ arquivo, onRemove }: ArquivoAnexoProps) => {
    const fileURL = URL.createObjectURL(arquivo)
    return (
        <div style={{ margin: '.5rem', height: '96px', maxWidth: '96px' }}>
            <div style={{display: 'flex'}}>
                <span style={{ flex: 1, overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }} title={arquivo.name}>{arquivo.name}</span>
                <Botao variant="none-secondary" onClick={onRemove}>
                    <Icon path={mdiCloseCircle} size="1rem" />
                </Botao>
            </div>
            <div style={{ fontSize: '.75rem', fontStyle: 'italic', color: '#888', textAlign: 'center' }}>({arquivo.type})</div>
            <div style={{ textAlign: 'center' }}>
                {arquivo.type.indexOf('image/') === 0 ? <img src={fileURL} style={{ maxHeight: '42px', maxWidth: '96px' }} alt="Anexo" /> : null}
            </div>
        </div>
    )
}

export default ArquivoAnexo;