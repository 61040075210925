import { useCallback, useId, useLayoutEffect, useRef } from 'react';
import { createNoise2D } from 'simplex-noise';

const noise2D = createNoise2D();
export const AnimatedBackground = ({
  width,
  height,
  pause,
}: {
  width: number;
  height: number;
  pause: boolean;
}) => {
  const id = useId();

  const canvasRef = useRef<HTMLCanvasElement>(null);
  const animationFrameId = useRef<number>();

  const startTime = useRef<number>(0);
  const opacity = useRef<number>(1);

  const draw = useCallback(
    (timestamp: number) => {
      const canvas = canvasRef.current;
      if (!canvas) return;

      if (!startTime.current) startTime.current = timestamp;
      const elapsedTime = timestamp - startTime.current;
      const maxDuration = 0;
      opacity.current = Math.min(1, elapsedTime / maxDuration);

      const ctx = canvas.getContext('2d')!;

      ctx.globalAlpha = Number.isNaN(opacity.current) ? 0 : opacity.current;

      const opHex =
        Number.isNaN(opacity.current) || opacity.current < 0
          ? '00'
          : Math.round(opacity.current * 255)
              .toString(16)
              .padStart(2, '0');

      ctx.fillStyle = '#02172C';
      ctx.fillRect(0, 0, width, height);

      const time = Date.now() / 5000;

      ctx.fillStyle = `#FFFFFF${Math.round(opacity.current * 25)
        .toString(16)
        .padStart(2, '0')}`;
      ctx.beginPath();
      ctx.arc(Math.cos(time) * 40, height * 1.5, height * 1.25, 0, Math.PI * 2);
      ctx.fill();

      ctx.beginPath();
      ctx.arc(width * 1.1, Math.cos(time) * 40, height * 0.75, 0, Math.PI * 2);
      ctx.fill();

      ctx.fillStyle = `#02172C${opHex}`;
      ctx.beginPath();
      ctx.arc(Math.cos(time) * 40, height * 1.5, height * 0.75, 0, Math.PI * 2);
      ctx.fill();

      const lineNum = 25;
      const segmentNum = 50;

      for (let j = 0; j < lineNum; j++) {
        const coefficient = 50 + j;

        ctx.beginPath();
        ctx.strokeStyle = `#40D51C${opHex}`;

        for (let i = 0; i < segmentNum; i++) {
          const x = (i / (segmentNum - 1)) * width;
          const px = i / coefficient;
          const py = j / 50 + time;
          const randomValue = noise2D(px, py) / 2.5 + 0.5;
          const y = randomValue * height;

          if (i === 0) {
            ctx.moveTo(x, y);
          } else {
            ctx.lineTo(x, y);
          }
        }
        ctx.stroke();
      }

      if (!pause) {
        animationFrameId.current = requestAnimationFrame(draw);
      } else {
        console.log('paused');
      }
    },
    [width, height, pause],
  );

  useLayoutEffect(() => {
    animationFrameId.current = requestAnimationFrame(draw);

    const canvas = canvasRef.current!;
    canvas.width = width;
    canvas.height = height;

    return () => {
      if (animationFrameId.current) {
        cancelAnimationFrame(animationFrameId.current);
      }
    };
  }, [pause, width, height, draw]);

  return (
    <canvas
      ref={canvasRef}
      id={id}
      style={{
        position: 'absolute',
        top: 0,
        left: 0,
        zIndex: -1,
        backgroundColor: 'var(--tc-color-brand-secondary)',
        width: '100vw',
        height: '100vh',
      }}
    />
  );
};
