import React from 'react';
import styles from './ConteudoVideo.module.css';

type ConteudoVideoProps = {
    value: string;
    mime: string;
};

const ConteudoVideo = ({ value, mime }:ConteudoVideoProps) => {
    return (
        <div className={styles.container}>
            <video className={styles.video} controls>
                <source src={`/api/assets${value}`} type={mime} />
            </video>
        </div>
    );
};

export default ConteudoVideo;   