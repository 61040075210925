"use client";

import { useNavigate } from 'react-router-dom';
import ApiCall from '../../lib/apiCall';
import { useEffect } from 'react';

const Page = () => {
  const navigate = useNavigate();
  
  useEffect(() => {
  ApiCall.get('/api/logout')
    .then(() => {
      navigate('/login')
    })
    .catch(() => {
      navigate('/login')
    });
  }, [navigate])


    return (
        <div style={{ width: '100vw', height: '100vh', display: 'flex', alignItems: 'center', justifyContent: 'center', gap: '10rem' }}>
            <div>Saindo...</div>
        </div>
    )
}

export default Page;