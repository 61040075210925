import { dateWithoutTimezone } from '../../lib/util';
import axios from 'axios';
import { useMemo } from 'react';
import { useQuery } from 'react-query';
import { parse } from 'date-fns';
import { translateFilter } from './util';

const diasSemana = [
  'Domingo',
  'Segunda',
  'Terça',
  'Quarta',
  'Quinta',
  'Sexta',
  'Sábado',
];

type FilterProps = {
  periodo: [string, string, string];
  canal_id: number[];
  grupo_id: number[];
};

type Props = {
  data: any;
  seletor: string;
} & FilterProps;

const HistogramaMensagens = ({
  seletor,
  periodo,
  canal_id,
  grupo_id,
}: Props) => {
  const q = useQuery(
    ['histogramaMensagens', periodo, canal_id, grupo_id],
    async () => {
      const qs = new URLSearchParams();

      qs.append('filter', translateFilter({ periodo, canal_id, grupo_id }));

      const res = await axios.get(
        '/api/relatorios/histograma_mensagens?' + qs.toString(),
      );

      return res.data.result;
    },
    { refetchOnWindowFocus: false },
  );

  const data = q.data;

  const maxValue = useMemo(() => {
    return data
      ? Object.entries(data).reduce((acc, [k, cur]: [string, any]) => {
          return Math.max(
            acc,
            Math.max(...Object.values(cur).map((c: any) => c[seletor] ?? 0)),
          );
        }, 0)
      : 0;
  }, [data]);

  const shades = useMemo(() => {
    return [
      'color-mix(in srgb, var(--tc-color-primary), transparent 90%)',
      'color-mix(in srgb, var(--tc-color-primary), transparent 80%)',
      'color-mix(in srgb, var(--tc-color-primary), transparent 70%)',
      'color-mix(in srgb, var(--tc-color-primary), transparent 60%)',
      'color-mix(in srgb, var(--tc-color-primary), transparent 50%)',
      'color-mix(in srgb, var(--tc-color-primary), transparent 40%)',
      'color-mix(in srgb, var(--tc-color-primary), transparent 30%)',
      'color-mix(in srgb, var(--tc-color-primary), transparent 20%)',
    ];
  }, []);

  const divider = maxValue / 7;

  return (
    <div style={{ height: '24px' }}>
      {new Array(7).fill(0).map((_, indexw) => (
        <div
          key={indexw}
          style={{
            display: 'flex',
            height: '100%',
            borderTop: '1px solid #10366888',
          }}
        >
          <div style={{ width: '120px' }}>{diasSemana[indexw]}</div>
          {new Array(24).fill(0).map((_, indexh) => {
            const thPos = Math.floor(
              (data?.[indexw]?.[indexh]?.[seletor] ?? 0) / divider,
            );
            return (
              <div
                key={`${indexw}-${indexh}`}
                style={{
                  flex: 1,
                  height: '24px',
                  backgroundColor: !data?.[indexw]?.[indexh]?.[seletor]
                    ? undefined
                    : shades[thPos],
                  color: '#888',
                  textAlign: 'center',
                }}
                title={`${diasSemana[indexw]} as ${indexh}h\n- ${data?.[indexw]?.[indexh]?.['clientes'] ?? '0'} clientes\n- ${data?.[indexw]?.[indexh]?.['mensagens'] ?? '0'} mensagens`}
              ></div>
            );
          })}
        </div>
      ))}

      <div
        style={{
          display: 'flex',
          height: '100%',
          borderTop: '1px solid #10366888',
        }}
      >
        <div style={{ width: '120px' }}>&nbsp;</div>
        {new Array(24).fill(0).map((_, indexh) => (
          <div
            key={indexh}
            style={{ flex: 1, height: '24px', textAlign: 'center' }}
          >
            {indexh}h
          </div>
        ))}
      </div>

      <div
        style={{
          display: 'flex',
          height: '36px',
          borderBottom: '1px solid #10366888',
          justifyContent: 'center',
        }}
      >
        {new Array(7).fill(0).map((_, shadeIdx) => (
          <div key={shadeIdx}>
            <div
              style={{
                display: 'inline-block',
                width: '70px',
                backgroundColor: shades[shadeIdx],
                border: '1px solid #888',
                margin: '0.25rem',
              }}
            >
              &nbsp;
            </div>
            <span style={{ margin: '0 1.5rem 0 0.5rem' }}>
              {Math.max(0, Math.floor((maxValue / 7) * shadeIdx))}-
              {shadeIdx != 6 ? (
                <span>{Math.floor((maxValue / 7) * (shadeIdx + 1)) - 1}</span>
              ) : (
                <span>{Math.floor(maxValue)}</span>
              )}
            </span>
          </div>
        ))}
      </div>
    </div>
  );
};

export default HistogramaMensagens;
