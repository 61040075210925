import portProps from '@tera/shared/src/fluxoConfig/ports/list';

type Props = {
  value: string[] | null;
  onChange: (value: string[]) => void;
};

const Port = ({ value, onChange }: Props) => {
  return (
    <div>
      <button onClick={() => onChange([...(value ?? []), ''])}>
        + Adicionar
      </button>
      {value?.map((item, index) => (
        <div key={index} style={{ display: 'flex', width: '100%' }}>
          <input
            style={{ flex: 1 }}
            type="text"
            value={item}
            onChange={e => {
              const newValue = [...value];
              newValue[index] = e.target.value;
              onChange(newValue);
            }}
          />
          <button
            onClick={() => {
              const newValue = [...value];
              newValue.splice(index, 1);
              onChange(newValue);
            }}
          >
            {' '}
            -{' '}
          </button>
        </div>
      ))}
    </div>
  );
};

const port = {
  ...portProps,
  render({ value, onChange }: Props) {
    return <Port value={value} onChange={onChange} />;
  },
};

export default port;
