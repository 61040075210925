import { NodeType } from '../../../types/NodeType'

const instance: NodeType = {
  type: 'list',
  label: 'Lista',
  description: 'Lista de itens',
  category: 'Lista',
  inputs() {
    return [
      { type: 'list', name: 'list', label: 'Lista', defaultValue: [] }
    ]
  },
  outputs() {
    return [
      { type: 'list', name: 'list', label: 'Lista' },
      { type: 'number', name: 'length', label: 'Tamanho da lista' }
    ]
  },
  async resolveOutputs(inputs) {
    return { list: inputs.list, length: inputs.list?.length ?? 0 }
  }
}

export default instance