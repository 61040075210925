import { NodeType } from '../../../types/NodeType'
import { objDef } from './ticketMount'

const instance: NodeType = {
  type: 'ticketUnmount',
  label: '[Tomticket] Desestruturar chamado',
  description: 'Desestrutura um chamado do TomTicket.',
  category: 'Tomticket',
  inputs() {
    return [
      { type: 'object', name: 'object', label: 'Chamado' },
    ]
  },
  outputs() {
    const result: any[] = []

    for (const key in objDef) {
      result.push(objDef[key])
    }
    return result
  },
  async resolveOutputs(inputs) {
    return inputs.object
  }
}

export default instance