import React from 'react';
import styles from './Titulo.module.css';

type TituloProps = {
    children: React.ReactNode;
};

const Titulo = ({children}:TituloProps) => {
    return (
        <h1 className={styles.container}>{children}</h1>
    );
};

export default Titulo;