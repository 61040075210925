import CampoTexto from '../../components/Formulario/CampoTexto';
import Grid, { GridItem } from '../../components/layout/Grid';
import HBox from '@/components/layout/HBox';
import CampoMemo from '@/components/Formulario/CampoMemo';
import VBox from '@/components/layout/VBox';
import Botao from '@/components/Botao';
import { mdiPlus, mdiTrashCan } from '@mdi/js';
import BotaoPerigoso from '@/components/BotaoPerigoso';

type CorProps = {
  value: string;
  selected: boolean;
  onClick: () => void;
};
const Cor = ({ value, selected, onClick }: CorProps) => {
  return (
    <div
      style={{
        width: '24px',
        height: '24px',
        backgroundColor: value,
        borderRadius: '50%',
        border: '1px solid var(--tc-color-gray-300)',
        outline: selected
          ? '4px solid var(--tc-color-primary-300)'
          : '0px solid var(--tc-color-primary-300)',
        cursor: 'pointer',
        transition: 'outline 100ms',
      }}
      onClick={onClick}
    />
  );
};

// crie cores baseadas nas seguintes: 2f8719, cc3b33, ffaf19, 207edd

const cores = [
  '#b580e3',
  '#c49c73',
  '#80e0e0',
  '#a3cfee',
  '#ffe0a3',
  '#f0a3a3',
  '#a3d4a3',
];

export const FormBaseConhecimento = ({
  value,
  onChange,
  fieldErrors,
}: {
  value: any;
  onChange: any;
  fieldErrors: any;
}) => {
  return (
    <Grid>
      <div style={{ position: 'absolute', height: 0 }}>
        <input
          type="text"
          name="username"
          style={{
            height: 0,
            width: 0,
            border: 'none',
            outline: 'none',
            margin: 0,
            padding: 0,
          }}
        />
        <input
          type="password"
          name="password"
          style={{
            height: 0,
            width: 0,
            border: 'none',
            outline: 'none',
            margin: 0,
            padding: 0,
          }}
        />
      </div>
      <GridItem sm={12}>
        <CampoTexto
          value={value?.titulo ?? ''}
          onChange={v => onChange({ ...value, titulo: v })}
          label="Título"
          error={fieldErrors?.titulo}
          style={{
            fontSize: '20px',
            fontWeight: 'bold',
          }}
        />
      </GridItem>
      {value?.secoes?.map((s: any, i: number) => (
        <GridItem sm={12}>
          <VBox
            style={{
              border: '1px solid var(--tc-color-gray-400)',
              borderRadius: '8px',
              padding: '4px',
            }}
          >
            <CampoTexto
              value={s?.titulo ?? ''}
              style={{ fontWeight: 'bold' }}
              onChange={v =>
                onChange({
                  ...value,
                  secoes: value.secoes.map((ss: any, ii: number) =>
                    ii === i ? { ...ss, titulo: v } : ss,
                  ),
                })
              }
              label="Subtítulo"
              error={fieldErrors?.[`secoes.${i}.titulo`]}
            />
            <CampoMemo
              value={s?.conteudo ?? ''}
              onChange={v =>
                onChange({
                  ...value,
                  secoes: value.secoes.map((ss: any, ii: number) =>
                    ii === i ? { ...ss, conteudo: v } : ss,
                  ),
                })
              }
              label="Conteúdo"
              error={fieldErrors?.[`secoes.${i}.conteudo`]}
            />
            {i !== 0 && (
              <BotaoPerigoso
                variant="outline-danger"
                icon={mdiTrashCan}
                onClick={() =>
                  onChange({
                    ...value,
                    secoes: value.secoes.filter(
                      (_: any, ii: number) => ii !== i,
                    ),
                  })
                }
              >
                Remover seção
              </BotaoPerigoso>
            )}
          </VBox>
        </GridItem>
      ))}
      <GridItem sm={12}>
        <HBox style={{ width: '100%', justifyContent: 'center' }}>
          <Botao
            variant="outline-primary"
            icon={mdiPlus}
            onClick={() => {
              onChange({
                ...value,
                secoes: [...value.secoes, { titulo: '', conteudo: '' }],
              });
            }}
          >
            Adicionar seção
          </Botao>
        </HBox>
      </GridItem>
    </Grid>
  );
};
