import { NodeType } from "../../../types/NodeType";

const instance: NodeType = {
  type: "user",
  label: "Usuário",
  description: "Usuário",
  category: "Domínio",
  inputs() {
    return [{ type: "domainUser", name: "domainUser", label: "Usuário" }];
  },
  outputs() {
    return [{ type: "number", name: "number", label: "ID" }];
  },
  async resolveOutputs(inputs) {
    return {
      number: inputs.domainUser?.id,
    };
  },
};

export default instance;
