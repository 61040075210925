import React from 'react';
import { useQuery } from 'react-query';
import Icon from '@mdi/react';
import { mdiChevronDown, mdiPlus, mdiTrashCan } from '@mdi/js';
import Grid, { GridItem } from '../../components/layout/Grid';
import CampoTexto from '../../components/Formulario/CampoTexto';
import CampoSeletor from '../../components/Formulario/CampoSeletor';
import Botao from '../../components/Botao';
import AlertBox from '../../components/layout/AlertBox';
import CampoCheckbox from '../../components/Formulario/CampoCheckbox';
import HBox from '../../components/layout/HBox';
import styles from './FormConfiguracaoFluxo.module.css';

const diasSemana = [
  { id: 'dom', value: 'Dom' },
  { id: 'seg', value: 'Seg' },
  { id: 'ter', value: 'Ter' },
  { id: 'qua', value: 'Qua' },
  { id: 'qui', value: 'Qui' },
  { id: 'sex', value: 'Sex' },
  { id: 'sab', value: 'Sab' },
];

type FormularioConfiguracaoFluxoProps = {
  value: any;
  onChange: (value: any) => void;
  errors: any;
};

export const FormularioConfiguracaoFluxo = ({
  value,
  onChange,
  errors,
}: FormularioConfiguracaoFluxoProps) => {
  const [showDetails, setShowDetails] = React.useState(false);

  const canalQuery = useQuery(['canal:box'], async () => {
    const res = await fetch('/api/canal/box');
    const result = await res.json();
    return result;
  });

  const inicializacaoOptions = [
    { id: '', label: 'Desabilitado' },
    { id: 'canal', label: 'Resposta automática' },
    { id: 'agendado', label: 'Disparo agendado' },
    { id: 'manual', label: 'Manual' },
  ];

  const toggleDiaAgenda = (index: number, dia: number) => {
    const _agendas = [...(value?.agendas ?? [])];
    const _agenda = _agendas[index];

    _agenda[dia] = !_agenda[dia];
    onChange({ ...value, agendas: _agendas });
  };

  const horas: { id: string; label: string }[] = new Array(24)
    .fill(0)
    .map<{
      id: string;
      label: string;
    }>((_, i) => ({ id: (+i).toString(), label: i.toString().padStart(2, '0') + 'h' }));
  const minutos = ['00', '15', '30', '45'].map<{ id: string; label: string }>(
    v => ({ id: (+v).toString(), label: v + 'm' }),
  );

  const canais =
    canalQuery.data?.result?.map((it: any) => ({
      id: it.id,
      label: it.nome,
    })) ?? [];

  return (
    <Grid>
      <GridItem sm={3} md={2}>
        <CampoTexto
          label="ID do fluxo"
          defaultValue={value?.id ?? ''}
          disabled={true}
          error={errors?.id}
        />
      </GridItem>
      <GridItem sm={9} md={10}>
        <CampoTexto
          label="Nome do fluxo"
          value={value?.nome ?? ''}
          onChange={v => onChange({ ...value, nome: v })}
          error={errors?.nome}
        />
      </GridItem>
      <GridItem sm={12} md={6}>
        <CampoSeletor
          label="Inicialização do fluxo"
          options={inicializacaoOptions}
          optionLabel="value"
          optionValue="id"
          value={value?.inicializacao ?? ''}
          onChange={v => onChange({ ...value, inicializacao: v })}
          error={errors?.inicializacao}
        />
        {value?.inicializacao === 'canal' && (
          <div className="text-muted fst-italic" style={{ fontSize: '0.8rem' }}>
            Responderá automaticamente a mensagens recebidas no canal
            selecionado.
          </div>
        )}
        {value?.inicializacao === 'agendado' && (
          <div className="text-muted fst-italic" style={{ fontSize: '0.8rem' }}>
            Disparará automaticamente nos horários agendados. <br />
            <b>Nota:</b> Este fluxo não deve ter ações interativas, ou não irá
            funcionar.
          </div>
        )}
        {value?.inicializacao === 'manual' && (
          <div className="text-muted fst-italic" style={{ fontSize: '0.8rem' }}>
            Será necessário disparar manualmente via API.
          </div>
        )}
      </GridItem>
      <GridItem sm={12} md={6}>
        <CampoSeletor
          label="Canal atendido"
          options={canais}
          optionLabel="nome"
          optionValue="id"
          value={value?.canal_id ?? ''}
          onChange={v => onChange({ ...value, canal_id: v })}
          error={errors?.canal_id}
        />
      </GridItem>
      {value?.inicializacao === 'agendado' && (
        <GridItem sm={12}>
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              gap: '0.5rem',
            }}
          >
            <label style={{ flex: 1 }}>Horários</label>
            <Botao
              variant="outline-primary"
              size="sm"
              onClick={() =>
                onChange({
                  ...value,
                  agendas: [
                    ...(value?.agendas ?? []),
                    {
                      dom: false,
                      seg: false,
                      ter: false,
                      qua: false,
                      qui: false,
                      sex: false,
                      sab: false,
                      horario: 0,
                    },
                  ],
                })
              }
            >
              <Icon path={mdiPlus} size={0.8} /> Adicionar horário
            </Botao>
          </div>

          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'start',
              gap: '0.5rem',
              width: '100%',
            }}
          >
            {value?.agendas?.map((agenda: any, i: number) => (
              <div
                key={`agenda-${i}-${agenda.dom}-${agenda.seg}-${agenda.ter}-${agenda.qua}-${agenda.qui}-${agenda.sex}-${agenda.sab}-${agenda.horario}`}
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                  gap: '0.5rem',
                  width: '100%',
                }}
              >
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    gap: '0.5rem',
                    flex: 1,
                  }}
                >
                  {diasSemana.map((dia: any, idx: number) => (
                    <Botao
                      key={`dia-${idx}-${dia?.id}`}
                      variant={agenda[dia.id] ? 'primary' : 'outline-primary'}
                      onClick={() => toggleDiaAgenda(i, dia.id)}
                    >
                      {dia.value}
                    </Botao>
                  ))}

                  <CampoSeletor
                    options={horas}
                    optionLabel="value"
                    optionValue="id"
                    value={Math.floor(agenda.horario / 60).toString()}
                    onChange={v => {
                      onChange({
                        ...value,
                        agendas: [
                          ...(value.agendas.slice(0, i) ?? []),
                          {
                            ...agenda,
                            horario: +v * 60 + ((agenda?.horario ?? 0) % 60),
                          },
                          ...(value.agendas.slice(i + 1) ?? []),
                        ],
                      });
                    }}
                  />
                  <CampoSeletor
                    options={minutos}
                    optionLabel="value"
                    optionValue="id"
                    value={Math.round(agenda.horario % 60).toString()}
                    onChange={v =>
                      onChange({
                        ...value,
                        agendas: [
                          ...(value.agendas.slice(0, i) ?? []),
                          {
                            ...agenda,
                            horario:
                              +agenda.horario -
                              ((agenda?.horario ?? 0) % 60) +
                              +v,
                          },
                          ...(value.agendas.slice(i + 1) ?? []),
                        ],
                      })
                    }
                  />
                </div>
                <div>
                  <Botao
                    variant="danger"
                    onClick={() =>
                      onChange({
                        ...value,
                        agendas: value.agendas.filter(
                          (_: any, index: number) => index !== i,
                        ),
                      })
                    }
                  >
                    <Icon path={mdiTrashCan} size={1} />
                  </Botao>
                </div>
              </div>
            ))}
            {errors?.agendas && (
              <div style={{ color: 'red' }}>{errors.agendas}</div>
            )}
          </div>
          {value?.agendas?.length > 0 && (
            <div style={{ marginTop: '1.5rem' }}>
              <CampoCheckbox
                text="Considerar (incluir) feriados"
                value={value?.agendamento_considerar_feriados ?? false}
                onChange={v =>
                  onChange({ ...value, agendamento_considerar_feriados: v })
                }
                error={errors?.agendamento_considerar_feriados}
                disabled={false}
              />
            </div>
          )}
        </GridItem>
      )}

      {errors && Object.keys(errors).length > 0 && (
        <GridItem sm={12}>
          <AlertBox variant="danger">
            <p>Ocorreram alguns problemas ao tentar salvar este diálogo.</p>
            <Botao
              variant="outline-danger"
              onClick={() => setShowDetails(!showDetails)}
            >
              <HBox>
                Detalhes
                <Icon path={mdiChevronDown} size={1} />
              </HBox>
            </Botao>
            {showDetails && (
              <div>
                <ul className={styles.errList}>
                  {Object.keys(errors).map((key: string, idx: number) => (
                    <li key={`err-line-${idx}`}>
                      <b>{key}:</b> {errors[key]}
                    </li>
                  ))}
                </ul>
              </div>
            )}
          </AlertBox>
        </GridItem>
      )}
    </Grid>
  );
};
