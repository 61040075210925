import React from 'react';
import styles from './Skeleton.module.css';

type SkeletonProps = {
    format?: 'text' | 'box' | 'circle' | 'card';
};

const Skeleton = ({ format = 'text' }:SkeletonProps) => {
    return (
        <div className={[styles.skeleton, styles[`skeleton-${format}`]].join(' ')}></div>
    );
};

export default Skeleton;