import { NodeType } from '../../../types/NodeType'

const instance: NodeType = {
  type: 'newList',
  label: 'Criar lista',
  description: 'Cria uma nova lista.',
  category: 'Lista',
  inputs() {
    return [
      { type: 'route', name: 'route', label: 'Entr. fluxo' }
    ]
  },
  outputs() {
    return [
      { type: 'list', name: 'list', label: 'Lista' },
      { type: 'route', name: 'route', label: 'Saída fluxo' }
    ]
  },
  async resolveOutputs(inputs, outConnections, context, localVars) {
    localVars.list = []
    return { list: localVars.list }
  }
}

export default instance