import React from 'react';

type CampoRadioProps = {
  id: string;
  type?: 'radio' | 'checkbox';
  name?: string;
  label: string;
  checked: boolean;
  value?: string;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  disabled?: boolean;
  inline?: boolean;
};

const CampoRadio = (props: CampoRadioProps) => {
  return (
    <div style={{ width: '100%' }}>
      <label>
        <input
          type="radio"
          id={props.id}
          name={props.name}
          checked={props.checked}
          value={props.value}
          onChange={props.onChange}
          disabled={props.disabled}
          style={{ width: '24px' }}
        />
        <span>{props.label}</span>
      </label>
    </div>
  );
};

export default CampoRadio;
