import { useCallback, useEffect, useState } from "react";

export default function useWindowSize(breakpoint = 768) {

    const getSize = useCallback((): [number, number, boolean] => {
      return (typeof window === 'undefined') ? [0, 0, false] : [window.innerWidth, window.innerHeight, window.innerWidth < breakpoint]
    }, [breakpoint]);

    const [size, setSize] = useState<[number, number, boolean]>(getSize());
    useEffect(() => {
      if (typeof window === 'undefined')
        return;

      function updateSize() {
        setSize(getSize());
      }
      window.addEventListener('resize', updateSize);
      updateSize();
      return () => window.removeEventListener('resize', updateSize);
    }, [getSize]);
    return size;
}