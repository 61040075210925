import portProps from '@tera/shared/src/fluxoConfig/ports/number'

type Props = {
  value: number | null
  onChange: (value: number) => void
}

const Port = ({ value, onChange }: Props) => {
  return <input type="tel" style={{width: '100%'}} value={value ?? ''} onChange={(e) => onChange(Number.parseInt(e.target.value))} />
}

const port = { 
  ...portProps,
  render({ value, onChange }: Props) {
    return <Port value={value} onChange={onChange} />
 }
}

export default port