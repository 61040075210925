import { useEffect, useState } from 'react';
import portProps from '@tera/shared/src/fluxoConfig/ports/api';
import axios from 'axios';

type Obj = { id: number; nome: string; host: { nome: string } };

type Props = {
  value: Obj | null;
  onChange: (value: Obj | undefined) => void;
};

const Port = ({ value, onChange }: Props) => {
  const [list, setList] = useState([]);
  useEffect(() => {
    axios.get('/api/integracao_api_endpoint/box').then(response => {
      console.log(response.data.result);
      setList(response.data.result);
    });
  }, []);

  const groups: Record<string, Obj[]> = list?.reduce((acc: any, it: Obj) => {
    const group = it.host?.nome;
    if (!acc[group]) acc[group] = [];
    acc[group].push(it);
    return acc;
  }, {});

  return (
    <div>
      <select
        style={{ width: '100%' }}
        value={value?.id ?? ''}
        onChange={e =>
          onChange(list.find((it: Obj) => it.id?.toString() === e.target.value))
        }
      >
        <option value="">(Selecione)</option>
        {groups &&
          Object.entries(groups).map(([group, items]: [string, Obj[]]) => (
            <optgroup label={group} key={group}>
              {items.map((item: Obj) => (
                <option key={item.id} value={item.id}>
                  {item.nome}
                </option>
              ))}
            </optgroup>
          ))}
      </select>
    </div>
  );
};

const port = {
  ...portProps,
  render({ value, onChange }: Props) {
    return <Port value={value} onChange={onChange} />;
  },
};

export default port;
