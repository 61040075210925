import React, { ReactNode, useEffect, useState } from 'react';
import styles from './Tabs.module.css';
import Icon from '@mdi/react';

export const Tab = () => {};

type TabType = {
  id: string;
  label: ReactNode;
  icon?: string;
  content?: React.ReactNode;
  error?: boolean;
};

// expect children to be an array of Tab components
type TabsProps = {
  tabs: TabType[];
  selectedTab?: string;
  onTabChange?: (id: string) => void;
  size?: 'lg' | 'sm';
  disabled?: boolean;
  position?: 'start' | 'center' | 'end';
  gap?: number | string;
  className?: string;
};

const Tabs = ({
  tabs,
  selectedTab,
  onTabChange,
  size = 'sm',
  disabled,
  position = 'start',
  gap = 0,
  className,
}: TabsProps) => {
  const [selectedIndex, setSelectedIndex] = useState(0);

  const handleIndexChange = (
    e: React.KeyboardEvent<HTMLDivElement> | React.MouseEvent<HTMLDivElement>,
    index: number,
  ) => {
    if (disabled) {
      e.preventDefault();
      e.stopPropagation();
      return;
    }
    console.log('handleIndexChange', index);
    setSelectedIndex(index);
    if (onTabChange) {
      onTabChange(tabs[index].id);
    }
  };

  useEffect(() => {
    const index = tabs.findIndex(tab => tab.id === selectedTab);
    setSelectedIndex(index);
  }, [selectedTab, tabs]);

  const handleKeyDown = (
    e: React.KeyboardEvent<HTMLDivElement>,
    index: number,
  ) => {
    if (disabled) {
      e.preventDefault();
      e.stopPropagation();
      return;
    }

    console.log('handleKeyDown', e.code, index, e.target, e.currentTarget);

    let hit = true;
    switch (e.code.toLowerCase()) {
      case 'space':
      case 'enter':
        handleIndexChange(e, index);
        break;
      default:
        hit = false;
    }
    if (hit) {
      e.preventDefault();
      e.stopPropagation();
    }
  };

  return (
    <div className={className}>
      <div
        className={[styles.header, styles[`position-${position}`]].join(' ')}
        style={{ gap }}
      >
        {tabs.map((tab, index) => (
          <div
            tabIndex={0}
            key={index}
            className={[
              styles.tabHeader,
              selectedIndex === index ? styles.active : null,
              tab.error ? styles.error : null,
              disabled ? styles.disabled : null,
            ].join(' ')}
            onKeyDown={e => handleKeyDown(e, index)}
            onClick={e => handleIndexChange(e, index)}
          >
            {tab.icon && <Icon path={tab.icon} size={0.8} />}
            {(size === 'lg' || selectedIndex === index) && (
              <div style={{ whiteSpace: 'nowrap' }}>{tab.label}</div>
            )}
          </div>
        ))}
      </div>
      <div>{selectedIndex >= 0 && tabs[selectedIndex].content}</div>
    </div>
  );
};

export default Tabs;
