import { NodeType } from '../../../types/NodeType'

const instance: NodeType = {
  type: 'number',
  label: 'Número',
  description: 'Número',
  category: 'Número',
  inputs() {
    return [
      { type: 'number', name: 'number', label: 'Número' }
    ]
  },
  outputs() {
    return [
      { type: 'number', name: 'number', label: 'Número' }
    ]
  },
  async resolveOutputs(inputs) {
    return { number: inputs.number }
  }
}

export default instance