import { NodeType } from '../../../types/NodeType'

const instance: NodeType = {
  type: 'clientExists',
  label: '[Tomticket] Cliente existe?',
  description: 'Verifica se um cliente existe no TomTicket, ou pelo email ou pelo identificador interno.',
  category: 'Tomticket',
  inputs() {
    return [
      { type: 'route', name: 'route', label: 'Entr. fluxo' },
      { type: 'string', name: 'token', label: 'Token' },
      { type: 'string', name: 'email', label: 'Email' },
      { type: 'string', name: 'id_cliente', label: 'ID cliente' }
    ]
  },
  outputs() {

    return [
      { type: 'route', name: 'route-true', label: 'Existe' },
      { type: 'route', name: 'route-false', label: 'Não existe' }
    ]
  },
  async resolveOutputs() {
    return null
  }
}

export default instance