import { NodeType } from '../../../types/NodeType'

const instance: NodeType = {
  type: 'summarizeString',
  label: 'Sumário de texto',
  description: 'Sumariza um texto',
  category: 'IA',
  inputs() {
    const ret = [
      { type: 'route', name: 'route', label: 'Entr. fluxo' },
      { type: 'string', name: 'token', label: 'Token' },
      { type: 'number', name: 'max_caracteres', label: 'Máx. caracteres', defaultValue: 50 },
      { type: 'string', name: 'string', label: 'Texto' }
    ]
    return ret
  },
  outputs() {
    return [
      { type: 'string', name: 'string', label: 'Texto' },
      { type: 'route', name: 'route', label: 'Saída fluxo' },
      { type: 'route', name: 'route-erro', label: 'Erro' },
    ]
  },
  async resolveOutputs() {
    return null
  }
}

export default instance