import Titulo from '../../components/tipografia/Titulo';
import React, { useCallback } from 'react';
import styles from './page.module.css';
import LayoutUsuario from '../../components/navegacao/LayoutUsuario';
import Descricao from '../../components/tipografia/Descricao';
import HBox from '../../components/layout/HBox';
import Botao from '../../components/Botao';
import VBox from '../../components/layout/VBox';
import CampoTexto from '../../components/Formulario/CampoTexto';
import Icon from '@mdi/react';
import {
  mdiArrowLeft,
  mdiLoading,
  mdiMagnify,
  mdiPen,
  mdiPlus,
  mdiTrashCan,
} from '@mdi/js';
import DataTable, { DataTableHeader } from '../../components/layout/DataTable';
import { useInfiniteQuery, useMutation } from 'react-query';
import axios from 'axios';
import { useDebounce } from '../chat/useDebounce';
import Modal from '../../components/layout/Modal';
import { toast } from 'react-toastify';
import BotaoPerigoso from '../../components/BotaoPerigoso';
import Badge, { BadgeVariants } from '../../components/layout/Badge';
import CampoSeletor from '../../components/Formulario/CampoSeletor';
import Tabs from '../../components/layout/Tabs';
import Grid, { GridItem } from '../../components/layout/Grid';
import {
  FormApiEntradas,
  IntegracaoApiEndpointEntrada,
} from './FormApiEntradas';
import { FormApiSaidas, IntegracaoApiEndpointSaida } from './FormApiSaidas';
import {
  FormApiCabecalhos,
  IntegracaoApiEndpointCabecalho,
} from './FormApiCabecalhos';
import Card from '../../components/layout/Card';

type PageProps = {};

type ResponseList = {
  status: number;
  ok?: boolean;
  result: any[];
  total: number;
  cursor: string | number | null;
};

type SelectedTabValues = 'entrada' | 'saida' | 'cabecalho';

const FormChatIntegracaoApiDominio = ({
  value,
  onChange,
  fieldErrors,
  onEndpointSelected,
}: {
  value: any;
  onChange: any;
  fieldErrors: any;
  onEndpointSelected?: (idx: number) => void;
}) => {
  const [selectedEndpoint, setSelectedEndpoint] = React.useState<any>(null);
  const [viewMode, setViewMode] = React.useState<'list' | 'edit'>('list');

  const badgeColors: Record<string, BadgeVariants> = {
    GET: 'info',
    POST: 'success',
    PUT: 'warning',
    DELETE: 'danger',
  };

  const metodoOptions = [
    { id: 'GET', label: 'GET' },
    { id: 'POST', label: 'POST' },
    { id: 'PUT', label: 'PUT' },
    { id: 'DELETE', label: 'DELETE' },
  ];

  const endpointsOptions = value?.endpoints?.map((it: any, idx: number) => ({
    id: it.id?.toString(),
    label: (
      <Grid>
        <GridItem sm={12} md={6}>
          {it.nome}
        </GridItem>
        <GridItem sm={12} md={5}>
          <HBox>
            <Badge
              style={{ minWidth: '60px', justifyContent: 'center' }}
              variant={badgeColors[it.metodo] || 'secondary'}
            >
              {it.metodo}
            </Badge>
            <span style={{ overflow: 'hidden', textOverflow: 'ellipsis' }}>
              {it.caminho}
            </span>
          </HBox>
        </GridItem>
        <GridItem sm={12} md={1}>
          <HBox style={{ justifyContent: 'flex-end' }}>
            <Botao
              variant="none-primary"
              icon={mdiPen}
              title="Editar"
              onClick={() => {}}
            ></Botao>
            <Botao
              variant="none-warning"
              icon={mdiTrashCan}
              title="Editar"
              onClick={() =>
                onChange([
                  ...value.endpoints.filter((e: any) => e.id !== it.id),
                ])
              }
            ></Botao>
          </HBox>
        </GridItem>
      </Grid>
    ),
    error:
      fieldErrors &&
      Object.keys(fieldErrors).some(k => k.startsWith(`endpoints[${idx}].`)),
  }));

  const [endpointSelIdx, setEndpointSelIdx] = React.useState<number>(-1);
  const [endpointSearch, setEndpointSearch] = React.useState<string>('');

  const [selectedTab, setSelectedTab] =
    React.useState<SelectedTabValues>('entrada');

  const handleNewEndpoint = () => {
    const newObj = {
      metodo: 'GET',
      nome: 'Nova requisição',
      caminho: '',
      tipo_corpo: 'json',
      tipo_resposta: 'json',
    };

    onChange({ ...value, endpoints: [...(value?.endpoints ?? []), newObj] });
    setSelectedEndpoint(newObj);
    setEndpointSelIdx(value.endpoints?.length ?? -1); // endpoints ainda não foi atualizado
    onEndpointSelected?.(value.endpoints?.length ?? -1);
    setViewMode('edit');
  };

  const handleRemoveEndpoint = (idx: number) => {
    onChange({
      ...value,
      endpoints: [
        ...(value.endpoints?.filter((_: any, i: number) => i !== idx) ?? []),
      ],
    });

    setSelectedEndpoint(null);
    setEndpointSelIdx(-1);
    onEndpointSelected?.(-1);
    setViewMode('list');
  };

  const handleGoBack = useCallback(() => {
    console.log(selectedEndpoint, endpointSelIdx);
    //save
    onChange({
      ...value,
      endpoints: [
        ...(value.endpoints?.map((it: any, idx: number) =>
          idx === endpointSelIdx ? selectedEndpoint : it,
        ) ?? []),
      ],
    });

    setSelectedEndpoint(null);
    setEndpointSelIdx(-1);
    onEndpointSelected?.(-1);
    setViewMode('list');
  }, [endpointSelIdx, selectedEndpoint, value, onChange, onEndpointSelected]);

  const feEntradas = fieldErrors
    ? Object.keys(fieldErrors).reduce((acc: any, k: string) => {
        const key = `endpoints[${endpointSelIdx}].api_entradas`;
        if (k.startsWith(key)) {
          acc[k.replace(key, '')] = fieldErrors[k];
        }
        return acc;
      }, {})
    : null;

  const feSaidas = fieldErrors
    ? Object.keys(fieldErrors).reduce((acc: any, k: string) => {
        const key = `endpoints[${endpointSelIdx}].api_saidas`;
        if (k.startsWith(key)) {
          acc[k.replace(key, '')] = fieldErrors[k];
        }
        return acc;
      }, {})
    : null;

  const feCabecalhos = fieldErrors
    ? Object.keys(fieldErrors).reduce((acc: any, k: string) => {
        const key = `endpoints[${endpointSelIdx}].api_cabecalhos`;
        if (k.startsWith(key)) {
          acc[k.replace(key, '')] = fieldErrors[k];
        }
        return acc;
      }, {})
    : null;

  return (
    <VBox gap="16px" style={{ minWidth: '80%' }}>
      {viewMode === 'list' && (
        <div>
          <Grid>
            <GridItem sm={12} md={6}>
              <CampoTexto
                value={value?.nome ?? ''}
                onChange={v => onChange({ ...value, nome: v })}
                label="Nome"
                error={fieldErrors?.nome}
              />
            </GridItem>
            <GridItem sm={12} md={6}>
              <CampoTexto
                value={value?.endereco_host ?? ''}
                onChange={v => onChange({ ...value, endereco_host: v })}
                label="URL servidor"
                error={fieldErrors?.endereco_host ?? ''}
                placeholder="Ex: https://api.example.com"
              />
            </GridItem>
          </Grid>
          <div>
            <HBox stretch>
              <label>Endpoints</label>
              <Botao variant="none-primary" onClick={handleNewEndpoint}>
                Adicionar endpoint
              </Botao>
            </HBox>

            {!value?.endpoints?.length && (
              <div style={{ textAlign: 'center', marginTop: '16px' }}>
                Nenhum endpoint cadastrado.
              </div>
            )}
            <Grid>
              {value?.endpoints?.map((it: any, idx: number) => (
                <GridItem md={3} sm={12} key={`${it.id}-${idx}`}>
                  <div
                    style={{ borderRadius: '16px', cursor: 'pointer' }}
                    onClick={() => {
                      setSelectedEndpoint(it);
                      setEndpointSelIdx(idx);
                      onEndpointSelected?.(idx);
                      setViewMode('edit');
                    }}
                  >
                    <Card>
                      <VBox>
                        <div
                          style={{
                            fontWeight: 600,
                            textAlign: 'center',
                            fontSize: '16px',
                          }}
                        >
                          {it.nome}
                        </div>
                        <HBox>
                          <Badge
                            style={{
                              minWidth: '60px',
                              justifyContent: 'center',
                            }}
                            variant={badgeColors[it.metodo] || 'secondary'}
                          >
                            {it.metodo}
                          </Badge>
                          <span
                            style={{
                              overflow: 'hidden',
                              textOverflow: 'ellipsis',
                            }}
                          >
                            {it.caminho}
                          </span>
                        </HBox>

                        <HBox style={{ justifyContent: 'flex-end' }}>
                          <Botao
                            variant="none-primary"
                            icon={mdiPen}
                            title="Editar"
                            onClick={() => {
                              setSelectedEndpoint(it);
                              setViewMode('edit');
                            }}
                          ></Botao>
                          <Botao
                            variant="none-danger"
                            icon={mdiTrashCan}
                            title="Remover"
                            onClick={(e: any) => {
                              e.stopPropagation();
                              e.preventDefault();
                              onChange({
                                ...value,
                                endpoints: value.endpoints.filter(
                                  (e1: any) => e1.id !== it.id,
                                ),
                              });
                            }}
                          ></Botao>
                        </HBox>
                      </VBox>
                    </Card>
                  </div>
                </GridItem>
              ))}
            </Grid>
          </div>
        </div>
      )}
      {viewMode === 'edit' && (
        <VBox>
          <HBox stretch>
            <Botao
              variant="outline-primary"
              onClick={handleGoBack}
              icon={mdiArrowLeft}
            >
              Voltar
            </Botao>
          </HBox>
          <Grid>
            <GridItem sm={12} md={6}>
              <CampoTexto
                value={selectedEndpoint.nome}
                onChange={v =>
                  setSelectedEndpoint({ ...selectedEndpoint, nome: v })
                }
                label="Descrição endpoint"
                error={fieldErrors?.[`endpoints[${endpointSelIdx}].nome`]}
              />
            </GridItem>
            <GridItem sm={12} md={3}>
              <CampoSeletor
                options={[
                  { id: 'json', label: 'JSON' },
                  { id: 'formData', label: 'form-data' },
                  { id: 'urlencoded', label: 'URLEncoded' },
                ]}
                value={selectedEndpoint.tipo_corpo}
                onChange={v =>
                  setSelectedEndpoint({ ...selectedEndpoint, tipo_corpo: v })
                }
                label="Formato entradas"
                error={
                  fieldErrors?.[`endpoints[${endpointSelIdx}].tipo_corpo`] ?? ''
                }
              />
            </GridItem>
            <GridItem sm={12} md={3}>
              <CampoSeletor
                options={[
                  { id: 'json', label: 'JSON' },
                  { id: 'urlencoded', label: 'URLEncoded' },
                ]}
                value={selectedEndpoint.tipo_resposta}
                onChange={v =>
                  setSelectedEndpoint({ ...selectedEndpoint, tipo_resposta: v })
                }
                label="Formato saida"
                error={
                  fieldErrors?.[`endpoints[${endpointSelIdx}].tipo_resposta`] ??
                  ''
                }
              />
            </GridItem>
          </Grid>

          <Grid>
            <GridItem md={3} sm={12}>
              <CampoSeletor
                noSearch={true}
                label="Método"
                value={selectedEndpoint.metodo}
                onChange={v =>
                  setSelectedEndpoint({ ...selectedEndpoint, metodo: v })
                }
                options={metodoOptions}
                error={
                  fieldErrors?.[`endpoints[${endpointSelIdx}].metodo`] ?? ''
                }
              />
            </GridItem>
            <GridItem md={9} sm={12}>
              <CampoTexto
                value={selectedEndpoint.caminho}
                onChange={v =>
                  setSelectedEndpoint({ ...selectedEndpoint, caminho: v })
                }
                label="Caminho"
                placeholder="Ex: /api/v1/endpoint"
                error={
                  fieldErrors?.[`endpoints[${endpointSelIdx}].caminho`] ?? ''
                }
              />
            </GridItem>
          </Grid>

          <Tabs
            tabs={[
              {
                id: 'entrada',
                label: (
                  <HBox>
                    <span>Entradas</span>
                    <Badge variant="secondary">
                      {selectedEndpoint.api_entradas?.length ?? '0'}
                    </Badge>
                  </HBox>
                ),
                content: (
                  <FormApiEntradas
                    fieldErrors={feEntradas}
                    value={selectedEndpoint.api_entradas}
                    onChange={(v: IntegracaoApiEndpointEntrada[]) =>
                      setSelectedEndpoint((prev: any) => ({
                        ...prev,
                        api_entradas: v,
                      }))
                    }
                  />
                ),
                error: feEntradas && Object.keys(feEntradas).length > 0,
              },
              {
                id: 'body',
                label: (
                  <HBox>
                    <span>Saídas</span>
                    <Badge variant="secondary">
                      {selectedEndpoint.api_saidas?.length ?? '0'}
                    </Badge>
                  </HBox>
                ),
                content: (
                  <FormApiSaidas
                    fieldErrors={feSaidas}
                    value={selectedEndpoint.api_saidas}
                    onChange={(v: IntegracaoApiEndpointSaida[]) =>
                      setSelectedEndpoint((prev: any) => ({
                        ...prev,
                        api_saidas: v,
                      }))
                    }
                  />
                ),
                error: feSaidas && Object.keys(feSaidas).length > 0,
              },
              {
                id: 'headers',
                label: (
                  <HBox>
                    <span>Cabeçalhos</span>
                    <Badge variant="secondary">
                      {selectedEndpoint.api_cabecalhos?.length ?? '0'}
                    </Badge>
                  </HBox>
                ),
                content: (
                  <FormApiCabecalhos
                    fieldErrors={feCabecalhos}
                    value={selectedEndpoint.api_cabecalhos}
                    onChange={(v: IntegracaoApiEndpointCabecalho[]) =>
                      setSelectedEndpoint((prev: any) => ({
                        ...prev,
                        api_cabecalhos: v,
                      }))
                    }
                  />
                ),
                error: feCabecalhos && Object.keys(feCabecalhos).length > 0,
              },
            ]}
            size="lg"
            selectedTab={selectedTab}
            onTabChange={v => setSelectedTab(v as SelectedTabValues)}
          />
        </VBox>
      )}
    </VBox>
  );
};

const Page = ({}: PageProps) => {
  const [editObject, setEditObject] = React.useState<any>(null);
  const [fieldErrors, setFieldErrors] = React.useState<Record<
    string,
    string
  > | null>(null);
  const [canCloseModal, setCanCloseModal] = React.useState<boolean>(true);

  const [search, setSearch] = React.useState<string>('');
  const debouncedSearch = useDebounce(search, 500);

  const headers: DataTableHeader[] = [
    { label: 'Nome', width: '50%', column: 'nome' },
    { label: 'URL servidor', width: '50%', column: 'endereco_host' },
    {
      label: 'Ações',
      width: '100px',
      stickRight: true,
      alignRight: true,
      render: (row: any) => {
        return (
          <HBox gap="8px" style={{ justifyContent: 'flex-end' }}>
            <Botao
              variant="none-primary"
              icon={mdiPen}
              title="Editar"
              onClick={() => setEditObject(row as any)}
            ></Botao>
            <BotaoPerigoso
              variant="none-danger"
              icon={mdiTrashCan}
              title="Apagar"
              onClick={() => handleDelete(row.id)}
            />
          </HBox>
        );
      },
    },
  ];

  const queryList = useInfiniteQuery({
    queryKey: ['integracao_api_host', debouncedSearch],
    queryFn: async ({ pageParam }) => {
      const params = new URLSearchParams({
        txt: debouncedSearch,
        c: pageParam,
      });

      return await axios.get<ResponseList>(
        `/api/integracao_api_host?${params.toString()}`,
        {
          withCredentials: true,
        },
      );
    },
    getNextPageParam: lastPage => lastPage.data.cursor ?? null,
  });

  const changeMutator = useMutation(
    async () => {
      if (!editObject) return;

      setFieldErrors(null);
      if (editObject.id) {
        return await axios.put(
          `/api/integracao_api_host/${editObject.id}`,
          editObject,
          {
            withCredentials: true,
          },
        );
      } else {
        return await axios.post(`/api/integracao_api_host`, editObject, {
          withCredentials: true,
        });
      }
    },
    {
      onSuccess: () => {
        setFieldErrors(null);
        queryList.refetch();
        setEditObject(null);
      },
      onError: (err: any) => {
        if (err.response?.status === 422) {
          setFieldErrors(err.response?.data?.fieldErrors ?? {});
        }
        toast(err.response?.data?.msg ?? 'Erro desconhecido', {
          toastId: 'error',
          position: 'top-center',
          type: 'warning',
          autoClose: 5000,
        });
      },
    },
  );

  const deleteMutator = useMutation(
    async (id: number) => {
      return await axios.delete(`/api/integracao_api_host/${id}`, {
        withCredentials: true,
      });
    },
    {
      onSuccess: () => {
        toast('Integração apagada com sucesso', {
          toastId: 'success',
          position: 'top-center',
          type: 'success',
          autoClose: 5000,
        });
        queryList.refetch();
      },
      onError: (err: any) => {
        console.error(err);
        toast('Ocorreu um erro ao tentar apagar a integração.', {
          toastId: 'error',
          position: 'top-center',
          type: 'warning',
          autoClose: 5000,
        });
      },
      onSettled: () => {
        queryList.refetch();
      },
    },
  );

  const handleChange = useCallback(async () => {
    await changeMutator.mutate();
  }, [changeMutator]);

  const handleDelete = (id: number) => {
    deleteMutator.mutate(id);
  };

  const queryFlatList =
    queryList.data?.pages?.map(p => p.data.result)?.flat() ?? [];
  const total =
    queryList.data?.pages?.[queryList.data.pages.length - 1]?.data?.total ?? 0;

  const editMode = !!editObject?.id && editObject.id > 0;

  const handleCloseModal = () => {
    setEditObject(null);
    setFieldErrors(null);
  };

  const handleOpenModalNewObject = () => {
    setEditObject({});
  };

  return (
    <LayoutUsuario>
      <Modal
        ignoreOverlayClick
        size="lg"
        title={editMode ? 'Editar integração' : 'Nova integração'}
        show={!!editObject}
        onClose={handleCloseModal}
        onCancel={handleCloseModal}
        onConfirm={handleChange}
        hideControls={!canCloseModal}
      >
        <FormChatIntegracaoApiDominio
          value={editObject as any}
          onChange={setEditObject}
          fieldErrors={fieldErrors}
          onEndpointSelected={idx => {
            setCanCloseModal(idx == -1);
          }}
        />
      </Modal>
      <VBox gap="16px" style={{ height: 'calc(100vh - 32px)' }}>
        {/* Header */}
        <HBox>
          <VBox>
            <Titulo>Integrações</Titulo>
            <Descricao>
              Gerencie aqui as integrações que estarão disponíveis nos fluxos de
              atendimento.
            </Descricao>
          </VBox>
          <HBox gap="12px" className={styles.headerAcoes}>
            <Botao
              variant="primary"
              icon={mdiPlus}
              onClick={handleOpenModalNewObject}
            >
              Nova integração
            </Botao>
          </HBox>
        </HBox>

        {/* Filtros */}
        <HBox>
          <CampoTexto
            value={search}
            onChange={setSearch}
            prepend={
              <Icon
                path={mdiMagnify}
                size={1}
                color="var(--tc-color-gray-500)"
              />
            }
            placeholder="Pesquisar"
          />
        </HBox>

        {queryList.isFetching && (
          <div>
            Aguarde...{' '}
            <Icon
              path={mdiLoading}
              size="14px"
              color="var(--tc-color-gray-700)"
              spin
            />
          </div>
        )}
        {!queryList.isFetching && (
          <div>
            Exibindo {queryFlatList?.length ?? '0'} de {total ?? '0'}{' '}
            resultados.
          </div>
        )}

        <DataTable
          isLoading={queryList.isFetching}
          cabecalhos={headers}
          linhas={queryFlatList ?? []}
          hasMoreData={queryList.hasNextPage}
          onLoadMore={async () => queryList.fetchNextPage()}
        />
      </VBox>
    </LayoutUsuario>
  );
};

export default Page;
