import Titulo from '../../components/tipografia/Titulo';
import React, { useCallback } from 'react';
import styles from './page.module.css';
import LayoutUsuario from '../../components/navegacao/LayoutUsuario';
import Descricao from '../../components/tipografia/Descricao';
import HBox from '../../components/layout/HBox';
import Botao from '../../components/Botao';
import VBox from '../../components/layout/VBox';
import CampoTexto from '../../components/Formulario/CampoTexto';
import Icon from '@mdi/react';
import {
  mdiLoading,
  mdiMagnify,
  mdiOfficeBuilding,
  mdiOfficeBuildingCog,
  mdiPen,
  mdiPlus,
  mdiTrashCan,
} from '@mdi/js';
import DataTable, { DataTableHeader } from '../../components/layout/DataTable';
import { useInfiniteQuery, useMutation, useQuery } from 'react-query';
import axios from 'axios';
import BotaoPerigoso from '../../components/BotaoPerigoso';
import { useDebounce } from '../chat/useDebounce';
import { toast } from 'react-toastify';
import Modal from '../../components/layout/Modal';
import { FormIAAgente } from './FormIAAgente';
import Grid, { GridItem } from '@/components/layout/Grid';

type ResponseList = {
  status: number;
  ok?: boolean;
  result: any[];
  total: number;
  cursor: string | number | null;
};

type Props = {};

const Page = ({}: Props) => {
  const [editObject, setEditObject] = React.useState<any>(null);
  const [fieldErrors, setFieldErrors] = React.useState<Record<
    string,
    string
  > | null>(null);

  const [search, setSearch] = React.useState<string>('');
  const debouncedSearch = useDebounce(search, 500);

  const headers: DataTableHeader[] = [
    { label: 'Nome', width: '50%', column: 'nome' },
    {
      label: 'Grupo / departamento',
      width: '50%',
      render: row => row?.grupo?.nome,
    },
    {
      label: 'Ações',
      width: '100px',
      stickRight: true,
      alignRight: true,
      render: (row: any) => {
        return (
          <HBox gap="8px" style={{ justifyContent: 'flex-end' }}>
            <Botao
              variant="none-primary"
              icon={mdiPen}
              onClick={() => setEditObject(row)}
            />
            <BotaoPerigoso
              variant="none-danger"
              icon={mdiTrashCan}
              onClick={() => handleDelete(row.id)}
              confirmation="Os fluxos de atendimento deverão ser reconfigurados manualmente, ou os clientes não serão atendidos corretamente.\nContinuar?"
            />
          </HBox>
        );
      },
    },
  ];

  const queryList = useInfiniteQuery({
    queryKey: ['ia_agente', debouncedSearch],
    queryFn: async ({ pageParam }) => {
      const params = new URLSearchParams({
        txt: debouncedSearch,
        c: pageParam,
      });

      return await axios.get<ResponseList>(
        `/api/ia_agente?${params.toString()}`,
        {
          withCredentials: true,
        },
      );
    },
    getNextPageParam: lastPage => lastPage.data.cursor ?? null,
  });

  const changeMutator = useMutation(
    async () => {
      if (!editObject) return;

      setFieldErrors(null);
      if (editObject.id) {
        return await axios.put(`/api/ia_agente/${editObject.id}`, editObject, {
          withCredentials: true,
        });
      } else {
        return await axios.post(`/api/ia_agente`, editObject, {
          withCredentials: true,
        });
      }
    },
    {
      onSuccess: () => {
        setFieldErrors(null);
        queryList.refetch();
        setEditObject(null);
      },
      onError: (err: any) => {
        if (err.response?.status === 422) {
          setFieldErrors(err.response?.data?.fieldErrors ?? {});
        }
        toast(err.response?.data?.msg ?? 'Erro desconhecido', {
          toastId: 'error',
          position: 'top-center',
          type: 'warning',
          autoClose: 5000,
        });
      },
    },
  );

  const deleteMutator = useMutation(
    async (id: number) => {
      return await axios.delete(`/api/ia_agente/${id}`, {
        withCredentials: true,
      });
    },
    {
      onSuccess: () => {
        toast('Agente apagado com sucesso', {
          toastId: 'success',
          position: 'top-center',
          type: 'success',
          autoClose: 5000,
        });
        queryList.refetch();
      },
      onError: (err: any) => {
        console.error(err);
        toast('Ocorreu um erro ao tentar apagar o agente.', {
          toastId: 'error',
          position: 'top-center',
          type: 'warning',
          autoClose: 5000,
        });
      },
      onSettled: () => {
        queryList.refetch();
      },
    },
  );

  const handleChange = useCallback(async () => {
    await changeMutator.mutate();
  }, [changeMutator]);

  const handleDelete = (id: number) => {
    deleteMutator.mutate(id);
  };

  const handleCloseModal = () => {
    setEditObject(null);
    setFieldErrors(null);
  };

  const handleOpenModalNewObject = () => {
    setEditObject({});
  };

  const queryFlatList =
    queryList.data?.pages?.map(p => p.data.result)?.flat() ?? [];
  const total =
    queryList.data?.pages?.[queryList.data.pages.length - 1]?.data?.total ?? 0;
  const editMode = !!editObject?.id && editObject.id > 0;

  const [showOrgDetails, setShowOrgDetails] = React.useState(false);
  const [orgDetails, setOrgDetails] = React.useState<any>(null);

  const handleOpenModalOrgDetails = () => {
    setShowOrgDetails(true);
  };
  const qOrgDetails = useQuery(
    ['organizacao/detalhes'],
    async () => {
      const { data } = await axios.get('/api/organizacao/detalhes', {
        withCredentials: true,
      });
      return data;
    },
    {
      onSuccess: data => {
        setOrgDetails(data.result);
      },
      enabled: showOrgDetails,
      refetchOnWindowFocus: false,
    },
  );
  const orgMutator = useMutation(
    async () => {
      return await axios.put('/api/organizacao/detalhes', orgDetails, {
        withCredentials: true,
      });
    },
    {
      onSuccess: () => {
        toast('Detalhes da empresa atualizados com sucesso', {
          toastId: 'success',
          position: 'top-center',
          type: 'success',
          autoClose: 5000,
        });
        qOrgDetails.refetch();
      },
      onError: (err: any) => {
        console.error(err);
        toast('Ocorreu um erro ao tentar atualizar os detalhes da empresa.', {
          toastId: 'error',
          position: 'top-center',
          type: 'warning',
          autoClose: 5000,
        });
      },
    },
  );

  return (
    <LayoutUsuario>
      <Modal
        title="Detalhes da empresa"
        show={showOrgDetails}
        onClose={() => setShowOrgDetails(false)}
        onCancel={() => setShowOrgDetails(false)}
        onConfirm={() => orgMutator.mutateAsync()}
        cancelProps={{
          disabled: orgMutator.isLoading,
        }}
        confirmProps={{
          label: 'Salvar',
          disabled: orgMutator.isLoading,
        }}
      >
        <p>
          Descreva aqui mais detalhes da empresa, incluindo o ramo de atividade,
          para que os agentes compreendam melhor onde trabalham.
        </p>
        <p>
          Isto é muito importante para que os agentes possam formular respostas
          mais precisas e adequadas ao setor da empresa.
        </p>
        <Grid>
          <GridItem sm={12}>
            <CampoTexto
              label="Descrição da empresa"
              placeholder='Ex.: "Empresa de tecnologia voltada para soluções de ..."'
              value={orgDetails?.descricao_curta}
              onChange={v =>
                setOrgDetails({ ...orgDetails, descricao_curta: v })
              }
              maxLength={255}
            />
          </GridItem>
        </Grid>
      </Modal>

      <Modal
        ignoreOverlayClick
        title={editMode ? 'Editar agente' : 'Novo agente'}
        show={!!editObject}
        onClose={handleCloseModal}
        onCancel={handleCloseModal}
        onConfirm={handleChange}
      >
        <FormIAAgente
          value={editObject as any}
          onChange={setEditObject}
          fieldErrors={fieldErrors}
        />
      </Modal>
      <VBox gap="16px" style={{ height: 'calc(100vh - 32px)' }}>
        {/* Header */}
        <HBox>
          <VBox>
            <Titulo>✨ Agentes de IA</Titulo>
            <Descricao>Gerencie seus agentes aqui.</Descricao>
          </VBox>
          <HBox gap="12px" className={styles.headerAcoes}>
            <Botao
              variant="outline-primary"
              icon={mdiOfficeBuildingCog}
              onClick={handleOpenModalOrgDetails}
            >
              Detalhes da empresa
            </Botao>
            <Botao
              variant="primary"
              icon={mdiPlus}
              onClick={handleOpenModalNewObject}
            >
              Novo
            </Botao>
          </HBox>
        </HBox>

        {/* Filtros */}
        <HBox>
          <CampoTexto
            prepend={
              <Icon
                path={mdiMagnify}
                size={1}
                color="var(--tc-color-gray-500)"
              />
            }
            placeholder="Pesquisar"
            value={search}
            onChange={setSearch}
          />
        </HBox>

        {queryList.isFetching && (
          <div>
            Aguarde...{' '}
            <Icon
              path={mdiLoading}
              size="14px"
              color="var(--tc-color-gray-700)"
              spin
            />
          </div>
        )}
        {!queryList.isFetching && (
          <div>
            Exibindo {queryFlatList?.length ?? '0'} de {total ?? '0'}{' '}
            resultados.
          </div>
        )}

        <DataTable
          isLoading={queryList.isFetching}
          cabecalhos={headers}
          linhas={queryFlatList ?? []}
          hasMoreData={queryList.hasNextPage}
          onLoadMore={async () => queryList.fetchNextPage()}
        />
      </VBox>
    </LayoutUsuario>
  );
};

export default Page;
