import { useCallback, useEffect, useRef, useState } from 'react';
import styles from './CampoSlider.module.css';

const getPercentage = (current: number, max: number): number =>
  (100 * current) / max;

type CampoSliderProps = {
  range: [number] | [number, number];
  onChange: (range: [number, number]) => void;
  min?: number;
  max?: number;
  step?: number;
  subSteps?: number;
  mode?: 'single' | 'range';
};

export const CampoSlider = ({
  range = [0],
  onChange = (arr: any) => {},
  min = 0,
  max = 100,
  step = 1,
  subSteps = 0,
  mode = 'range',
}: CampoSliderProps) => {
  const slider = useRef<HTMLDivElement>(null);
  const thumbMin = useRef<HTMLDivElement>(null);
  const thumbMax = useRef<HTMLDivElement>(null);

  const [dragging, setDragging] = useState<'max' | 'min' | null>(null);
  const [perc0, setPerc0] = useState<number>(range[0]);
  const [perc1, setPerc1] = useState<number | undefined>(range?.[1]);

  useEffect(() => {
    if (range?.[0] != null) {
      const clampedValue = Math.max(min, Math.min(range[0], max));
      setPerc0(getPercentage(clampedValue, max));
    }
    if (range?.[1] != null) {
      const clampedValue = Math.max(range[0], Math.min(range[1], max));
      setPerc1(getPercentage(clampedValue, max));
    }
  }, [range?.[0], range?.[1], max]);

  console.log('perc0', perc0);

  const handleMouseMove = useCallback(
    (e: any) => {
      console.log('handleMouseMove', dragging);
      if (dragging) {
        updatePosition(e.clientX, dragging);
      }
    },
    [dragging],
  );

  const handleMouseUp = useCallback(() => {
    console.log('handleMouseUp', dragging);
    if (dragging) {
      setDragging(null);
    }
  }, [dragging]);

  const handleMouseDown = useCallback(
    (e: any, thumb: any) => {
      console.log('handleMouseDown', thumb, dragging);
      if (!dragging) {
        setDragging(thumb);
        updatePosition(e.clientX, thumb);
        if (thumb === 'min') {
          thumbMin.current!.focus();
        } else if (thumbMax.current) {
          thumbMax.current.focus();
        }
      }
    },
    [dragging],
  );

  useEffect(() => {
    if (dragging) {
      document.addEventListener('mousemove', handleMouseMove);
      document.addEventListener('mouseup', handleMouseUp);
    } else {
      document.removeEventListener('mousemove', handleMouseMove);
      document.removeEventListener('mouseup', handleMouseUp);
    }

    return () => {
      document.removeEventListener('mousemove', handleMouseMove);
      document.removeEventListener('mouseup', handleMouseUp);
    };
  }, [dragging, handleMouseMove, handleMouseUp]);

  const updatePosition = (clientX: number, thumb: any) => {
    if (!slider.current) return;

    const rect = slider.current.getBoundingClientRect();

    const x = clientX - rect.left; // posição x do clique
    const percentage = getPercentage(x, rect.width); // porcentagem do slider

    console.log('percentage', percentage);

    if (percentage < 0 || percentage > 100) {
      return;
    }
    const value = Math.round(((max / 100) * percentage) / step) * step;

    console.log('value', value);

    if (thumb == 'min') {
      const clampedValue = Math.max(
        min,
        Math.min(value, mode == 'range' ? range[1]! : max),
      );
      onChange([clampedValue, range?.[1] ?? 0]);
    } else {
      const clampedValue = Math.max(range[0], Math.min(value, max));
      onChange([range[0], clampedValue]);
    }
  };

  const handleKeyDown = (e: any, thumb: any) => {
    let value;
    if (e.key === 'ArrowRight' || e.key === 'ArrowUp') {
      value = thumb === 'min' ? range[0] + step : range[1]! + step;
    } else if (e.key === 'ArrowLeft' || e.key === 'ArrowDown') {
      value = thumb === 'min' ? range[0] - step : range[1]! - step;
    } else {
      return;
    }

    if (thumb == 'min' && range.length > 1) {
      const clampedValue = Math.max(min, Math.min(value, range[1]!));
      onChange([clampedValue, range[1]!]);
    } else {
      const clampedValue = Math.max(range[0], Math.min(value, max));
      onChange([range[0], clampedValue]);
    }
  };

  return (
    <div className={styles.container}>
      <div
        className={styles.sliderContainerSlider}
        onMouseDown={e => {
          if (!dragging) {
            if (thumbMax.current?.contains(document.activeElement)) {
              setDragging('max');
              updatePosition(e.clientX, 'max');
              setTimeout(() => thumbMax.current!.focus(), 0);
            } else {
              setDragging('min');
              updatePosition(e.clientX, 'min');
              setTimeout(() => thumbMin.current!.focus(), 0);
            }
          }
        }}
      >
        <div className={styles.slider} ref={slider}>
          <div className={styles.sliderTrack} />
          {range.length > 1 && (
            <div
              className={[styles.sliderTrack, styles.sliderTrackRange].join(
                ' ',
              )}
              style={{ left: `${perc0}%`, width: `${perc1! - perc0}%` }}
            />
          )}
          {range.length == 1 && (
            <div
              className={[styles.sliderTrack, styles.sliderTrackSingle].join(
                ' ',
              )}
              style={{ width: `${perc0}%` }}
            />
          )}

          {range?.length > 0 && (
            <div
              ref={thumbMin}
              className={styles.sliderThumb}
              tabIndex={0}
              role="slider"
              style={{ left: `${perc0}%` }}
              aria-valuemin={min}
              aria-valuemax={max}
              aria-valuenow={range[0]}
              onKeyDown={e => handleKeyDown(e, 'min')}
              onMouseDown={e => handleMouseDown(e, 'min')}
            />
          )}
          {range?.length > 1 && (
            <div
              ref={thumbMax}
              className={styles.sliderThumb}
              style={{ left: `${perc1}%`, color: 'red' }}
              tabIndex={0}
              role="slider"
              aria-valuemin={min}
              aria-valuemax={max}
              aria-valuenow={range[1]}
              onKeyDown={e => handleKeyDown(e, 'max')}
              onMouseDown={e => handleMouseDown(e, 'max')}
            />
          )}
        </div>
      </div>
      <div className={styles.sliderSteps}>
        {[...Array(Math.round((max - min) / step) + 1)].map((_, i) => (
          <div
            key={i}
            className={
              subSteps == 0 || i % subSteps == 0
                ? styles.stepMark
                : styles.subStepMark
            }
          />
        ))}
      </div>
    </div>
  );
};
