type ValorProps = {
  valor: number | null;
  prepend?: string | null;
  append?: string | null;
  digits?: number;
  valueSize?: string;
  fractionSize?: string;
};

const Valor = ({
  valor,
  prepend = null,
  append = null,
  digits = 0,
  valueSize = '2.5rem',
  fractionSize = '1.5rem',
}: ValorProps) => {
  const i18num = new Intl.NumberFormat('pt-BR', {
    minimumFractionDigits: digits,
    maximumFractionDigits: digits,
  });

  const vl =
    valor != null
      ? i18num.formatToParts(valor)
      : [{ type: 'value', value: '--' }];

  const fractionIndex = vl.findIndex(v => v.type === 'fraction');

  const vlInt = fractionIndex === -1 ? vl : vl.slice(0, fractionIndex);
  const vlFrac = fractionIndex === -1 ? [] : vl.slice(fractionIndex);

  return (
    <div style={{ display: 'flex', alignItems: 'end' }}>
      <div
        style={{ display: 'flex', flex: 1, alignItems: 'baseline', gap: '4px' }}
      >
        {prepend && (
          <span style={{ color: 'gray', fontWeight: 'bold' }}>{prepend}</span>
        )}
        <span
          style={{
            fontSize: valueSize,
            fontWeight: 'bold',
            color: 'var(--tc-color-primary)',
          }}
        >
          {vlInt.map(v => v.value).join('')}
          <span style={{ fontSize: fractionSize }}>
            {vlFrac.map(v => v.value).join('')}
          </span>
        </span>
        {append && (
          <span style={{ color: 'gray', fontWeight: 'bold' }}>{append}</span>
        )}
      </div>
    </div>
  );
};

export default Valor;
