import React from 'react';
import styles from './Footer.module.css';
import { ChatDtoEstado } from '@tera/shared/src/dto/ChatDto';
import FooterChatbot from './FooterChatbot';
import { FooterAtendimento } from './FooterAtendimento';
import FooterFinalizado from './FooterFinalizado';

type FooterProps = {
  estado: ChatDtoEstado;
  onSend: (mensagem: string, anexos: File[]) => void;
  isSending: boolean;
  onSendAudio: (audio: Blob) => void;

  chatId: number;
  onChatAddTag: (chatId: number, tagId: number) => void;
  chatTags?: string[];
};
const Footer = ({
  estado,
  onSend,
  isSending,
  onSendAudio,
  chatId,
  onChatAddTag,
  chatTags,
}: FooterProps) => {
  switch (estado) {
    case 'chatbot':
      return <FooterChatbot />;
    case 'atendimento':
      return (
        <FooterAtendimento
          chatId={chatId}
          onChatAddTag={onChatAddTag}
          chatTags={chatTags}
          onSend={onSend}
          isSending={isSending}
          onSendAudio={onSendAudio}
        />
      );
    case 'finalizado':
      return <FooterFinalizado />;
    case 'aguardando_cliente':
    case 'aguardando_atendente':
      return <FooterChatbot />;
    default:
      return null;
  }
};

export default Footer;
