import { NodeType } from '../../../types/NodeType'

const instance: NodeType = {
  type: 'clientCreate',
  label: '[Tomticket] Criar cliente',
  description: 'Cria um cliente no TomTicket.',
  category: 'Tomticket',
  inputs() {
    return [
      { type: 'route', name: 'route', label: 'Entr. fluxo' },
      { type: 'string', name: 'token', label: 'Token' },
      { type: 'string', name: 'identificador', label: 'Identificador' },
      { type: 'string', name: 'nome', label: 'Nome' },
      { type: 'string', name: 'email', label: 'Email' },
      { type: 'string', name: 'telefone', label: 'Telefone' },
      { type: 'string', name: 'id_organizacao', label: 'ID Organização' },
    ]
  },
  outputs() {

    return [
      { type: 'route', name: 'route', label: 'Saída fluxo' },
      { type: 'route', name: 'route-erro', label: 'Erro' },
    ]
  },
  async resolveOutputs() {
    return null
  }
}

export default instance