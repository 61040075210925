import { NodeType } from '../../../types/NodeType'

const instance: NodeType = {
  type: 'orgFind',
  label: '[Tomticket] Procurar organização',
  description: 'Procura por uma organização no TomTicket.',
  category: 'Tomticket',
  inputs() {
    return [
      { type: 'route', name: 'route', label: 'Entr. fluxo' },
      { type: 'string', name: 'token', label: 'Token' },
      { type: 'string', name: 'email', label: 'Email' },
      { type: 'string', name: 'id_cliente', label: 'ID cliente' },
      { type: 'string', name: 'nome_campo', label: 'Campo a procurar' },
      { type: 'string', name: 'valor_campo', label: 'Valor a procurar' },
    ]
  },
  outputs() {

    return [
      { type: 'string', name: 'id', label: 'ID' },
      { type: 'string', name: 'nome', label: 'Nome' },
      { type: 'route', name: 'route-true', label: 'Encontrado' },
      { type: 'route', name: 'route-false', label: 'Não encontrado' },
    ]
  },
  async resolveOutputs() {
    return null
  }
}

export default instance