import React from 'react';
import styles from './Card.module.css';
import VBox from '../../components/layout/VBox';
import HBox from '../../components/layout/HBox';
import Botao from '../../components/Botao';
import { mdiPen, mdiPlay, mdiTrashCan } from '@mdi/js';
import BotaoPerigoso from '../../components/BotaoPerigoso';
import IconeCanal from '../../components/layout/IconeCanal';
import { Link } from 'react-router-dom';

type CardProps = {
  item: any;
  onRemove: () => void;
  onPlay?: () => void;
};

const CardContents = ({ item, onRemove, onPlay }: CardProps) => {
  return (
    <VBox>
      <div className={styles.title}>{item.nome}</div>
      <HBox>
        <IconeCanal tipo={item.canal?.tipo} style={{ height: '16px' }} />
        <div className={styles.description}>
          {item.canal?.nome ?? '(Sem canal)'}
        </div>
      </HBox>
      <hr style={{ color: 'var(--tc-color-gray-200)', height: 1 }} />
      <HBox stretch>
        <div className={styles.qtd} style={{ flex: 1 }}>
          [#{item.id}] {item.qtdBlocos} blocos, {item.qtdConexoes} conexões
        </div>
        <HBox style={{ flex: '0 1' }}>
          {onPlay && (
            <Botao
              variant="none-info"
              icon={mdiPlay}
              title="Iniciar conversa"
              onClick={(e: any) => {
                e.preventDefault();
                e.stopPropagation();
                onPlay?.();
              }}
            ></Botao>
          )}
          {item.versao !== 1 && (
            <Botao
              variant="none-primary"
              icon={mdiPen}
              href={`/dialogo/${item.id}`}
            ></Botao>
          )}
          <BotaoPerigoso
            variant="none-danger"
            icon={mdiTrashCan}
            confirmation={
              <div>
                <p>
                  Se excluir o fluxo, algumas conversas automatizadas podem
                  parar de funcionar.
                </p>
                <p>Tem certeza que deseja excluir?</p>
              </div>
            }
            onClick={onRemove}
          ></BotaoPerigoso>
        </HBox>
      </HBox>
    </VBox>
  );
};

const Card = ({ item, onRemove, onPlay }: CardProps) => {
  if (item.versao === 1)
    return (
      <div className={styles.container}>
        <CardContents item={item} onRemove={onRemove} onPlay={onPlay} />
      </div>
    );

  return (
    <Link
      className={styles.container}
      style={{ cursor: 'pointer' }}
      to={`/dialogo/${item.id}`}
    >
      <CardContents item={item} onRemove={onRemove} onPlay={onPlay} />
    </Link>
  );
};

export default Card;
