import { NodeType } from '../../../types/NodeType'

const instance: NodeType = {
  type: 'extractDatetime',
  label: 'Extrair Data/hora',
  description: 'Extrai Data/hora a partir de um texto',
  category: 'Data/hora',
  inputs() {
    return [
      { type: 'route', name: 'route', label: 'Entr. fluxo' },
      { type: 'string', name: 'string', label: 'Texto' }
    ]
  },
  outputs() {
    return [
      { type: 'datetime', name: 'data-ini', label: 'Data/hora inicial' },
      { type: 'datetime', name: 'data-fim', label: 'Data/hora final' },
      { type: 'route', name: 'route', label: 'Saída fluxo' },
      { type: 'route', name: 'route-invalido', label: 'Data/hora inválida' }
    ]
  },
  async resolveOutputs(inputs, outConnections, context, localVars) {
    return localVars.resposta ? { resposta: localVars.resposta } : null
  }
}

export default instance