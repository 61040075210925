import { NodeType } from '../../../types/NodeType'

const instance: NodeType = {
  type: 'activityTransferToGroup',
  label: 'Redirecionar para departamento',
  description: 'Redireciona o atendimento para um departamento',
  category: 'Chat',
  inputs(inputValues) {
    const result = [
      { type: 'route', name: 'route', label: 'Entr. fluxo' },
      { type: 'string', name: 'mensagem', label: 'Mensagem' },
      { type: 'number', name: 'id_grupo', label: 'Departamento' },
    ]

    if (inputValues?.mensagem) {
      const template = inputValues.mensagem
      const re = /\{\{([^\}]+?)\}\}/g

      const ids: string[] = []
      let res: RegExpExecArray | null
      while ((res = re.exec(template)) !== null) {
        if (!ids.includes(res[1])) ids.push(res[1]);
      }
      if (ids.length)
        result.push(...ids.map(id => ({ type: 'string', name: id, label: id })))
    }

    return result
  },
  outputs(/* inputValues, context */) {
    return [
      { type: 'route', name: 'route-inatividade', label: 'Inatividade' },
      { type: 'route', name: 'route-expirado', label: 'T. atend. expirado' },
      { type: 'route', name: 'route-expediente', label: 'Fora do expediente' },
      { type: 'route', name: 'route-finalizado', label: 'Atendimento finalizado' },
    ]
  },
  async resolveOutputs() {
    return null
  }
}

export default instance