import portProps from '@tera/shared/src/fluxoConfig/ports/datetime'

type Props = {
  value: string | null
  onChange: (value: string) => void
}

const Port = ({ value, onChange }: Props) => {
  return <input type="date" style={{ width: '100%' }} value={value ?? ''} onChange={(e) => onChange(e.target.value)} />
}
const port = {
  ...portProps,
  render({ value, onChange }: Props) {
    return <Port value={value} onChange={onChange} />
  }
}

export default port