import Icon from '@mdi/react';
import CampoTexto from '../../components/Formulario/CampoTexto';
import AlertBox from '../../components/layout/AlertBox';
import LayoutUsuario from '../../components/navegacao/LayoutUsuario';
import TituloChat from './TituloChat';
import styles from './page.module.css';
import {
  mdiCheckCircle,
  mdiHeadset,
  mdiInformationOutline,
  mdiMagnify,
  mdiMessageProcessing,
  mdiOpenInNew,
  mdiRobot,
} from '@mdi/js';
import CampoSeletor from '../../components/Formulario/CampoSeletor';
import Tabs from '../../components/layout/Tabs';
import Conversas from './Conversas';
import AlertasAtendimento from './AlertasAtendimento';
import Mensagens from './Mensagens';
import ChatDto from '@tera/shared/src/dto/ChatDto';
import React, { useCallback, useEffect, useState } from 'react';
import useWebsocket from './useWebsocket';
import {
  ChatMessagePayload,
  ChatMessagePayloadFile,
} from '@tera/shared/src/types/ChatMessagePayload';
import { useDebounce } from './useDebounce';
import axios from 'axios';
import { useQuery } from 'react-query';
import IconeCanal from '../../components/layout/IconeCanal';
import HBox from '../../components/layout/HBox';
import Modal from '../../components/layout/Modal';
import { useAuth } from '../../lib/useAuth';
import Subtitulo from '@/components/tipografia/Subtitulo';
import Titulo from '@/components/tipografia/Titulo';
import VBox from '@/components/layout/VBox';

type ModalAbrirChatMensagemProgramadaProps = {
  canalId: number | null;
  open: boolean;
  onClose: () => void;
  onConfirm: (mensagemId: string) => void;
};

const ModalAbrirChatMensagemProgramada = ({
  canalId,
  open,
  onClose,
  onConfirm,
}: ModalAbrirChatMensagemProgramadaProps) => {
  const [idMensagem, setIdMensagem] = useState<string | null>(null);

  const mensagensQuery = useQuery(
    ['mensagem_programada', canalId],
    () => {
      return fetch(
        '/api/mensagem_programada?fi=' +
          encodeURIComponent(JSON.stringify({ canal_id: ['eq', canalId] })),
      ).then(res => res.json());
    },
    {
      staleTime: 30000,
      onSuccess: () => {
        console.log('mensagensQuery returned');
      },
    },
  );

  const mensagens =
    mensagensQuery.data?.result?.map((msg: any) => ({
      id: msg.id,
      label: msg.nome,
    })) ?? [];

  const mensagem = mensagensQuery.data?.result?.find(
    (msg: any) => msg.id === idMensagem,
  );

  return (
    <Modal
      title="Abrir chat"
      show={open}
      onClose={onClose}
      onConfirm={() => onConfirm(idMensagem!)}
      confirmProps={{
        label: 'Enviar',
        disabled: !idMensagem,
      }}
      cancelProps={{
        label: 'Cancelar',
      }}
    >
      <CampoSeletor
        label="Selecione a mensagem programada a enviar para o cliente"
        options={mensagens}
        value={idMensagem ?? undefined}
        onChange={setIdMensagem}
        optionLabel="nome"
        optionValue="id"
      />
      <div
        style={{
          marginTop: '1rem',
          fontSize: '1.2rem',
          fontWeight: 'lighter',
          textAlign: 'center',
        }}
      >
        {mensagem?.conteudo ?? '(nenhuma mensagem selecionada)'}
      </div>

      <AlertBox variant="info" style={{ marginTop: '1rem' }}>
        <Icon path={mdiInformationOutline} size={1} />
        <b>Nota:</b> Outras conversas em aberto com o cliente serão encerradas
      </AlertBox>
    </Modal>
  );
};

const pubKey = process.env.REACT_APP_PUB_KEY;

const Page = () => {
  const [userInteracted, setUserInteracted] = React.useState<boolean>(false);
  const [selectedChatIndex, setSelectedChatIndex] = React.useState<number>(-1);
  const [chatEstadoIsLoading, setChatEstadoIsLoading] =
    React.useState<boolean>(false);

  const [confirmCloseChat, setConfirmCloseChat] =
    React.useState<boolean>(false);
  const [confirmOpenChat, setConfirmOpenChat] = React.useState<boolean>(false);
  const [notificationStatus, setNotificationStatus] =
    React.useState<NotificationPermission>('default');

  const [query, setQuery] = React.useState<{
    search?: string;
    chatStatus?: string;
    canalId?: number | null;
  }>({ search: '', chatStatus: '', canalId: null });
  const debouncedQuery = useDebounce(query, 650);

  const { user } = useAuth();

  const {
    socket: websocket,
    wsState,
    chatState,
    chatAddTag,
    reloadChats,
    selectChat,
    loadMoreChats,
    sendMessage,
    atenderFila,
    sendNotification,
    sendAudio,
    loadMore,
  } = useWebsocket({
    userId: user?.id,
    query: debouncedQuery,
    onLoadingChats: (status: boolean) => {
      console.log('reload chats', status);
    },
    onChatsUpdated: (chats: ChatDto[]) => {
      console.log('chats updated', chats);
    },
  });

  const selectedChat = chatState.chats?.find(
    (chat: any) => chat.canal_cliente_id === chatState.selectedChatClientId,
  );
  const chatCanBeOpened = selectedChat
    ? selectedChat.estado !== 'atendimento' &&
      new Date().getTime() -
        new Date(selectedChat.ultmsg_data_criacao).getTime() <
        23 * 50 * 60 * 1000
    : false;

  const loadMoreMessages = useCallback(
    (dt: string) => {
      console.log('is fetching? ', chatState.loadingMessages);
      if (chatState.loadingMessages) return;

      console.log('fetching more');

      loadMore(dt);
    },
    [chatState.loadingMessages, loadMore],
  );

  const handleSelectedChatIndexChange = useCallback(
    (index: number) => {
      console.log('selected chat index', index, 'chat', chatState.chats[index]);
      selectChat(chatState.chats[index]);
      setSelectedChatIndex(index);
    },
    [chatState.chats, selectChat],
  );

  const handleChatEstadoChange = useCallback(
    (estado: string) => {
      setChatEstadoIsLoading(() => true);
      try {
        websocket.emit('chat.state', {
          chat_id: chatState.chats[selectedChatIndex].id,
          state: estado,
        });
      } finally {
        setChatEstadoIsLoading(() => false);
      }
    },
    [selectedChatIndex, websocket, chatState.chats],
  );

  const onChatEstadoChange = useCallback(
    async (estado: string): Promise<void> => {
      if (estado === 'finalizado') {
        setConfirmCloseChat(true);
        return;
      }

      if (estado === 'atendimento' && !chatCanBeOpened) {
        setConfirmOpenChat(true);
        return;
      }

      handleChatEstadoChange(estado);
    },
    [handleChatEstadoChange, chatCanBeOpened],
  );

  const handleSend = useCallback(
    async (message: string, attachments: File[]) => {
      const _attachments = await Promise.all(
        attachments.map(
          async f =>
            ({
              name: f.name,
              size: f.size,
              type: f.type,
              buffer: await f.arrayBuffer(),
            }) as ChatMessagePayloadFile,
        ),
      );

      const msg: ChatMessagePayload = {
        chat_id: chatState.chats[selectedChatIndex].id,
        message,
        attachments: _attachments,
        __trace: 5,
      };

      sendMessage(msg);
    },
    [selectedChatIndex, chatState.chats, sendMessage],
  );

  const handleSendAudio = useCallback(
    async (audio: Blob) => {
      sendAudio(chatState.chats[selectedChatIndex].id, audio);
    },
    [chatState.chats, selectedChatIndex, sendAudio],
  );

  const queryCanais = useQuery(['canal.box'], async () => {
    const { data } = await axios.get('/api/canal/box');
    return data;
  });

  const canais = [
    {
      id: '',
      label: <span style={{ paddingLeft: '22px' }}>Todos os canais</span>,
    },
  ];
  if (queryCanais.isSuccess) {
    canais.push(
      ...queryCanais.data.result
        .map((c: any) => ({
          id: c.id,
          sort: c.nome,
          label: (
            <HBox gap="8px">
              <IconeCanal tipo={c.tipo} style={{ height: '14px' }} />
              {c.nome}
            </HBox>
          ),
        }))
        .sort((a: any, b: any) => a.sort.localeCompare(b.sort)),
    );
  }

  function subscribeUserToPush() {
    return navigator.serviceWorker
      .register('/service-worker.js')
      .then(function (reg) {
        let serviceWorker;
        if (reg.installing) {
          serviceWorker = reg.installing;
          // console.log('Service worker installing');
        } else if (reg.waiting) {
          serviceWorker = reg.waiting;
          // console.log('Service worker installed & waiting');
        } else if (reg.active) {
          serviceWorker = reg.active;
          // console.log('Service worker active');
        }

        if (serviceWorker) {
          console.log('sw current state', serviceWorker.state);
          if (serviceWorker.state === 'activated') {
            //If push subscription wasnt done yet have to do here
            console.log('sw already activated - Do watever needed here');
          }
          serviceWorker.addEventListener('statechange', function (e: any) {
            console.log('sw statechange : ', e.target.state);
            if (e.target.state === 'activated') {
              // use pushManger for subscribing here.
              console.log(
                'Just now activated. now we can subscribe for push notification',
              );
              const subscribeOptions = {
                userVisibleOnly: true,
                applicationServerKey: pubKey,
              };

              reg.pushManager
                .subscribe(subscribeOptions)
                .then(function (pushSubscription) {
                  fetch('/api/notificacao', {
                    method: 'POST',
                    body: JSON.stringify({ token: pushSubscription }),
                    headers: {
                      'Content-Type': 'application/json',
                    },
                  })
                    .then(res => res.json())
                    .then(res => {
                      console.log(res);
                    });
                });
            }
          });
        }
      });
  }

  function askPermission() {
    Notification.requestPermission(function (result) {
      setNotificationStatus(result);
      if (result !== 'granted') {
        console.warn("We weren't granted permission.");
      } else {
        subscribeUserToPush();
      }
    });
  }

  useEffect(() => {
    axios
      .get('/api/chat/user_prefs')
      .then(res => {
        const { data } = res;

        const newQuery = { search: data?.search, chatStatus: data?.chatStatus };
        setQuery(newQuery);

        console.log('startup');
      })
      .finally(() => {
        console.log('connecting to socket');
        websocket.connect();
      });

    return () => {
      console.log('unloading component');
      websocket.disconnect();
    };
  }, [websocket]);

  useEffect(() => {
    if (userInteracted) return;

    const firstInteractionHandler = () => {
      setUserInteracted(true);
    };

    window.addEventListener('click', firstInteractionHandler);
    window.addEventListener('touchstart', firstInteractionHandler);

    return () => {
      window.removeEventListener('click', firstInteractionHandler);
      window.removeEventListener('touchstart', firstInteractionHandler);
    };
  }, [userInteracted]);

  useEffect(() => {
    if (chatState.chats?.[selectedChatIndex]?.id !== chatState.selectedChatId) {
      const index = chatState.chats.findIndex(
        (c: any) => c.id === chatState.selectedChatId,
      );
      setSelectedChatIndex(index);
    }
  }, [
    chatState.chats,
    chatState.selectedChatId,
    selectedChatIndex,
    handleSelectedChatIndexChange,
  ]);

  useEffect(() => {
    setNotificationStatus(
      !('serviceWorker' in navigator) || !('PushManager' in window)
        ? 'denied'
        : Notification.permission,
    );
  }, []);

  useEffect(() => {
    console.log('saving preferences', debouncedQuery);

    axios.post('/api/chat/user_prefs', debouncedQuery).then(() => {
      console.log('reload by preferences change');
      reloadChats(debouncedQuery);
    });
  }, [debouncedQuery]);

  const [selectedKbSection, setSelectedKbSection] = useState<any>(null);

  return (
    <LayoutUsuario>
      {selectedKbSection && (
        <Modal
          size="md"
          title="Base de conhecimento"
          show={!!selectedKbSection}
          onClose={() => setSelectedKbSection(null)}
          onCancel={() => setSelectedKbSection(null)}
          confirmProps={{
            label: (
              <span>
                Ver documentação completa{' '}
                <Icon path={mdiOpenInNew} size="16px" />
              </span>
            ),
            href: `/base_conhecimento/${selectedKbSection?.id}/view#secao-${selectedKbSection?.secao_id}`,
            linkTarget: '_blank',
          }}
        >
          <VBox>
            <Titulo>{selectedKbSection?.titulo}</Titulo>

            <p style={{ fontSize: '20px', fontWeight: 'bold' }}>
              {selectedKbSection?.secao_titulo}
            </p>

            <p>{selectedKbSection?.secao_conteudo}</p>

            <AlertBox variant="info">
              Nota: Ver a documentação completa abrirá uma nova aba. Você pode
              fechá-la depois para voltar a este chat.
            </AlertBox>
          </VBox>
        </Modal>
      )}

      <Modal
        title="Finalizar atendimento?"
        show={confirmCloseChat}
        onClose={() => setConfirmCloseChat(false)}
        onConfirm={() => {
          setConfirmCloseChat(false);
          handleChatEstadoChange('finalizado');
        }}
        confirmProps={{
          label: 'Finalizar',
        }}
        cancelProps={{
          label: 'Continuar atendimento',
        }}
      >
        <p>Tem certeza que deseja fechar este chat?</p>
      </Modal>

      <ModalAbrirChatMensagemProgramada
        canalId={selectedChat?.canal_id || selectedChat?.canal?.id}
        open={confirmOpenChat}
        onClose={() => setConfirmOpenChat(false)}
        onConfirm={(mensagemId: string) => {
          setConfirmOpenChat(false);
          sendNotification(
            selectedChat.id,
            selectedChat.canal_cliente_id,
            +mensagemId,
          );
          // handleChatEstadoChange('aguardando_cliente')
        }}
      />

      <div className={styles.container}>
        <div
          className={[
            styles.conversasContainer,
            selectedChatIndex > -1 ? styles.open : null,
          ].join(' ')}
        >
          <div className={styles.conversasHeader}>
            <TituloChat
              wsState={wsState}
              loading={chatState.loadingChats}
              onReloadChats={reloadChats}
              notificationStatus={notificationStatus}
              onAskPermission={askPermission}
            />
            {!userInteracted && (
              <AlertBox variant="warning">
                Clique aqui para ativar o som de novos atendimentos
              </AlertBox>
            )}
            <CampoTexto
              prepend={
                <Icon
                  path={mdiMagnify}
                  size={1}
                  color="var(--tc-color-gray-500)"
                />
              }
              placeholder="Pesquisar"
              value={query.search ?? ''}
              onChange={v => setQuery({ ...query, search: v })}
            />
            <CampoSeletor
              disabled={chatState.loadingChats}
              placeholder="Todos os canais"
              options={canais}
              value={query.canalId?.toString() ?? ''}
              onChange={v => setQuery({ ...query, canalId: +v ?? null })}
            />

            <Tabs
              className={styles.tabs}
              selectedTab={query.chatStatus}
              disabled={chatState.loadingChats}
              tabs={[
                { id: '', label: 'Todos', icon: mdiMessageProcessing },
                { id: 'chatbot', label: 'Chatbots', icon: mdiRobot },
                { id: 'atendimento', label: 'Atendimento', icon: mdiHeadset },
                {
                  id: 'finalizado',
                  label: 'Finalizados',
                  icon: mdiCheckCircle,
                },
              ]}
              onTabChange={tab => setQuery({ ...query, chatStatus: tab })}
            />
          </div>
          <AlertasAtendimento
            value={chatState.fila}
            userInteracted={userInteracted}
            onAtender={chatId => atenderFila(chatId)}
          />
          <Conversas
            value={chatState.chats}
            selectedIndex={selectedChatIndex}
            setSelectedIndex={handleSelectedChatIndexChange}
            loadingChats={chatState.loadingChats}
            hasMoreChats={chatState.hasMoreChats}
            onLoadMoreChats={loadMoreChats}
          />
        </div>
        <div
          className={[
            styles.mensagensContainer,
            selectedChatIndex > -1 ? styles.open : null,
          ].join(' ')}
        >
          {selectedChatIndex > -1 && (
            <Mensagens
              chat={chatState.chats[selectedChatIndex]}
              value={chatState.selectedChatMessages}
              onChatEstadoChange={onChatEstadoChange}
              onSend={handleSend}
              onReloadChats={reloadChats}
              onChatAddTag={chatAddTag}
              isSending={chatState.sendingMessage}
              onSendAudio={handleSendAudio}
              isLoading={chatState.loadingMessages}
              hasMorePages={chatState.hasMorePages}
              onLoadMore={loadMoreMessages}
              onSelectedKbSection={setSelectedKbSection}
              onGoBack={() => handleSelectedChatIndexChange(-1)}
            />
          )}
          {selectedChatIndex === -1 && (
            <div className={styles.mensagensPlaceholder}>
              <Icon
                path={mdiMessageProcessing}
                size="96px"
                color="var(--tc-color-gray-300)"
              />
              <div>Selecione uma conversa para visualizar as mensagens</div>
            </div>
          )}
        </div>
      </div>
    </LayoutUsuario>
  );
};

export default Page;
