import {
  Bar,
  BarChart,
  CartesianGrid,
  Legend,
  Line,
  LineChart,
  ResponsiveContainer,
  XAxis,
  YAxis,
  Tooltip,
} from 'recharts';
import { useCallback, useState } from 'react';
import { useQuery } from 'react-query';
import axios from 'axios';
import HBox from '../../components/layout/HBox';
import Skeleton from '../../components/layout/Skeleton';
import { translateFilter } from './util';

type Props = {
  colorPalette: string[];
  filterGrupo: number[];
  periodo: [string, string, string];
};

export const GraficoDesempenhoAtendentes = ({
  colorPalette,
  periodo,
  filterGrupo,
}: Props) => {
  const q = useQuery(
    ['desempenhoAtendentes', periodo, filterGrupo],
    async () => {
      const qs = new URLSearchParams();

      const f = translateFilter({ periodo, grupo_id: filterGrupo });

      qs.append('facts', 'qtd_atendimentos_humanos');
      qs.append('dimensions', 'data_criacao_mes,usuario_responsavel_id');
      qs.append('filter', f);

      const res = await axios.get(
        '/api/relatorios/chat_mensagem?' + qs.toString(),
      );

      return res.data.result;
    },
    { refetchOnWindowFocus: false },
  );

  const dados = q.data
    ? q.data.reduce((acc: any[], row: any) => {
        const mes = row.data_criacao_mes.substring(0, 7);
        if (!acc[acc.length - 1] || acc[acc.length - 1].mes != mes)
          acc.push({ mes, atendente: {} });
        const curr = acc[acc.length - 1];

        if (!curr.atendente[row.usuario_responsavel_id])
          curr.atendente[row.usuario_responsavel_id] = { atendimentos: 0 };

        curr.atendente[row.usuario_responsavel_id].atendimentos = parseInt(
          row.qtd_atendimentos_humanos,
        );

        return acc;
      }, [])
    : [];

  const [foco, setFoco] = useState<string | null>(null);

  const [abrirModal, setAbrirModal] = useState(false);

  const CustomTooltip = useCallback(
    ({ active, payload, label, separator }: any) => {
      if (active && payload && payload.length) {
        const uname = queryUsuarios.data?.find(
          (u: any) => foco === `atendente.${u.id}.atendimentos`,
        )?.nome;

        if (uname == null) return null;

        const v = payload.find((p: any) => p.name === uname);
        return (
          <div
            style={{
              backgroundColor: '#fff8',
              border: '1px solid #ccc',
              padding: '0.5rem',
            }}
          >
            {label != '0' && (
              <p>
                <b>{label}</b>
              </p>
            )}
            <p>
              {uname}
              {separator}
              {v?.value} atendimentos
            </p>
          </div>
        );
      }

      return null;
    },
    [foco],
  );

  const queryUsuarios = useQuery(['usuario'], () => {
    return fetch('/api/usuario/box')
      .then(res => res.json())
      .then(res => res.result);
  });

  const renderDot = ({
    cx,
    cy,
    fill,
    stroke,
    strokeWidth,
    strokeOpacity,
    dataKey,
  }: any) => {
    const r = 7;
    return (
      <svg
        x={cx - r * 4}
        y={cy - r}
        width={r * 8}
        height={r * 2}
        viewBox={`0 0 ${r * 8} ${r * 2}`}
        onMouseEnter={() => setFoco(dataKey)}
        onMouseLeave={() => setFoco(null)}
      >
        <rect x={0} y={0} width={r * 8} height={r * 2} fill="transparent" />
        <circle
          cx={r * 4}
          cy={r}
          r={r}
          fill={fill}
          stroke={stroke}
          strokeWidth={strokeWidth}
          opacity={!foco || dataKey === foco ? strokeOpacity : 0.2}
        />
      </svg>
    );
  };

  const handleMouseEnter = (o: any) => {
    const { dataKey } = o;
    setFoco(dataKey);
  };

  const handleMouseLeave = () => {
    setFoco(null);
  };

  if (q.isFetching) {
    return <Skeleton format="box" />;
  }

  // if (!q.isFetching) {
  //   return (
  //     <div>
  //       <pre>{JSON.stringify(q.data, null, 2)}</pre>
  //     </div>
  //   );
  // }

  return (
    <div style={{ width: '100%' }}>
      {q.isSuccess && dados.length === 0 && (
        <div
          style={{
            width: '100%',
            height: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <p>Nenhum dado encontrado</p>
        </div>
      )}
      {q.isSuccess && dados?.length === 1 && (
        <ResponsiveContainer width="100%" height={250}>
          <BarChart
            margin={{
              top: 5,
              right: 5,
              left: 5,
              bottom: 5,
            }}
            data={[dados[0]]}
          >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis />
            <YAxis />
            <Tooltip
              cursor={{ fill: '#8882' }}
              itemSorter={(item: any) => {
                return -item.value as number;
              }}
              itemStyle={{ padding: 0, fontSize: '0.9rem' }}
              labelStyle={{ fontWeight: 'bold' }}
              content={<CustomTooltip />}
            />
            <Legend
              onMouseEnter={handleMouseEnter}
              onMouseLeave={handleMouseLeave}
              formatter={(v, e: any, i) => {
                const { dataKey } = e;

                return (
                  <span
                    style={{
                      color: colorPalette[i % colorPalette.length],
                      opacity: !foco || foco === dataKey ? 1 : 0.2,
                      fontWeight: foco && foco === dataKey ? 'bold' : 'normal',
                    }}
                  >
                    {v}
                  </span>
                );
              }}
            />

            {queryUsuarios.data
              ?.filter((u: any) => dados[0].atendente?.[u.id])
              ?.filter(
                (u: any) =>
                  !filterGrupo?.length ||
                  filterGrupo.includes((u as any).grupo_id),
              )
              ?.map((u: any, index: number) => (
                <Bar
                  // stackId="a"
                  key={u.id}
                  dataKey={`atendente.${u.id}.atendimentos`}
                  name={u.nome}
                  fill={colorPalette[index % colorPalette.length]}
                  maxBarSize={50}
                  opacity={
                    !foco || foco === `atendente.${u.id}.atendimentos` ? 1 : 0.2
                  }
                  onMouseOver={() => setFoco(`atendente.${u.id}.atendimentos`)}
                  onMouseOut={() => setFoco(null)}
                />
              ))}
          </BarChart>
        </ResponsiveContainer>
      )}
      {q.isSuccess && dados?.length > 1 && (
        <HBox>
          <ResponsiveContainer height={200}>
            <LineChart
              margin={{
                top: 5,
                right: 5,
                left: 5,
                bottom: 5,
              }}
              data={dados}
            >
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="mes" interval={1} />
              <YAxis interval={1} />
              <Tooltip
                cursor={{ fill: '#8882' }}
                itemSorter={(item: any) => {
                  return -item.value as number;
                }}
                itemStyle={{ padding: 0, fontSize: '0.9rem' }}
                labelStyle={{ fontWeight: 'bold' }}
              />
              <Legend
                onMouseEnter={handleMouseEnter}
                onMouseLeave={handleMouseLeave}
                formatter={(v, e: any, i) => {
                  const { dataKey } = e;

                  return (
                    <span
                      style={{
                        color: colorPalette[i % colorPalette.length],
                        opacity: !foco || foco === dataKey ? 1 : 0.2,
                        fontWeight:
                          foco && foco === dataKey ? 'bold' : 'normal',
                      }}
                    >
                      {v}
                    </span>
                  );
                }}
              />

              {queryUsuarios.data
                ?.filter((u: any) =>
                  dados?.some((rr: any) =>
                    Boolean((rr as any)?.atendente?.[u.id]),
                  ),
                )
                ?.filter(
                  (u: any) =>
                    !filterGrupo?.length ||
                    filterGrupo.includes((u as any).grupo_id),
                )
                ?.map((u: any, index: number) => (
                  <Line
                    type="monotone"
                    // stackId="a"
                    key={u.id}
                    dataKey={`atendente.${u.id}.atendimentos`}
                    name={u.nome}
                    stroke={colorPalette[index % colorPalette.length]}
                    strokeOpacity={
                      !foco || foco === `atendente.${u.id}.atendimentos`
                        ? 1
                        : 0.2
                    }
                    strokeDasharray={
                      !foco || foco === `atendente.${u.id}.atendimentos`
                        ? `0`
                        : `5 5`
                    }
                    activeDot={renderDot}
                  />
                ))}
            </LineChart>
          </ResponsiveContainer>
        </HBox>
      )}
    </div>
  );
};
