import { NodeType } from '../../../types/NodeType'

const instance: NodeType = {
  type: 'stringConcat',
  label: 'Concatenar String',
  description: 'Concatenar cadeia de caracteres',
  category: 'String',
  inputs() {
    return [
      { type: 'string', name: 'string1', label: 'input 1', hidePort: true },
      { type: 'string', name: 'string2', label: 'input 2', hidePort: true }
    ]
  },
  outputs() {
    return [
      { type: 'string', name: 'strOut', label: 'output 1' }
    ]
  },
  async resolveOutputs(inputs) {
    return { strOut: inputs.string1 + inputs.string2 }
  }
}

export default instance