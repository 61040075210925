import Icon from '@mdi/react';
import styles from './MenuItem.module.css';
import { Link } from 'react-router-dom';
import { useAuth } from '@/lib/useAuth';
import { useMenuGroup } from './useMenuGroup';
import { useEffect } from 'react';

type MenuItemProps = {
  label: string;
  icon: string;
  path: string;
  disabled?: boolean;
  important?: boolean;
  roles?: string[];
};
const MenuItem = ({
  label,
  icon,
  path,
  disabled,
  important,
  roles,
}: MenuItemProps) => {
  const { register, unregister } = useMenuGroup();
  const { user } = useAuth();
  const shouldRender = !roles || roles.includes(user?.papel);

  useEffect(() => {
    if (shouldRender) {
      register();
      return () => unregister();
    }
  }, [shouldRender, register, unregister]);

  if (!shouldRender) return null;

  return (
    <Link
      className={[
        styles.container,
        disabled ? styles.disabled : null,
        important ? styles.important : null,
      ].join(' ')}
      to={disabled ? '#' : path}
    >
      <Icon className={styles.icon} path={icon} size={1} />
      <span>{label}</span>
    </Link>
  );
};

export default MenuItem;
