import Titulo from '../../components/tipografia/Titulo';
import React from 'react';
import styles from './page.module.css';
import LayoutUsuario from '../../components/navegacao/LayoutUsuario';
import Descricao from '../../components/tipografia/Descricao';
import HBox from '../../components/layout/HBox';
import Botao from '../../components/Botao';
import VBox from '../../components/layout/VBox';
import CampoTexto from '../../components/Formulario/CampoTexto';
import Icon from '@mdi/react';
import {
  mdiCashMultiple,
  mdiEmail,
  mdiEye,
  mdiFilePdfBox,
  mdiFileXmlBox,
  mdiMagnify,
} from '@mdi/js';
import DataTable, {
  DataTableHeader,
  DataTableRow,
} from '../../components/layout/DataTable';
import { useInfiniteQuery, useQuery } from 'react-query';
import axios from 'axios';
import { useDebounce } from '../chat/useDebounce';
import { format } from 'date-fns';
import { Link } from 'react-router-dom';

type faturaPageProps = {};

type ResponseList = {
  status: number;
  ok?: boolean;
  result: any[];
  total: number;
  cursor: string | number | null;
};

const moneyFormatter = new Intl.NumberFormat('pt-BR', {
  style: 'currency',
  currency: 'BRL',
});

const ftEst: Record<string, { color: string; label: string }> = {
  pendente: { color: '#333', label: 'Em aberto' },
  vencida: { color: '#800', label: 'Em aberto' },
  paga: { color: '#080', label: 'Paga' },
  cancelada: { color: '#F80', label: 'Cancelada' },
  erro_pagamento: { color: '#F00', label: 'Erro no pagamento' },
};

const faturaPage = ({}: faturaPageProps) => {
  const [search, setSearch] = React.useState<string>('');
  const debouncedSearch = useDebounce(search, 500);

  const q = useInfiniteQuery({
    queryKey: ['fatura', debouncedSearch],
    queryFn: async ({ pageParam }) => {
      const params = new URLSearchParams({
        txt: debouncedSearch,
        c: pageParam,
      });

      return await axios.get<ResponseList>(`/api/fatura?${params.toString()}`, {
        withCredentials: true,
      });
    },
    getNextPageParam: lastPage => lastPage.data.cursor ?? null,
  });

  const headers: DataTableHeader[] = [
    { label: 'Código', width: '40px', column: 'id' },
    { label: 'Período', column: 'descricao_periodo' },
    {
      label: 'Data abertura',
      render: (row: any) => {
        return new Date(row.data_abertura_fatura).toLocaleDateString();
      },
    },
    {
      label: 'Data fechamento',
      render: (row: any) => {
        return new Date(row.data_fechamento_fatura).toLocaleDateString();
      },
    },
    {
      label: 'Data vencimento',
      render: (row: any) => {
        return new Date(row.data_vencimento).toLocaleDateString();
      },
    },
    {
      label: 'Valor',
      render: (row: any) => {
        return moneyFormatter.format(row.fatura_valor);
      },
    },
    {
      label: 'Estado',
      render: (row: any) => {
        const vencido =
          row.fatura_estado === 'pendente' &&
          new Date(row.data_vencimento) < new Date();

        return (
          <HBox>
            <span
              style={{
                whiteSpace: 'nowrap',
                color: ftEst[vencido ? 'vencida' : row.fatura_estado]?.color,
              }}
            >
              {ftEst[vencido ? 'vencida' : row.fatura_estado]?.label}
            </span>
            {!!row.data_envio_email && (
              <span
                style={{ fontSize: '0.8rem', color: 'gray', display: 'block' }}
              >
                <Icon
                  path={mdiEmail}
                  size={0.8}
                  style={{ marginRight: '5px' }}
                  title={`Fatura enviada por e-mail em ${format(new Date(row.data_envio_email), "dd/MM/yyyy 'às' HH:mm")}`}
                />
              </span>
            )}
          </HBox>
        );
      },
    },
    {
      label: 'Ações',
      width: '100px',
      stickRight: true,
      alignRight: true,
      render: (row: any) => {
        return (
          <HBox gap="8px" style={{ justifyContent: 'flex-end' }}>
            <Link
              to={`/fatura/${row.id}`}
              title="Visualizar"
              className={[styles.linkBtn, styles.linkBtnPrimary].join(' ')}
            >
              <Icon path={mdiEye} size={1} />
            </Link>
            {['pendente', 'erro_pagamento'].includes(row.fatura_estado) &&
              row.stripe_url_pagamento && (
                <a
                  href={row.stripe_url_pagamento}
                  target="_blank"
                  title="Pagar agora"
                  className={styles.linkBtn}
                >
                  <Icon path={mdiCashMultiple} size={1} />
                </a>
              )}
            <span style={{ width: '32px' }}>
              {row.existeNotaFiscalPdf && (
                <a
                  href={`/api/fatura/${row.id}/nf_pdf`}
                  target="_blank"
                  title="Nota fiscal (PDF)"
                  className={styles.linkBtn}
                >
                  <Icon path={mdiFilePdfBox} size={1} />
                </a>
              )}
            </span>
            <span style={{ width: '32px' }}>
              {row.existeNotaFiscalXml && (
                <a
                  href={`/api/fatura/${row.id}/nf_xml`}
                  target="_blank"
                  title="Nota fiscal (XML)"
                  className={styles.linkBtn}
                >
                  <Icon path={mdiFileXmlBox} size={1} />
                </a>
              )}
            </span>
          </HBox>
        );
      },
    },
  ];

  const queryFlatList = q.data?.pages?.map(p => p.data.result)?.flat() ?? [];
  const total = q.data?.pages?.[q.data.pages.length - 1]?.data?.total ?? 0;

  return (
    <LayoutUsuario>
      <VBox gap="16px" style={{ height: 'calc(100vh - 32px)' }}>
        {/* Header */}
        <HBox>
          <VBox>
            <Titulo>Faturas</Titulo>
            <Descricao>Gerencie aqui suas faturas e pagamentos</Descricao>
          </VBox>
        </HBox>

        {/* Filtros */}

        <div>
          Exibindo {queryFlatList?.length ?? '0'} de {total ?? '0'} resultados.
        </div>

        <DataTable
          isLoading={q.isFetching}
          cabecalhos={headers}
          linhas={queryFlatList ?? []}
          hasMoreData={q.hasNextPage}
          onLoadMore={async () => q.fetchNextPage()}
        />
      </VBox>
    </LayoutUsuario>
  );
};

export default faturaPage;
