import { NodeType } from '../../../types/NodeType'

const instance: NodeType = {
  type: 'objectMount',
  label: 'Estruturador',
  description: 'Converte valores primitivos para estrutura de dados',
  category: 'Estrutura de dados',
  inputs(inputValues) {

    const result = [
      { type: 'objectType', name: 'objectType', label: 'Estrutura esperada' },
    ]

    const metadados = inputValues?.objectType?.metadados
    if (metadados) {
      for (const meta of metadados) {
        switch (meta.tipo) {
          case 'string':
          case 'number':
          case 'boolean':
          case 'datetime':
          case 'timestamp':
          case 'object':
          case 'list':
            result.push({ type: meta.tipo, name: meta.nome, label: meta.nome })
            break;
        }
      }
    }

    return result
  },
  outputs() {
    return [
      { type: 'object', name: 'object', label: 'Estrutura' }
    ]
  },
  async resolveOutputs(inputs) {
    const primitives = inputs
    const obj = {}
    for (const key in primitives) {
      if (key == 'objectType')
        continue;
      obj[key] = primitives[key]
    }

    return { object: obj }
  }
}

export default instance