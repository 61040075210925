import Titulo from '../../components/tipografia/Titulo';
import LayoutUsuario from '../../components/navegacao/LayoutUsuario';
import Descricao from '../../components/tipografia/Descricao';
import HBox from '../../components/layout/HBox';
import VBox from '../../components/layout/VBox';
import { useEffect, useState } from 'react';
import CampoSeletor from '../../components/Formulario/CampoSeletor';
import Grid, { GridItem } from '../../components/layout/Grid';
import CampoData from '../../components/Formulario/CampoData';
import {
  format,
  startOfDay,
  startOfWeek,
  startOfMonth,
  startOfYear,
} from 'date-fns';
import CardRelatorio from './CardRelatorio';
import Valor from './Valor';
import CardRelatorioTotalClientesAtendidos from './CardRelatorioTotalClientesAtendidos';
import FiltroCanal from '../../components/Filtros/FiltroCanal';
import CardRelatorioTotalCDA from './CardRelatorioCDA';
import CardRelatorioTotalAtendimentos from './CardRelatorioTotalAtendimentos';
import CardRelatorioTotalAtendimentosHumanos from './CardRelatorioTotalAtendimentosHumanos';
import CardRelatorioTotalMensagens from './CardRelatorioTotalMensagens';
import CardRelatorioTotalNotificacoes from './CardRelatorioTotalNotificacoes';
import CardRelatorioDesempenho from './CardRelatorioDesempenho';
import Subtitulo from '../../components/tipografia/Subtitulo';
import FiltroGrupoUsuario from '../../components/Filtros/FiltroGrupoUsuario';
import { GraficoSLAAtendentes2 } from './GraficoSLAAtendentes2';
import { useQuery } from 'react-query';
import axios from 'axios';
import { GraficoDesempenhoAtendentes } from './GraficoDesempenhoAtendentes';
import HistogramaMensagens from './HistogramaMensagens';
import Modal from '@/components/layout/Modal';
import VisualizadorRelatorio from './view/VisualizadorRelatorio';
import { GraficoMarcadores } from './GraficoMarcadores';
import CardRelatorioHistoricoSessao from './CardRelatorioHistoricoSessao';

const today = startOfDay(new Date());
const thisWeek = startOfWeek(new Date());
const thisMonth = startOfMonth(new Date());
const thisYear = startOfYear(new Date());

const filterPeriodoOptions = [
  { id: 'hoje', label: `Hoje (${today.toLocaleDateString()})` },
  {
    id: 'semana',
    label: `Esta semana (de ${thisWeek.toLocaleDateString()} até agora)`,
  },
  {
    id: 'mes',
    label: `Este mês (de ${thisMonth.toLocaleDateString()} até agora)`,
  },
  {
    id: 'ano',
    label: `Este ano (de ${thisYear.toLocaleDateString()} até agora)`,
  },
  { id: 'custom', label: 'Personalizado' },
];
type FilterPeriodoOption = (typeof filterPeriodoOptions)[number];
type FilterPeriodoOptionId = FilterPeriodoOption['id'];

type FilterProps = {
  periodo: [FilterPeriodoOptionId, string, string];
  grupo_id: number[];
  canal_id: number[];
};
type PageProps = {};

const colorPalette: string[] = [
  '#1b6336',
  '#4b9366',
  '#7bc396',
  '#abf3c6',
  '#211d71',
  '#514da1',
  '#817dd1',
  '#b1adff',
  '#985f00',
  '#c88f21',
  '#f8bf51',
  '#ffef81',
  '#2b4f4a',
  '#5b7f7a',
  '#8bafaa',
  '#bbdfda',
  '#985964',
  '#c88994',
  '#f8b9c4',
  '#ffe9f4',
  '#983e00',
  '#c86e00',
  '#f89e00',
  '#ffce29',
  '#4b6244',
  '#7b9274',
  '#abc2a4',
  '#dbf2d4',
];

const Page = ({}: PageProps) => {
  const [filter, setFilter] = useState<FilterProps>({
    periodo: ['hoje', format(today, 'yyyy-MM-dd'), format(today, 'yyyy-MM-dd')],
    canal_id: [],
    grupo_id: [],
  });

  useEffect(() => {
    if (!filter.periodo[0]) return;

    switch (filter.periodo[0]) {
      case 'hoje':
        setFilter(p => ({
          ...p,
          periodo: [
            'hoje',
            format(today, 'yyyy-MM-dd'),
            format(today, 'yyyy-MM-dd'),
          ],
        }));
        break;
      case 'semana':
        setFilter(p => ({
          ...p,
          periodo: [
            'semana',
            format(thisWeek, 'yyyy-MM-dd'),
            format(today, 'yyyy-MM-dd'),
          ],
        }));
        break;
      case 'mes':
        setFilter(p => ({
          ...p,
          periodo: [
            'mes',
            format(thisMonth, 'yyyy-MM-dd'),
            format(today, 'yyyy-MM-dd'),
          ],
        }));
        break;
      case 'ano':
        setFilter(p => ({
          ...p,
          periodo: [
            'ano',
            format(thisYear, 'yyyy-MM-dd'),
            format(today, 'yyyy-MM-dd'),
          ],
        }));
        break;
    }
  }, [filter.periodo?.[0]]);

  const [abrirModalAtendimentosHumanos, setAbrirModalAtendimentosHumanos] =
    useState(false);

  return (
    <LayoutUsuario>
      <VBox gap="16px" style={{ height: 'calc(100vh - 32px)' }}>
        {/* Header */}
        <HBox>
          <VBox>
            <Titulo>Relatórios</Titulo>
            <Descricao>
              Visualize dados sobre clientes, atendentes, mensagens,
              notificações e mais
            </Descricao>
          </VBox>
        </HBox>

        {/* Filtros */}
        <Grid>
          <GridItem sm={12} md={8} lg={6}>
            <HBox>
              <CampoSeletor
                noSearch
                options={filterPeriodoOptions}
                placeholder="Pesquisar por período"
                value={filter.periodo[0]}
                onChange={value => {
                  setFilter({
                    ...filter,
                    periodo: [value, filter.periodo[1], filter.periodo[2]],
                  });
                }}
                style={{
                  width: '100%',
                  flex: 1,
                }}
              />
              {filter.periodo[0] == 'custom' && (
                <CampoData
                  placeholder="Data inicial"
                  value={filter.periodo[1]}
                  onChange={value => {
                    setFilter({
                      ...filter,
                      periodo: [filter.periodo[0], value, filter.periodo[2]],
                    });
                  }}
                />
              )}

              {filter.periodo[0] == 'custom' && (
                <CampoData
                  placeholder="Data final"
                  value={filter.periodo[2]}
                  onChange={value => {
                    setFilter({
                      ...filter,
                      periodo: [filter.periodo[0], filter.periodo[1], value],
                    });
                  }}
                />
              )}
            </HBox>
          </GridItem>
          <GridItem sm={12} md={4} lg={6}>
            <FiltroCanal
              value={filter.canal_id.map(it => it.toString())}
              onChange={value =>
                setFilter({
                  ...filter,
                  canal_id: value.map(it => parseInt(it)),
                })
              }
            />
          </GridItem>
        </Grid>

        {/* Conteudo */}
        <Subtitulo>Mensagens e notificações</Subtitulo>
        <Grid>
          <GridItem sm={12} md={4} lg={2}>
            <CardRelatorioTotalClientesAtendidos
              periodo={filter.periodo}
              canal_id={filter.canal_id}
            />
          </GridItem>
          <GridItem sm={12} md={4} lg={2}>
            <CardRelatorioTotalAtendimentos
              periodo={filter.periodo}
              canal_id={filter.canal_id}
            />
          </GridItem>
          <GridItem sm={12} md={4} lg={2}>
            <CardRelatorioTotalAtendimentosHumanos
              periodo={filter.periodo}
              canal_id={filter.canal_id}
            />
          </GridItem>

          <GridItem sm={12} md={4} lg={2}>
            <CardRelatorioTotalMensagens
              periodo={filter.periodo}
              canal_id={filter.canal_id}
            />
          </GridItem>

          <GridItem sm={12} md={4} lg={2}>
            <CardRelatorioTotalNotificacoes
              periodo={filter.periodo}
              canal_id={filter.canal_id}
            />
          </GridItem>

          <GridItem sm={12} md={4} lg={2}>
            <CardRelatorioTotalCDA
              periodo={filter.periodo}
              canal_id={filter.canal_id}
            />
          </GridItem>

          <GridItem sm={12} md={6} lg={4} rows={4}>
            <CardRelatorioDesempenho
              periodo={filter.periodo}
              canal_id={filter.canal_id}
              grupo_id={filter.grupo_id}
              colorPalette={colorPalette}
            />
          </GridItem>

          <GridItem sm={12} md={6} lg={4} rows={4}>
            <CardRelatorioHistoricoSessao
              periodo={filter.periodo}
              canal_id={filter.canal_id}
              grupo_id={filter.grupo_id}
              colorPalette={colorPalette}
            />
          </GridItem>

          <GridItem sm={12} md={6} lg={4} rows={4}>
            <CardRelatorio
              titulo="Marcadores"
              style={{ height: '360px' }}
              mostrarVisualizador={false}
              filtroVisualizador={filter}
              metricasVisualizador={[]}
              dimensoesVisualizador={[]}
              hrefVisualizador="/api/relatorios/marcadores"
            >
              <GraficoMarcadores
                colorPalette={colorPalette}
                periodo={filter.periodo}
                filterCanal={filter.canal_id}
              />
            </CardRelatorio>
          </GridItem>
        </Grid>

        <Subtitulo>Atendimento</Subtitulo>
        <Grid>
          <GridItem sm={12}>
            <FiltroGrupoUsuario
              value={filter.grupo_id.map(it => it.toString())}
              onChange={v =>
                setFilter({ ...filter, grupo_id: v.map(it => parseInt(it)) })
              }
            />
          </GridItem>
        </Grid>

        <Grid>
          <GridItem sm={12} lg={6} rows={2}>
            <CardRelatorio
              titulo="Tempo médio até atendimento"
              style={{ height: '350px' }}
              mostrarVisualizador
              filtroVisualizador={filter}
              metricasVisualizador={['avg_sla_seconds']}
              dimensoesVisualizador={[
                'data_criacao_mes',
                'usuario_responsavel_nome',
              ]}
              hrefVisualizador="/api/relatorios/sla"
            >
              <GraficoSLAAtendentes2
                periodo={filter.periodo}
                filterGrupo={filter.grupo_id}
                colorPalette={colorPalette}
              />
            </CardRelatorio>
          </GridItem>

          <GridItem sm={12} lg={6} rows={2}>
            <CardRelatorio
              titulo="Atendimentos humanos"
              style={{ height: '350px' }}
              mostrarVisualizador
              filtroVisualizador={filter}
              metricasVisualizador={['qtd_atendimentos_humanos']}
              dimensoesVisualizador={[
                'data_criacao_mes',
                'usuario_responsavel_nome',
              ]}
              hrefVisualizador="/api/relatorios/chat_mensagem"
            >
              <GraficoDesempenhoAtendentes
                periodo={filter.periodo}
                filterGrupo={filter.grupo_id}
                colorPalette={colorPalette}
              />
            </CardRelatorio>
          </GridItem>

          <GridItem sm={12} rows={4}>
            <CardRelatorio
              titulo="Atividade durante o período"
              style={{ height: '320px' }}
            >
              <HistogramaMensagens
                data={[]}
                seletor="mensagens"
                periodo={filter.periodo}
                canal_id={filter.canal_id}
                grupo_id={filter.grupo_id}
              />
            </CardRelatorio>
          </GridItem>
        </Grid>
      </VBox>
    </LayoutUsuario>
  );
};

export default Page;
