import { useQuery } from 'react-query';
import CardRelatorio from './CardRelatorio';
import Valor from './Valor';
import axios from 'axios';
import Skeleton from '../../components/layout/Skeleton';
import { dateWithoutTimezone } from '../../lib/util';
import { translateFilter } from './util';

type Props = {
  periodo: [string, string, string];
  canal_id: number[];
};

const CardRelatorioTotalAtendimentos = ({ periodo, canal_id }: Props) => {
  const q = useQuery(
    ['totalAtendimentos', periodo, canal_id],
    async () => {
      const qs = new URLSearchParams();
      qs.append('facts', 'qtd_chats');
      qs.append('filter', translateFilter({ periodo, canal_id }));

      const res = await axios.get(
        '/api/relatorios/chat_mensagem?' + qs.toString(),
      );
      return res.data.result[0].qtd_chats ?? 0;
    },
    { refetchOnWindowFocus: false },
  );

  return (
    <CardRelatorio
      titulo="Atendimentos"
      mostrarVisualizador={true}
      filtroVisualizador={{ periodo, canal_id }}
      metricasVisualizador={['qtd_chats']}
      dimensoesVisualizador={['usuario_responsavel_nome', 'nome']}
      hrefVisualizador="/api/relatorios/chat_mensagem"
    >
      {q.isFetching ? <Skeleton format="box" /> : <Valor valor={q.data} />}
    </CardRelatorio>
  );
};

export default CardRelatorioTotalAtendimentos;
