import React, { useEffect, useState } from 'react';
import styles from './SalaEspera.module.css';
import HBox from '../../components/layout/HBox';
import Subtitulo from '../../components/tipografia/Subtitulo';
import CardCliente from './CardCliente';
import { Droppable } from 'react-beautiful-dnd';
import { Tooltip } from '../../components/layout/Tooltip';
import Icon from '@mdi/react';
import { mdiInformationSlabBox } from '@mdi/js';
import Descricao from '../../components/tipografia/Descricao';

const getListStyle = (isDraggingOver: boolean, isDropDisabled: boolean) => ({
  background: isDropDisabled
    ? '#F88'
    : isDraggingOver
      ? 'var(--tc-color-primary-100)'
      : 'var(--tc-color-gray-200)',
  padding: '4px',
  display: 'flex',
  overflow: 'auto',
  minHeight: '80px',
  minWidth: '225px',
  width: '100%',
  borderRadius: '16px',
  gap: '4px',
});

type SalaEsperaProps = {
  nome: string;
  descricao: string;
  clientes?: {
    id: number;
    fila_id?: number;
    nome: string;
    status: 'aguardando_atendente' | 'atendimento' | 'atualizando';
  }[];
  dragItem: any;
  droppableId: string;
  online?: boolean;
};

const SalaEspera = ({
  nome,
  descricao,
  clientes,
  dragItem,
  droppableId,
  online,
}: SalaEsperaProps) => {
  // deal with StrictMode...
  const [enabled, setEnabled] = useState(false);

  useEffect(() => {
    const animation = requestAnimationFrame(() => setEnabled(true));

    return () => {
      cancelAnimationFrame(animation);
      setEnabled(false);
    };
  }, []);

  if (!enabled) {
    return null;
  }

  return (
    <HBox>
      <div className={styles.hallEntrada}>
        <Descricao>
          <HBox gap="2px">
            <Tooltip position="right" text={online ? 'Ativo' : 'Desconectado'}>
              <Icon
                path={mdiInformationSlabBox}
                size="14px"
                color="var(--tc-color-gray-500)"
              />
            </Tooltip>
            <span
              style={{
                color: online ? undefined : 'var(--tc-color-gray-400)',
                fontWeight: 600,
                fontSize: '16px',
                overflow: 'hidden',
                whiteSpace: 'nowrap',
                textOverflow: 'ellipsis',
              }}
            >
              {nome}
            </span>
          </HBox>
        </Descricao>
        <div style={{ textAlign: 'center', fontSize: '12px' }}>{descricao}</div>
      </div>
      <HBox gap="12px" style={{ flex: 1 }}>
        <Droppable
          droppableId={droppableId}
          direction="horizontal"
          isDropDisabled={dragItem?.grupo_responsavel_id == null}
        >
          {(provided, snapshot) => (
            <div
              ref={provided.innerRef}
              style={{
                ...getListStyle(
                  snapshot.isDraggingOver,
                  dragItem && dragItem?.grupo_responsavel_id == null,
                ),
              }}
            >
              {clientes?.map((cliente, idx) => (
                <CardCliente
                  key={`${cliente.id}-${idx}`}
                  item={cliente}
                  index={idx}
                  dragItem={dragItem}
                />
              ))}

              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </HBox>
    </HBox>
  );
};
export default SalaEspera;
