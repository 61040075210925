import React, { useCallback, useEffect } from 'react';
import styles from './Modal.module.css';
import Botao from '../Botao';
import Icon from '@mdi/react';
import { mdiClose } from '@mdi/js';
import { keyboard } from '@testing-library/user-event/dist/keyboard';
import ReactDOM from 'react-dom';

type ModalButtonProps = {
  visible?: boolean;
  disabled?: boolean;
  label?: React.ReactNode;
  href?: string;
  linkTarget?: string;
  variant?:
    | 'primary'
    | 'secondary'
    | 'success'
    | 'info'
    | 'warning'
    | 'danger'
    | 'outline-primary'
    | 'outline-secondary'
    | 'outline-success'
    | 'outline-info'
    | 'outline-warning'
    | 'outline-danger';
};

type ModalProps = {
  show: boolean;
  title?: React.ReactNode;
  children?: React.ReactNode;
  confirmProps?: ModalButtonProps;
  cancelProps?: ModalButtonProps;
  onConfirm?: () => void;
  onCancel?: () => void;
  onClose?: () => void;

  hideControls?: boolean;
  ignoreOverlayClick?: boolean;
  size?: 'sm' | 'md' | 'lg';
};

const defaultConfirmProps: ModalButtonProps = {
  visible: true,
  disabled: false,
  label: 'Confirmar',
};

const defaultCancelProps: ModalButtonProps = {
  visible: true,
  disabled: false,
  label: 'Cancelar',
};

enum _state {
  startup,
  opening,
  open,
  closing,
  closed,
}

const Modal = ({
  size = 'sm',
  show,
  title,
  children,
  confirmProps,
  cancelProps,
  hideControls,
  ignoreOverlayClick,
  onClose,
  onConfirm,
  onCancel,
}: ModalProps) => {
  const [state, setState] = React.useState<_state>(_state.startup);
  const [modalAttention, setModalAttention] = React.useState<boolean>(false);

  useEffect(() => {
    if (show) {
      if ([_state.startup, _state.closed].includes(state)) {
        setState(_state.opening);
        setTimeout(() => {
          setState(_state.open);
        }, 200);
      }
    } else {
      if (state === _state.open) {
        setState(_state.closing);
        setTimeout(() => {
          setState(_state.closed);
        }, 100);
      }
    }
  }, [show, state]);

  const handleClose = useCallback(() => {
    onClose?.();
  }, [onClose]);

  useEffect(() => {
    const handleEsc = (e: KeyboardEvent) => {
      if (e.key === 'Escape') {
        handleClose();
      }
    };

    if (show) {
      document.addEventListener('keydown', handleEsc);
    } else {
      document.removeEventListener('keydown', handleEsc);
    }

    return () => {
      document.removeEventListener('keydown', handleEsc);
    };
  }, [show, onClose, handleClose]);

  const handleOverlayClose = () => {
    if (ignoreOverlayClick) {
      setModalAttention(() => true);
      setTimeout(() => {
        setModalAttention(() => false);
      }, 200);
      return;
    }

    handleClose();
  };

  const handleCancel = () => {
    onCancel ? onCancel() : onClose?.();
  };

  const handleConfirm = () => {
    onConfirm ? onConfirm() : onClose?.();
  };

  if (state === _state.startup || state === _state.closed) return null;

  const confirmButtonProps = { ...defaultConfirmProps, ...confirmProps };
  const cancelButtonProps = { ...defaultCancelProps, ...cancelProps };

  return ReactDOM.createPortal(
    <>
      <div
        className={[
          styles.overlay,
          state === _state.closing ? styles.closing : null,
        ].join(' ')}
        onClick={handleOverlayClose}
      ></div>
      <div
        className={[
          styles.container,
          styles[`container-${size}`],
          state === _state.opening ? styles.init : null,
          state === _state.closing ? styles.closing : null,
          modalAttention ? styles.modalAttention : null,
        ].join(' ')}
      >
        <div className={styles.titleContainer}>
          <div className={styles.title}>{title}</div>
          <div className={styles.closeContainer}>
            <Botao variant="none-secondary" onClick={handleClose}>
              <Icon path={mdiClose} size={1} color="var(--tc-color-gray-500)" />
            </Botao>
          </div>
        </div>
        <div className={styles.content}>{children}</div>
        {!hideControls && (
          <div className={styles.footer}>
            {cancelButtonProps.visible && (
              <Botao
                disabled={cancelButtonProps.disabled}
                variant={cancelButtonProps.variant || 'outline-secondary'}
                onClick={handleCancel}
              >
                {cancelButtonProps.label}
              </Botao>
            )}
            {confirmButtonProps.visible && (
              <Botao
                disabled={confirmButtonProps.disabled}
                variant={confirmButtonProps.variant || 'primary'}
                onClick={handleConfirm}
                href={confirmButtonProps.href}
                target={confirmButtonProps.linkTarget}
              >
                {confirmButtonProps.label}
              </Botao>
            )}
          </div>
        )}
      </div>
    </>,
    document.body,
  );
};

export default Modal;
