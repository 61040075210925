import { NodeType } from '../../../types/NodeType'

const instance: NodeType = {
  type: 'string',
  label: 'String',
  description: 'Cadeia de caracteres',
  category: 'String',
  inputs(inputValues) {
    const ret = [{ type: 'string', name: 'string', label: 'input 1' }]

    const template = (inputValues?.string) ?? "";
    const re = /\{\{([^\}]+?)\}\}/g;

    const ids: string[] = []
    let res
    while ((res = re.exec(template)) !== null) {
      if (!ids.includes(res[1])) ids.push(res[1]);
    }
    if (ids.length)
      ret.push(...ids.map(id => ({ type: 'string', name: id, label: id })))

    return ret
  },
  outputs() {
    return [
      { type: 'string', name: 'string', label: 'Texto' }
    ]
  },
  async resolveOutputs(inputs) {
    const template = inputs.string ?? "";
    const re = /\{\{([^\}]+?)\}\}/g;

    const mensagem = template.replace(re, (match, p1) => {
      return inputs[p1] ?? `{{${p1}}}`;
    })

    return { string: mensagem }
  }
}

export default instance