import { NodeType } from '../../../types/NodeType'

const instance: NodeType = {
  type: 'stringLength',
  label: 'Tamanho da string',
  description: 'Retorna o tamanho da string',
  category: 'String',
  inputs() {
    const ret = [{ type: 'string', name: 'string', label: 'Texto' }]

    return ret
  },
  outputs() {
    return [
      { type: 'number', name: 'number', label: 'Tamanho' }
    ]
  },
  async resolveOutputs(inputs) {
    return { number: inputs.string?.length ?? 0 }
  }
}

export default instance