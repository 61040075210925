import portProps from '@tera/shared/src/fluxoConfig/ports/boolean'

type Props = {
  value: boolean | null
  onChange: (value: boolean) => void
}

const Port = ({ value, onChange }: Props) => {
  return <label>
    <input
      type="checkbox" checked={value ?? false} onChange={(e) => onChange(e.target.checked)} />
    <span> Sim / não</span>
  </label>
}

const port =  {
  ...portProps,
  render({ value, onChange }: Props) {
    return <Port value={value} onChange={onChange} />
  }
}

export default port