import { NodeType } from '../../../types/NodeType'

const instance: NodeType = {
  type: 'datetimeNextBusinessDay',
  label: 'Próximo dia útil',
  description: 'Retorna o próximo dia útil, de acordo com o calendário',
  category: 'Data/hora',
  inputs() {
    return [
      { type: 'datetime', name: 'datetime', label: 'Data/hora base' },
      { type: 'boolean', name: 'considerar_fds', label: 'Incluir fins de semana' }
    ]
  },
  outputs() {
    return [
      { type: 'datetime', name: 'datetime', label: 'Data/hora' }
    ]
  },
  async resolveOutputs() {
    return null
  }
}

export default instance