import axios from 'axios';

const options = { withCredentials: true };

export default class ApiCall {
  static async get(url: string, querystring: Record<string, any> = {}) {
    const urlIdx = url.indexOf('?');
    const urlParams = new URLSearchParams(
      urlIdx > -1 ? url.substring(urlIdx + 1) : '',
    );
    Object.keys(querystring).forEach(key =>
      urlParams.append(key, querystring[key]),
    );

    const response = await axios.get(`${url}?${urlParams.toString()}`, options);
    return response.data;
  }

  static async post(url: string, data: Record<string, any>) {
    const response = await axios.post(url, data, options);
    return response.data;
  }

  static async put(url: string, data: Record<string, any>) {
    const response = await axios.put(url, data, options);
    return response.data;
  }

  static async delete(url: string) {
    const response = await axios.delete(url, options);
    return response.data;
  }
}
