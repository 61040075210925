import { NodeType } from '../../../types/NodeType'

const instance: NodeType = {
  type: 'booleanCompare',
  label: 'Sim/Não (fluxo)',
  description: 'Sim/Não para fluxo de execução',
  category: 'Sim/Não',
  inputs() {
    return [
      { type: 'route', name: 'route', label: 'Entr. fluxo' },
      { type: 'boolean', name: 'boolean', label: 'Sim/Não' }
    ]
  },
  outputs() {
    return [
      { type: 'route', name: 'route-true', label: 'Verdadeiro' },
      { type: 'route', name: 'route-false', label: 'Falso' },
    ]
  },
  async resolveOutputs(inputs) {
    return { route: `route-${Boolean(inputs.boolean).toString()}` }
  }
}

export default instance