import React, { useEffect, useRef } from 'react';
import styles from './BotaoSeletor.module.css';
import Icon from '@mdi/react';
import { mdiChevronDown, mdiChevronUp } from '@mdi/js';
import { BotaoVariants } from './Botao';
import btnStyles from './Botao.module.css';
import HBox from './layout/HBox';

type BotaoSeletorProps = {
    variant?: BotaoVariants;
    value?: string;
    onChange?: (value: string) => void;
    placeholder?: string;
    type?: string;
    required?: boolean;
    options?: {id: string, label: React.ReactNode}[];
    disabled?: boolean;
    children?: React.ReactNode;
    [key: string]: any;
}

export default function BotaoSeletor({
    variant = 'primary',
    value, 
    onChange, 
    placeholder, 
    type = 'text',
    required, 
    options, 
    disabled,
    children,
    ...props 
}: Readonly<BotaoSeletorProps>) {
    const [selectedIndex, setSelectedIndex] = React.useState<number>(-1)
    const [open, setOpen] = React.useState<{ isOpen: boolean; position: 'top-right' | 'top-left' | 'bottom-right' | 'bottom-left' }>({ isOpen: false, position: 'bottom-right' });

    const selected = options?.[selectedIndex]

    const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
        if (disabled) return;

        let hit = true;
        switch(e.code.toLowerCase()) {
            case 'space':
            case 'enter':
                toggleDropdown();
                break;
            case 'arrowdown':
                if (options?.length) {
                    setSelectedIndex((selectedIndex + 1) % options?.length)
                    openDropdown();
                }
                break;
            case 'arrowup':
                if (options?.length) {
                    setSelectedIndex((selectedIndex - 1 + options?.length) % options?.length)
                    openDropdown();
                }
                break;
            default:
                hit = false;
        }
        if (hit) {
            e.preventDefault()
            e.stopPropagation()
        }
    }

    const handleContainerClick = () => {
        if (disabled) return;

        toggleDropdown();
    }

    const toggleDropdown = () => {
        setOpen((prevState) => ({ ...prevState, isOpen: !prevState.isOpen }));
    };

    const openDropdown = () => {
        setOpen((prevState) => ({ ...prevState, isOpen: true }));
    };

    const closeDropdown = () => {
        setOpen((prevState) => ({ ...prevState, isOpen: false }));
    };

    useEffect(() => {
        const handleClick = (e: MouseEvent) => {
            if (e.target instanceof HTMLElement && e.target.closest(`.${styles.container}`)) {
                return
            }
            closeDropdown();
        }
        document.addEventListener('click', handleClick)

        return () => {
            document.removeEventListener('click', handleClick)
        }
    }, [])

    useEffect(() => {
        if (value) {
            setSelectedIndex(options?.findIndex(it => it.id === value) ?? -1)
        } else {
            setSelectedIndex(-1)
        }
    }, [value, options])

    const optionsContainerRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        if (open.isOpen) {
            if (optionsContainerRef.current) {
                const rect = optionsContainerRef.current.getBoundingClientRect();
                const availableSpaceBelow = window.innerHeight - rect.bottom;
                const availableSpaceAbove = rect.top;
                const shouldOpenAbove = availableSpaceBelow < rect.height && availableSpaceAbove > availableSpaceBelow;

                const availableSpaceRight = window.innerWidth - rect.right;
                const availableSpaceLeft = rect.left;
                const shouldOpenLeft = availableSpaceRight < rect.width && availableSpaceLeft > availableSpaceRight;

                const position = shouldOpenAbove ? (shouldOpenLeft ? 'top-left' : 'top-right') : (shouldOpenLeft ? 'bottom-left' : 'bottom-right');

                console.log('position', position)
                setOpen((prevState) => ({ ...prevState, position }));
            }
        }
    }, [open.isOpen]);

    return (
        <div>
            <div 
                className={[
                    btnStyles.button, 
                    btnStyles[variant], 
                    styles.container, 
                    disabled ? styles.disabled : null
                ].join(' ')} 
                tabIndex={disabled ? undefined : 0} 
                onKeyDown={handleKeyDown} 
                onClick={handleContainerClick}
            >
                <HBox>
                    <div>{children}</div>
                    <div className={styles.arrow}>
                        <Icon path={open.isOpen ? mdiChevronUp : mdiChevronDown} size={1} />
                    </div>
                </HBox>

                <div 
                    ref={optionsContainerRef}
                    style={{ visibility: open.isOpen ? 'visible' : 'hidden' }} 
                    className={[styles.options, styles[open.position]].join(' ')}
                >
                    {options?.map((option, index) => (
                        <div 
                            tabIndex={0}
                            key={option.id} 
                            className={[styles.option, selectedIndex === index ? styles.selected : null].join(' ')}
                            onClick={() => {
                                setSelectedIndex(index)
                                onChange?.(option.id)
                                closeDropdown()
                            }}
                        >
                            {option.label}
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
}