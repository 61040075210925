import { useQuery } from 'react-query';
import CardRelatorio from './CardRelatorio';
import Valor from './Valor';
import axios from 'axios';
import Skeleton from '../../components/layout/Skeleton';
import {
  CartesianGrid,
  Legend,
  Line,
  LineChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';
import { useCallback, useEffect, useMemo, useState } from 'react';
import HBox from '../../components/layout/HBox';
import Botao from '../../components/Botao';

import { differenceInDays } from 'date-fns';
import { dateWithoutTimezone } from '../../lib/util';
import { FilterProps, translateFilter } from './util';

type Props = {
  colorPalette: string[];
} & FilterProps;

const parseDate = (v: string) =>
  new Date(v.substring(0, 10) + 'T00:00:00.000').toLocaleDateString();

const CardRelatorioDesempenho = ({
  periodo,
  canal_id,
  grupo_id,
  colorPalette,
}: Props) => {
  const [foco, setFoco] = useState<string | null>(null);

  const calcBestInterval = () => {
    let d1 = periodo![1],
      d2 = periodo![2];
    if (!d2) {
      d2 = new Date().toISOString().substring(0, 10);
    }

    const days = differenceInDays(d2, d1);
    if (days <= 7) {
      return 'dia';
    } else if (days <= 31) {
      return 'semana';
    } else {
      return 'mes';
    }
  };

  const [selectedPeriod, setSelectedPeriod] = useState<
    'dia' | 'semana' | 'mes'
  >(calcBestInterval);

  useEffect(() => {
    setSelectedPeriod(calcBestInterval());
  }, [periodo]);

  const CustomTooltip = useCallback(
    ({ active, payload, label, separator }: any) => {
      if (active && payload && payload.length) {
        return (
          <div
            style={{
              backgroundColor: '#fff8',
              border: '1px solid #ccc',
              padding: '0.5rem',
            }}
          >
            {label != '0' && (
              <p>
                <b>{parseDate(label)}</b>
              </p>
            )}
            <p>
              Clientes{separator}
              {payload[2].value}
            </p>
            <p>
              Mensagens{separator}
              {payload[0].value}
            </p>
            <p>
              Notificações{separator}
              {payload[1].value}
            </p>
            <p>
              Atendimentos{separator}
              {payload[3].value}
            </p>
          </div>
        );
      }

      return null;
    },
    [foco],
  );

  const q = useQuery(
    ['desempenho', selectedPeriod, periodo, canal_id],
    async () => {
      const qs = new URLSearchParams();
      qs.append(
        'facts',
        'qtd_chats,qtd_clientes,qtd_mensagens,qtd_notificacoes',
      );
      qs.append('dimensions', `data_criacao_${selectedPeriod}`);
      qs.append('filter', translateFilter({ periodo, canal_id, grupo_id }));

      const res = await axios.get(
        '/api/relatorios/chat_mensagem?' + qs.toString(),
      );
      return res.data.result;
    },
    { refetchOnWindowFocus: false },
  );

  const handleMouseEnter = (o: any) => {
    const { dataKey } = o;
    setFoco(dataKey);
  };

  const handleMouseLeave = () => {
    setFoco(null);
  };

  if (q.isFetching) {
    return (
      <CardRelatorio titulo="Desempenho" style={{ minHeight: '360px' }}>
        <Skeleton format="box" />
      </CardRelatorio>
    );
  }

  return (
    <CardRelatorio
      titulo="Desempenho"
      style={{ minHeight: '360px' }}
      mostrarVisualizador={true}
      filtroVisualizador={{ periodo, canal_id }}
      metricasVisualizador={[
        'qtd_chats',
        'qtd_clientes',
        'qtd_mensagens',
        'qtd_notificacoes',
      ]}
      dimensoesVisualizador={[`data_criacao_${selectedPeriod}`]}
      hrefVisualizador="/api/relatorios/chat_mensagem"
    >
      <HBox>
        <Botao
          variant={
            selectedPeriod === 'dia' ? 'outline-primary' : 'outline-secondary'
          }
          onClick={() => setSelectedPeriod('dia')}
        >
          Dia
        </Botao>
        <Botao
          variant={
            selectedPeriod === 'semana'
              ? 'outline-primary'
              : 'outline-secondary'
          }
          onClick={() => setSelectedPeriod('semana')}
        >
          Semana
        </Botao>
        <Botao
          variant={
            selectedPeriod === 'mes' ? 'outline-primary' : 'outline-secondary'
          }
          onClick={() => setSelectedPeriod('mes')}
        >
          Mês
        </Botao>
      </HBox>
      <ResponsiveContainer width="100%" height={160}>
        <LineChart
          margin={{
            top: 5,
            right: 5,
            left: 5,
            bottom: 5,
          }}
          data={q.data}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis
            dataKey={`data_criacao_${selectedPeriod}`}
            tickFormatter={v => parseDate(v)}
          />
          <YAxis yAxisId="left" dataKey="qtd_mensagens" orientation="left" />
          <YAxis yAxisId="left" dataKey="qtd_notificacoes" orientation="left" />
          <YAxis yAxisId="right" dataKey="qtd_clientes" orientation="right" />
          <YAxis yAxisId="right" dataKey="qtd_chats" orientation="right" />

          <Tooltip
            cursor={{ fill: '#8882' }}
            itemStyle={{ padding: 0, fontSize: '0.9rem' }}
            labelStyle={{ fontWeight: 'bold' }}
            content={<CustomTooltip />}
          />
          <Legend
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
            formatter={(v, e: any, i) => {
              const { dataKey } = e;

              return (
                <span
                  style={{
                    color: colorPalette[(i * 10) % colorPalette.length],
                    opacity: !foco || foco === dataKey ? 1 : 0.2,
                    fontWeight: foco && foco === dataKey ? 'bold' : 'normal',
                  }}
                >
                  {v}
                </span>
              );
            }}
          />
          <Line
            yAxisId="left"
            dataKey="qtd_mensagens"
            name="Mensagens"
            type="monotone"
            stroke={colorPalette[0]}
            opacity={!foco || foco === `qtd_mensagens` ? 1 : 0.2}
            onMouseOver={() => setFoco(`qtd_mensagens`)}
            onMouseOut={() => setFoco(null)}
            dot={false}
          />
          <Line
            yAxisId="left"
            dataKey="qtd_notificacoes"
            name="Notificações"
            type="monotone"
            stroke={colorPalette[5]}
            opacity={!foco || foco === `qtd_notificacoes` ? 1 : 0.2}
            onMouseOver={() => setFoco(`qtd_notificacoes`)}
            onMouseOut={() => setFoco(null)}
            dot={false}
          />
          <Line
            yAxisId="right"
            dataKey="qtd_clientes"
            name="Clientes"
            type="monotone"
            stroke={colorPalette[10]}
            opacity={!foco || foco === `qtd_clientes` ? 1 : 0.2}
            onMouseOver={() => setFoco(`qtd_clientes`)}
            onMouseOut={() => setFoco(null)}
            dot={false}
          />
          <Line
            yAxisId="left"
            dataKey="qtd_chats"
            name="Atendimentos"
            type="monotone"
            stroke={colorPalette[15]}
            opacity={!foco || foco === `qtd_chats` ? 1 : 0.2}
            onMouseOver={() => setFoco(`qtd_chats`)}
            onMouseOut={() => setFoco(null)}
            dot={false}
          />
        </LineChart>
      </ResponsiveContainer>
    </CardRelatorio>
  );
};

export default CardRelatorioDesempenho;
