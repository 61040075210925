import { NodeType } from '../../../types/NodeType'

const instance: NodeType = {
  type: 'groupWorkHours',
  label: 'Expediente (Departamento)',
  description: 'Verifica se o horário atual está dentro ou fora do expediente do departamento',
  category: 'Domínio',
  inputs() {
    return [
      { type: 'route', name: 'route', label: 'Entr. fluxo' },
      { type: 'number', name: 'group', label: 'Departamento' },
    ]
  },
  outputs() {
    return [
      { type: 'route', name: 'route-true', label: 'Dentro do exp.' },
      { type: 'route', name: 'route-false', label: 'Fora do exp.' },
    ]
  },
  async resolveOutputs(inputs) {
    return {
      number: inputs.domainGroup?.id
    }
  }
}

export default instance