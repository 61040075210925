import { NodeType } from "../../../types/NodeType";

const instance: NodeType = {
  type: "teraClientExists",
  label: "Cliente existe?",
  description: "Verifica se o contato existe como cliente no Terachat",
  category: "Domínio",
  inputs() {
    return [{ type: "route", name: "route", label: "Entr. fluxo" }];
  },
  outputs() {
    const outputs = [
      { type: "route", name: "route-true", label: "Existe" },
      { type: "route", name: "route-false", label: "Não existe" },
    ];

    return outputs;
  },
  async resolveOutputs() {
    return null;
  },
};

export default instance;
