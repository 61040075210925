import { NodeType } from '../../../types/NodeType'

const instance: NodeType = {
  type: 'numberCalc',
  label: 'Calculadora (Número)',
  description: 'Realiza operações matemáticas básicas com números',
  category: 'Número',
  inputs() {
    return [
      { type: 'number', name: 'value1', label: 'Número' },
      { type: 'numberCalcOp', name: 'numberOperator', label: 'Operação', hidePort: true },
      { type: 'number', name: 'value2', label: 'Número' }
    ]
  },
  outputs() {
    return [
      { type: 'number', name: 'number', label: 'Resultado' }
    ]
  },
  async resolveOutputs(inputs) {
    const op = inputs.numberOperator ?? '+'

    const v1 = Number(inputs.value1) || 0
    const v2 = Number(inputs.value2) || 0

    switch (op) {
      case '+':
        return { number: v1 + v2 }
      case '-':
        return { number: v1 - v2 }
      case '*':
        return { number: v1 * v2 }
      case '/':
        return { number: v1 / v2 }
      case '%':
        return { number: v1 % v2 }
    }

    return { number: null }
  }
}

export default instance