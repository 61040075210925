import ChatDto from '@tera/shared/src/dto/ChatDto';

export const updateChatList = (
  chats: ChatDto[],
  chatIdx: number,
  updateChatFn: (chat: ChatDto) => ChatDto,
  move: boolean = true,
) => {
  const state = chats.reduce(
    (acc: any, chat: ChatDto, idx) => {
      let _chat: any = chat;

      if (idx === chatIdx) {
        _chat = updateChatFn(chat);
        acc.atendimentoIdx =
          _chat.estado === 'atendimento'
            ? acc.atendimento.length
            : acc.atendimentoIdx;
        acc.outrosIdx =
          _chat.estado !== 'atendimento' ? acc.outros.length : acc.outrosIdx;
      }

      if (_chat.estado === 'atendimento') {
        acc.atendimento.push(_chat);
      } else {
        acc.outros.push(_chat);
      }
      return acc;
    },
    { atendimento: [], outros: [], atendimentoIdx: null, outrosIdx: null },
  );

  if (move) {
    if (state.atendimentoIdx !== null) {
      const item = state.atendimento.splice(state.atendimentoIdx, 1);
      state.atendimento.unshift(item[0]);
    }

    if (state.outrosIdx !== null) {
      const item = state.outros.splice(state.outrosIdx, 1);
      state.outros.unshift(item[0]);
    }
  }

  const updatedList = [...state.atendimento, ...state.outros];

  return updatedList;
};
