import { MarcadorDto } from '@tera/shared/src/dto/MarcadorDto';
import Marcador from './Marcador';
import HBox from './HBox';
import Icon from '@mdi/react';
import { mdiTrashCan } from '@mdi/js';

type Props = {
  tags: MarcadorDto[];
  ids?: number[];
  onRemove?: (id: number) => void;
};

const Marcadores = ({ tags, ids, onRemove }: Props) => {
  return (
    <HBox style={{ flex: '0 1' }}>
      {ids?.map((mid, idx) => {
        const tag = tags?.find((t: MarcadorDto) => +t.id == +mid);
        if (!tag) return null;

        return (
          <Marcador
            color={tag.cor}
            key={idx}
            onClick={onRemove ? () => onRemove?.(mid) : undefined}
          >
            <HBox style={{ alignItems: 'center' }}>
              <span>{tag.nome}</span>
              {onRemove && (
                <span
                  style={{
                    color: 'var(--tc-color-danger)',
                    lineHeight: '12px',
                  }}
                >
                  <Icon path={mdiTrashCan} size="12px" />
                </span>
              )}
            </HBox>
          </Marcador>
        );
      })}
    </HBox>
  );
};

export default Marcadores;
