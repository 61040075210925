import { useEffect, useState } from 'react';
import portProps from '@tera/shared/src/fluxoConfig/ports/msgProgramada';

type Props = {
  value: any;
  onChange: (value: any) => void;
};

export default {
  ...portProps,
  render({ value, onChange }: Props) {
    const [list, setList]: [any[], any] = useState([]);
    useEffect(() => {
      fetch('/api/mensagem_programada/box')
        .then(response => response.json())
        .then(json => setList(json.result));
    }, []);

    return (
      <div>
        <select
          style={{ width: '100%' }}
          value={value?.id ?? ''}
          onChange={e => onChange(list.find(it => it.id == e.target.value))}
        >
          <option value="">(Selecione)</option>
          {list.map(item => (
            <option value={item.id}>{item.nome}</option>
          ))}
        </select>
        {value && (
          <div>
            <i>
              {value.conteudo.split(/\r?\n/).map((line: string) => (
                <div>{line}</div>
              ))}
            </i>
          </div>
        )}
      </div>
    );
  },
};
