import React from 'react';
import styles from './LayoutUsuario.module.css';
import {
  mdiAccountBox,
  mdiAccountClock,
  mdiAccountEdit,
  mdiAccountGroup,
  mdiApi,
  mdiBullhorn,
  mdiCalendar,
  mdiCardAccountDetails,
  mdiCardAccountPhone,
  mdiChartAreaspline,
  mdiCreation,
  mdiCurrencyBrl,
  mdiHamburger,
  mdiHistory,
  mdiInvoiceImport,
  mdiMenu,
  mdiMessage,
  mdiMessageBookmark,
  mdiPhone,
  mdiSchool,
  mdiSitemap,
  mdiStar,
  mdiTag,
  mdiTextBoxEdit,
} from '@mdi/js';
import MenuItem from './MenuItem';
import { useAuth } from '../../lib/useAuth';
import { MenuGroup } from './MenuGroup';
import Icon from '@mdi/react';
import Botao from '../Botao';

const LayoutUsuario = ({ children }: { children: React.ReactNode }) => {
  const rolesAdmin = ['ADMIN'];
  const rolesManager = [...rolesAdmin, 'GESTOR'];
  const rolesUser = [...rolesManager, 'USUARIO'];

  const [menuOpen, setMenuOpen] = React.useState(false);
  const { user } = useAuth();

  if (!user) return null;

  return (
    <div className={styles.container}>
      <div
        className={[styles.sidebar, menuOpen ? styles.open : null].join(' ')}
      >
        <div className={[styles.logobar, 'font-terachat'].join(' ')}>
          <Botao
            className={styles.btnMenu}
            variant="transparent"
            onClick={() => setMenuOpen(!menuOpen)}
          >
            <Icon path={mdiMenu} size={1.5} />
          </Botao>
          <img src="/logo.png" alt="Logo Terachat" style={{ height: '32px' }} />
          <span>
            <b>Tera</b>chat
          </span>
        </div>

        <MenuGroup>
          <MenuItem
            important
            roles={rolesUser}
            label="Chats"
            icon={mdiMessage}
            path="/chat"
          />
        </MenuGroup>
        <MenuGroup>
          <MenuItem
            roles={rolesManager}
            label="Filas de atendimento"
            icon={mdiAccountClock}
            path="/fila_atendimento"
          />
          <MenuItem
            roles={rolesAdmin}
            label="Fluxos de atendimento"
            icon={mdiSitemap}
            path="/dialogo"
          />
          <MenuItem
            roles={rolesAdmin}
            label="Integrações"
            icon={mdiApi}
            path="/integracao_api"
          />
          <MenuItem
            roles={rolesAdmin}
            label="Base de conhecimento"
            icon={mdiSchool}
            path="/base_conhecimento"
          />
          <MenuItem
            roles={rolesAdmin}
            label="Agentes de IA"
            icon={mdiCreation}
            path="/ia_agente"
          />
          <MenuItem
            roles={rolesAdmin}
            label="Canais de atendimento"
            icon={mdiPhone}
            path="/canal"
          />
          <MenuItem
            roles={rolesManager}
            label="Modelos de mensagem"
            icon={mdiTextBoxEdit}
            path="/mensagem_programada"
          />
          <MenuItem
            roles={rolesUser}
            label="Atalhos de mensagem"
            icon={mdiMessageBookmark}
            path="/atalho_mensagem"
          />
          <MenuItem
            roles={rolesManager}
            label="Marcadores"
            icon={mdiTag}
            path="/marcador"
          />
          <MenuItem
            roles={rolesManager}
            label="Contatos"
            icon={mdiCardAccountPhone}
            path="/cliente"
          />
          <MenuItem
            roles={rolesManager}
            label="Grupos de contatos"
            icon={mdiCardAccountDetails}
            path="/cliente_grupo"
          />
          <MenuItem
            roles={rolesManager}
            label="Campanhas"
            icon={mdiBullhorn}
            path="/campanha_mensagem"
          />
        </MenuGroup>

        <MenuGroup>
          <MenuItem
            roles={rolesManager}
            label="Relatórios"
            icon={mdiChartAreaspline}
            path="/relatorios"
          />
          <MenuItem
            roles={rolesManager}
            label="Históricos de mensagens"
            icon={mdiHistory}
            path="/historico_mensagens"
          />
          <MenuItem
            roles={rolesAdmin}
            label="Usuários"
            icon={mdiAccountBox}
            path="/usuario"
          />
          <MenuItem
            roles={rolesAdmin}
            label="Grupos"
            icon={mdiAccountGroup}
            path="/usuario_grupo"
          />
          <MenuItem
            roles={rolesManager}
            label="Calendário"
            icon={mdiCalendar}
            path="/calendario"
          />
          <MenuItem
            roles={rolesAdmin}
            label="Faturas"
            icon={mdiCurrencyBrl}
            path="/fatura"
          />
        </MenuGroup>

        <MenuItem disabled label="Avalie-nos!" icon={mdiStar} path="/chat" />
        <MenuItem label={user?.nome} icon={mdiAccountEdit} path="/perfil" />
        <MenuItem label="Sair" icon={mdiInvoiceImport} path="/logout" />
      </div>
      <div className={styles.contents}>{children}</div>
    </div>
  );
};

export default LayoutUsuario;
