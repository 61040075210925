import { NodeType } from '../../../types/NodeType'

const instance: NodeType = {
  type: 'ticketCreate',
  label: '[Tomticket] Criar chamado',
  description: 'Cria um chamado no TomTicket.',
  category: 'Tomticket',
  inputs() {
    return [
      { type: 'route', name: 'route', label: 'Entr. fluxo' },
      { type: 'string', name: 'token', label: 'Token' },
      { type: 'string', name: 'email', label: 'Email' },
      { type: 'string', name: 'id_cliente', label: 'ID cliente' },
      { type: 'string', name: 'id_departamento', label: 'ID departamento' },
      { type: 'string', name: 'titulo', label: 'Título' },
      { type: 'string', name: 'mensagem', label: 'Mensagem' },
      // { type: ??, name: 'anexos', label: 'Anexos' },
      { type: 'number', name: 'prioridade', label: 'Prioridade' },
    ]
  },
  outputs() {

    return [
      { type: 'object', name: 'ticket', label: 'Chamado' },
      { type: 'string', name: 'id_chamado', label: 'ID chamado' },
      { type: 'string', name: 'protocolo', label: 'Protocolo' },
      { type: 'route', name: 'route-erro', label: 'Erro' },
      { type: 'route', name: 'route', label: 'Saída fluxo' }
    ]
  },
  async resolveOutputs() {
    return null
  }
}

export default instance