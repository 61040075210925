import number from './number';
import number2string from './number2string';
import numberCompare from './numberCompare';
import numberCalc from './numberCalc';

export default {
  number,
  number2string,
  numberCompare,
  numberCalc
}