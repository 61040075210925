import { NodeType } from '../../../types/NodeType'

function isValidTelefone(tel) {
  if (typeof tel !== 'string') return false
  if (tel.length < 10 || tel.length > 11) return false
  return true
}

function formatarTelefone(tel) {
  if (tel.length === 10) {
    return `(${tel.substr(0, 2)}) ${tel.substr(2, 4)}-${tel.substr(6)}`
  } else if (tel.length === 11) {
    return `(${tel.substr(0, 2)}) ${tel.substr(2, 5)}-${tel.substr(7)}`
  }
  return tel
}

const instance: NodeType = {
  type: 'extractTelefone',
  label: 'Extrair telefone',
  description: 'Extrai o telefone de um texto',
  category: 'String',
  inputs() {
    return [
      { type: 'route', name: 'route', label: 'Entr. fluxo' },
      { type: 'boolean', name: 'formatar', label: 'Formatar' },
      { type: 'string', name: 'string', label: 'Texto' },
    ]
  },
  outputs() {
    return [
      { type: 'string', name: 'telefone', label: 'Telefone' },
      { type: 'route', name: 'route', label: 'Saída fluxo' },
      { type: 'route', name: 'route-invalido', label: 'Telefone inválido' }
    ]
  },
  async resolveOutputs(inputs) {
    const telMatch = inputs.string.replace(/[^0-9]+/g, '').match(/([0-9\-\/\.]+)/g)
    if (!telMatch) return { route: 'route-invalido' }

    for (const tel of telMatch) {
      if (isValidTelefone(tel)) {
        const telNum = tel.replace(/[^0-9]+/g, '')

        const telefone = (inputs.formatar) ? formatarTelefone(telNum) : telNum

        return { telefone }
      }
    }

    return { route: 'route-invalido' }
  }
}

export default instance