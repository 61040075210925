import React from 'react';
import styles from './Subtitulo.module.css';

type SubtituloProps = {
  children: React.ReactNode;
  style?: React.CSSProperties;
};

const Subtitulo = ({ children, style }: SubtituloProps) => {
  return (
    <h3 className={styles.container} style={style}>
      {children}
    </h3>
  );
};

export default Subtitulo;
