import { Context, createContext, useContext } from 'react';

type MenuGroupContextType = {
  register: () => void;
  unregister: () => void;
};

export const MenuGroupContext: Context<MenuGroupContextType> = createContext({
  register: () => {},
  unregister: () => {},
});

export const useMenuGroup = () => useContext(MenuGroupContext);
