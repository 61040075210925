import { NodeType } from '../../../types/NodeType'

const instance: NodeType = {
  type: 'number2string',
  label: 'Número para texto',
  description: 'Converte um número para texto',
  category: 'Número',
  inputs() {
    return [
      { type: 'number', name: 'number', label: 'Número' }
    ]
  },
  outputs() {
    return [
      { type: 'string', name: 'string', label: 'Texto' }
    ]
  },
  async resolveOutputs(inputs) {
    return { string: inputs.number?.toString() }
  }
}

export default instance