import { useQuery } from 'react-query';

import portProps from '@tera/shared/src/fluxoConfig/ports/objectType';

type Obj = { id: string; nome: string };

type Props = {
  value: Obj | null;
  onChange: (value: Obj | null) => void;
};

const Port = ({ value, onChange }: Props) => {
  const query = useQuery(['estrutura-dados'], async () => {
    const res = await fetch('/api/estrutura-dados');
    const json = await res.json();
    return json?.result;
  });

  return (
    <div>
      <select
        style={{ width: '100%' }}
        value={value?.id ?? ''}
        onChange={e =>
          onChange(query.data?.find((it: Obj) => it.id == e.target.value))
        }
      >
        <option value="">(Selecione)</option>
        {query.data?.map((item: Obj) => (
          <option key={item.id} value={item.id}>
            {item.nome}
          </option>
        ))}
      </select>
    </div>
  );
};

const port = {
  ...portProps,
  render({ value, onChange }: Props) {
    return <Port value={value} onChange={onChange} />;
  },
};

export default port;
