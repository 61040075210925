import HBox from '@/components/layout/HBox';
import IconeCanal from '@/components/layout/IconeCanal';

export const contatoTipoOptions = [
  {
    id: 'whatsapp',
    label: (
      <HBox>
        <IconeCanal tipo="whatsapp" style={{ height: '16px' }} />
        WhatsApp
      </HBox>
    ),
  },
  {
    id: 'telefone',
    label: (
      <HBox>
        <IconeCanal tipo="voip" style={{ height: '16px' }} />
        Telefone
      </HBox>
    ),
  },
  {
    id: 'telegram',
    label: (
      <HBox>
        <IconeCanal tipo="telegram" style={{ height: '16px' }} />
        Telegram
      </HBox>
    ),
  },
  {
    id: 'email',
    label: (
      <HBox>
        <IconeCanal tipo="email" style={{ height: '16px' }} />
        E-mail
      </HBox>
    ),
  },
  {
    id: 'webchat',
    label: (
      <HBox>
        <IconeCanal tipo="webchat" style={{ height: '16px' }} />
        Webchat
      </HBox>
    ),
  },
];
