import LayoutUsuario from '../../components/navegacao/LayoutUsuario';
import React, { useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import styles from './edit.module.css';
import VBox from '../../components/layout/VBox';
import Modal from '../../components/layout/Modal';
import { FormularioConfiguracaoFluxo } from './FormConfiguracaoFluxo';
import { useMutation } from 'react-query';
import Botao from '../../components/Botao';
import Icon from '@mdi/react';
import { mdiCog, mdiContentSave } from '@mdi/js';
import CampoTexto from '../../components/Formulario/CampoTexto';
import HBox from '../../components/layout/HBox';

import 'flow-castro/dist/esm/index.css';

// @ts-ignore
import { NodeContainer, ThemeProvider } from 'flow-castro/dist/esm/index.js';
// @ts-ignore
import fluxoPortTypes from '../../fluxoConfig/portRenderers';
// @ts-ignore
import fluxoNodeTypes from '@tera/shared/src/fluxoConfig/nodes';
// @ts-ignore
import fluxoThemes from '@tera/shared/src/fluxoConfig/themes';
import Skeleton from '../../components/layout/Skeleton';
import axios from 'axios';
import { toast } from 'react-toastify';

type PageProps = {};

const Page = ({}: PageProps) => {
  const { id, canal_id } = useParams();

  const [fieldErrors, setFieldErrors] = useState({});
  const [editObj, setEditObj] = useState(null);
  const [model, setModel] = useState<any>(null);

  useEffect(() => {
    if (!id || id === 'novo') {
      console.log('modelo padrao');
      const _model: any = {
        conteudo: {
          nodes: {
            '1': {
              id: '1',
              type: 'start',
              name: 'Início',
              position: { x: 100, y: 100 },
              values: {},
              connections: {
                inputs: [],
                outputs: [],
              },
              root: true,
            },
          },
        },
      };

      if (canal_id && +canal_id > 0) _model.canal_id = +canal_id;
      setModel(_model);
      setState(_model.conteudo);
    } else {
      axios.get(`/api/dialogo/${id}`).then(res => {
        if (res.status === 200) {
          setModel(res.data.result);
          setState(res.data.result.conteudo);
        }
      });
    }
  }, [id, canal_id]);

  const saveNode = useMutation(
    async (data: any) => {
      setFieldErrors({});

      const res = data?.id
        ? await axios.put(`/api/dialogo/${data.id}`, data)
        : await axios.post('/api/dialogo', data);

      console.log(res.status, res.data);

      if (res.status === 422 || Math.floor(res.status / 100) === 5)
        throw new Error();
      return res.data;
    },
    {
      onSuccess: (data: any) => {
        setModel(data.result);

        toast('Salvo com sucesso', {
          toastId: 'success',
          position: 'top-center',
          type: 'success',
          autoClose: 5000,
        });

        window.history.pushState(null, '', `/dialogo/${data.result.id}`);
      },
      onError: (err: any) => {
        if (err.response?.status === 422) {
          setFieldErrors(err.response?.data?.fieldErrors ?? {});
          setEditObj({ ...model });

          toast('Existem valores inválidos. Corrija-os antes de prosseguir.', {
            toastId: 'warning',
            position: 'top-center',
            type: 'warning',
            autoClose: 5000,
          });
        } else {
          setFieldErrors({});
          toast(
            'Ocorreu um erro inesperado ao tentar salvar. Tente novamente mais tarde.',
            {
              toastId: 'error',
              position: 'top-center',
              type: 'error',
              autoClose: 5000,
            },
          );
        }
      },
    },
  );

  const handleConfirmEditObj = () => {
    setModel(editObj);
    setEditObj(null);
  };

  const handleEditObj = () => {
    setEditObj({ ...model });
  };

  const findTags = (nodes: any) => {
    const tags: string[] = [];
    Object.keys(nodes).forEach(key => {
      const node = nodes[key];
      switch (node.type) {
        case 'activityAddTag': {
          tags.push(node.values.tag);
          break;
        }
        case 'activityEnqueue':
        case 'activityRedirectToUser': {
          tags.push('TRANSFERIDO');
          break;
        }
        case 'activityOpcoes': {
          tags.push('OPCAO_NAO_ENCONTRADA');
          break;
        }
      }
    });
    return tags.filter((v, i, a) => a.indexOf(v) === i);
  };

  const portTypes = useMemo(() => fluxoPortTypes, []);
  const nodeTypes = useMemo(() => fluxoNodeTypes, []);

  const [state, setState] = useState(model?.conteudo ?? {});

  return (
    <LayoutUsuario>
      <VBox gap="16px" style={{ height: 'calc(100vh - 32px)' }}>
        <Modal
          show={editObj != null}
          onClose={() => setEditObj(null)}
          onConfirm={handleConfirmEditObj}
          title="Configurações"
          confirmProps={{
            variant: 'secondary',
          }}
        >
          <FormularioConfiguracaoFluxo
            value={editObj}
            onChange={setEditObj}
            errors={fieldErrors}
          />
        </Modal>
        <VBox style={{ height: 'calc(100vh - 3rem)' }}>
          <HBox stretch>
            <div>
              <Botao
                variant="outline-secondary"
                onClick={handleEditObj}
                icon={mdiCog}
              ></Botao>
            </div>
            <CampoTexto
              style={{ fontSize: '1.25rem', textAlign: 'center' }}
              value={model?.nome ?? ''}
              onChange={v => setModel({ ...model, nome: v })}
              placeholder="Nome do diálogo"
            />
            <Botao
              disabled={saveNode.isLoading}
              onClick={() => {
                saveNode.mutate({
                  ...model,
                  conteudo: state,
                  versao: 2,
                  marcadores: findTags(state.nodes),
                });
              }}
              icon={mdiContentSave}
            >
              Salvar
            </Botao>
          </HBox>

          <ThemeProvider themes={fluxoThemes} theme="light">
            {!state && <Skeleton format="box" />}
            {state && model && (
              <NodeContainer
                portTypes={portTypes}
                nodeTypes={nodeTypes}
                initialState={state}
                i18n={{
                  'contextMenu.search': 'Pesquisar...',
                  'contextMenu.add': '{nodeType}',
                  'contextMenu.addComment.label': 'Adicionar comentário',
                  'contextMenu.addComment.description':
                    'Adiciona um comentário ao fluxo',
                  'contextMenu.comment.label': 'comentário',
                  'contextMenu.removeThisNode': 'Remover este nó',
                  'contextMenu.removeThisComment': 'Remover este comentário',
                  'contextMenu.removeSelectedNodes': 'Remover nós selecionados',
                  'contextMenu.cloneThisNode': 'Clonar este nó',
                  'contextMenu.cloneThisComment': 'Clonar este comentário',
                  'contextMenu.removeThisConnection': 'Remover esta conexão',
                }}
                onChangeState={(v: any) => {
                  setState(v);
                }}
              />
            )}
          </ThemeProvider>
        </VBox>
      </VBox>
    </LayoutUsuario>
  );
};

export default Page;
