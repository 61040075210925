import { useQuery } from 'react-query';
import CardRelatorio from './CardRelatorio';
import Valor from './Valor';
import axios from 'axios';
import Skeleton from '../../components/layout/Skeleton';
import {
  Bar,
  BarChart,
  CartesianGrid,
  Legend,
  Line,
  LineChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';
import { useCallback, useEffect, useMemo, useState } from 'react';
import HBox from '../../components/layout/HBox';
import Botao from '../../components/Botao';

import { differenceInDays } from 'date-fns';
import { dateWithoutTimezone } from '../../lib/util';
import { FilterProps, translateFilter } from './util';
import { formatSeconds } from '@tera/shared/src/lib';

type Props = {
  colorPalette: string[];
} & FilterProps;

const parseDate = (v: string) =>
  new Date(v.substring(0, 10) + 'T00:00:00.000').toLocaleDateString();

const CardRelatorioHistoricoSessao = ({
  periodo,
  canal_id,
  grupo_id,
  colorPalette,
}: Props) => {
  const [foco, setFoco] = useState<string | null>(null);

  const calcBestInterval = () => {
    let d1 = periodo![1],
      d2 = periodo![2];
    if (!d2) {
      d2 = new Date().toISOString().substring(0, 10);
    }

    const days = differenceInDays(d2, d1);
    if (days <= 7) {
      return 'dia';
    } else if (days <= 31) {
      return 'semana';
    } else {
      return 'mes';
    }
  };

  const [selectedPeriod, setSelectedPeriod] = useState<
    'dia' | 'semana' | 'mes'
  >(calcBestInterval);

  useEffect(() => {
    setSelectedPeriod(calcBestInterval());
  }, [periodo]);

  const CustomTooltip = useCallback(
    ({ active, payload, label, separator }: any) => {
      if (active && payload && payload.length) {
        const selectedUser = payload.find((p: any) => foco == p.dataKey);

        return (
          <div
            style={{
              backgroundColor: '#fff8',
              border: '1px solid #ccc',
              padding: '0.5rem',
            }}
          >
            {payload?.[0]?.payload?.periodo && (
              <p>
                <b>{parseDate(payload[0].payload.periodo)}</b>
              </p>
            )}

            {selectedUser ? (
              <>
                <p>
                  Usuário{separator}
                  {selectedUser.name}
                </p>
                <p>
                  T. sessão{separator}
                  {formatSeconds(selectedUser.value)}
                </p>
              </>
            ) : (
              payload.map((p: any) => (
                <p key={p.dataKey}>
                  {p.name}
                  {separator}
                  {formatSeconds(p.value)}
                </p>
              ))
            )}
          </div>
        );
      }

      return null;
    },
    [foco],
  );

  const renderDot = ({
    cx,
    cy,
    fill,
    stroke,
    strokeWidth,
    strokeOpacity,
    dataKey,
  }: any) => {
    const r = 7;
    return (
      <svg
        x={cx - r * 4}
        y={cy - r}
        width={r * 8}
        height={r * 2}
        viewBox={`0 0 ${r * 8} ${r * 2}`}
        onMouseEnter={() => setFoco(dataKey)}
        onMouseLeave={() => setFoco(null)}
      >
        <rect x={0} y={0} width={r * 8} height={r * 2} fill="transparent" />
        <circle
          cx={r * 4}
          cy={r}
          r={r}
          fill={fill}
          stroke={stroke}
          strokeWidth={strokeWidth}
          opacity={!foco || dataKey === foco ? strokeOpacity : 0.2}
        />
      </svg>
    );
  };

  const queryUsuarios = useQuery(['usuario'], () => {
    return fetch('/api/usuario/box')
      .then(res => res.json())
      .then(res => res.result);
  });

  const q = useQuery(
    ['historicoSessao', selectedPeriod, periodo, canal_id],
    async () => {
      const qs = new URLSearchParams();
      qs.append('facts', 'total_sessao_segundos');
      qs.append(
        'dimensions',
        `data_criacao_${selectedPeriod},usuario_responsavel_id`,
      );
      qs.append('filter', translateFilter({ periodo, canal_id, grupo_id }));

      const res = await axios.get(
        '/api/relatorios/historico_chat_sessao?' + qs.toString(),
      );
      return res.data.result;
    },
    { refetchOnWindowFocus: false },
  );

  const dados = q.data
    ? q.data.reduce((acc: any[], row: any) => {
        const periodo = row[`data_criacao_${selectedPeriod}`].substring(0, 10);
        if (!acc[acc.length - 1] || acc[acc.length - 1].periodo != periodo)
          acc.push({ periodo, atendente: {} });
        const curr = acc[acc.length - 1];

        if (!curr.atendente[row.usuario_responsavel_id])
          curr.atendente[row.usuario_responsavel_id] = { total: 0 };

        curr.atendente[row.usuario_responsavel_id].total = parseInt(
          row.total_sessao_segundos,
        );

        return acc;
      }, [])
    : [];

  const handleMouseEnter = (o: any) => {
    const { dataKey } = o;
    setFoco(dataKey);
  };

  const handleMouseLeave = () => {
    setFoco(null);
  };

  if (q.isFetching) {
    return (
      <CardRelatorio
        titulo="T. Médio sessão chat"
        style={{ minHeight: '360px' }}
      >
        <Skeleton format="box" />
      </CardRelatorio>
    );
  }

  console.log('historico sessao: dados', selectedPeriod, q.data, dados);

  const styles = {
    legend: {
      overflow: 'auto',
      scrollX: 'auto',
    },
  };

  return (
    <CardRelatorio
      titulo="T. Médio sessão chat"
      style={{ minHeight: '360px' }}
      mostrarVisualizador={true}
      filtroVisualizador={{ periodo, canal_id }}
      metricasVisualizador={['total_sessao_segundos']}
      dimensoesVisualizador={[
        'usuario_responsavel_nome',
        `data_criacao_${selectedPeriod}`,
      ]}
      hrefVisualizador="/api/relatorios/historico_chat_sessao"
    >
      <HBox>
        <Botao
          variant={
            selectedPeriod === 'dia' ? 'outline-primary' : 'outline-secondary'
          }
          onClick={() => setSelectedPeriod('dia')}
        >
          Dia
        </Botao>
        <Botao
          variant={
            selectedPeriod === 'semana'
              ? 'outline-primary'
              : 'outline-secondary'
          }
          onClick={() => setSelectedPeriod('semana')}
        >
          Semana
        </Botao>
        <Botao
          variant={
            selectedPeriod === 'mes' ? 'outline-primary' : 'outline-secondary'
          }
          onClick={() => setSelectedPeriod('mes')}
        >
          Mês
        </Botao>
      </HBox>
      {q.isSuccess && dados.length === 0 && (
        <div
          style={{
            width: '100%',
            height: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <p>Nenhum dado encontrado</p>
        </div>
      )}
      {q.isSuccess && dados?.length === 1 && (
        <ResponsiveContainer width="100%" height={250}>
          <BarChart
            margin={{
              top: 5,
              right: 5,
              left: 5,
              bottom: 5,
            }}
            data={[dados[0]]}
          >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="periodo" />
            <YAxis tickFormatter={formatSeconds} />
            <Tooltip
              cursor={{ fill: '#8882' }}
              itemSorter={(item: any) => {
                return -item.value as number;
              }}
              itemStyle={{ padding: 0, fontSize: '0.9rem' }}
              labelStyle={{ fontWeight: 'bold' }}
              content={<CustomTooltip />}
            />
            <Legend
              onMouseEnter={handleMouseEnter}
              onMouseLeave={handleMouseLeave}
              formatter={(v, e: any, i) => {
                const { dataKey } = e;

                return (
                  <span
                    style={{
                      color: colorPalette[i % colorPalette.length],
                      opacity: !foco || foco === dataKey ? 1 : 0.2,
                      fontWeight: foco && foco === dataKey ? 'bold' : 'normal',
                    }}
                  >
                    {v}
                  </span>
                );
              }}
            />

            {queryUsuarios.data
              ?.filter((u: any) => dados[0].atendente?.[u.id])
              ?.filter(
                (u: any) =>
                  !grupo_id?.length || grupo_id.includes((u as any).grupo_id),
              )
              ?.map((u: any, index: number) => (
                <Bar
                  // stackId="a"
                  key={u.id}
                  dataKey={`atendente.${u.id}.total`}
                  name={u.nome}
                  fill={colorPalette[index % colorPalette.length]}
                  maxBarSize={50}
                  opacity={
                    !foco || foco === `atendente.${u.id}.total` ? 1 : 0.2
                  }
                  onMouseOver={() => setFoco(`atendente.${u.id}.total`)}
                  onMouseOut={() => setFoco(null)}
                />
              ))}
          </BarChart>
        </ResponsiveContainer>
      )}
      {q.isSuccess && dados?.length > 1 && (
        <HBox>
          <ResponsiveContainer height={200}>
            <LineChart
              margin={{
                top: 5,
                right: 5,
                left: 5,
                bottom: 5,
              }}
              data={dados}
            >
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="mes" interval={1} />
              <YAxis tickFormatter={formatSeconds} />
              <Tooltip
                cursor={{ fill: '#8882' }}
                itemSorter={(item: any) => {
                  return -item.value as number;
                }}
                itemStyle={{ padding: 0, fontSize: '0.9rem' }}
                labelStyle={{ fontWeight: 'bold' }}
                content={<CustomTooltip />}
              />
              <Legend
                onMouseEnter={handleMouseEnter}
                onMouseLeave={handleMouseLeave}
                wrapperStyle={{
                  height: '80px',
                  overflowY: 'auto',
                }}
                height={100}
                formatter={(v, e: any, i) => {
                  const { dataKey } = e;

                  return (
                    <span
                      style={{
                        color: colorPalette[i % colorPalette.length],
                        opacity: !foco || foco === dataKey ? 1 : 0.2,
                        fontWeight:
                          foco && foco === dataKey ? 'bold' : 'normal',
                      }}
                    >
                      {v}
                    </span>
                  );
                }}
              />

              {queryUsuarios.data
                ?.filter((u: any) =>
                  dados?.some((rr: any) =>
                    Boolean((rr as any)?.atendente?.[u.id]),
                  ),
                )
                ?.filter(
                  (u: any) =>
                    !grupo_id?.length || grupo_id.includes((u as any).grupo_id),
                )
                ?.map((u: any, index: number) => (
                  <Line
                    type="monotone"
                    // stackId="a"
                    key={u.id}
                    dataKey={`atendente.${u.id}.total`}
                    name={u.nome}
                    stroke={colorPalette[index % colorPalette.length]}
                    strokeOpacity={
                      !foco || foco === `atendente.${u.id}.total` ? 1 : 0.2
                    }
                    strokeDasharray={
                      !foco || foco === `atendente.${u.id}.total` ? `0` : `5 5`
                    }
                    activeDot={renderDot}
                  />
                ))}
            </LineChart>
          </ResponsiveContainer>
        </HBox>
      )}
    </CardRelatorio>
  );
};

export default CardRelatorioHistoricoSessao;
