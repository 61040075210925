import React from 'react';
import styles from './ConteudoImagem.module.css';
import Modal from '../../../components/layout/Modal';

export const ConteudoImagem = ({ value }: { value: string }) => {
    const [showImg, setShowImg] = React.useState<boolean>(false);

    return (
        <>
            <Modal title="Imagem" show={showImg} onClose={() => setShowImg(false)} hideControls>
                <div className={styles.fullImgContainer}>
                    <img className={styles.fullImg} src={`/api/assets${value}`} alt="imagem" />
                </div>
            </Modal>

            <div className={styles.container} onClick={() => setShowImg(true)}>
                <div>
                    <img className={styles.img} src={`/api/assets${value}`} alt="imagem" />
                </div>
            </div>
        </>
    )
}