
import Titulo from '../components/tipografia/Titulo'
import { useEffect, useState } from 'react'
import styles from './onboard.module.css'
import { AnimatedBackground } from './login/AnimatedBackground'
import useWindowSize from '../lib/useWindowSize'
import Botao from '../components/Botao'
import { mdiPauseCircle, mdiPlayCircle } from '@mdi/js'
import VBox from '../components/layout/VBox'
import AlertBox from '../components/layout/AlertBox'

const Page = () => {
  const [success, setSuccess] = useState<'true' | 'partial' | 'false' | null>(null)

  const [width, height, mobile] = useWindowSize();
  const [pause, setPause] = useState(false);

  const [debug, setDebug] = useState<any>(null);

  useEffect(() => {
    const params = new URLSearchParams(window.location.search)
    const body = Object.fromEntries(params.entries())

    fetch('/api/onboard', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(body)
    }).then(result => {
      if (!result.ok) {
        setSuccess('false')
      } else {
        result.json().then(json => {
          const params = {
            search: window.location.search,
            origin: window.location.origin
          }; // retrieve the current URL search parameters

          // Check if there is an opener window
          if (window.opener) {
            window.opener.postMessage(params); // post the parameters to the opener window
            // if (json.allOK)
            //   setTimeout(() => window.close(), 3000); // close the current window
          }

          setDebug(json.result)

          if (json.allOK)
            setSuccess('true')
          else if (json.allError)
            setSuccess('false')
          else
            setSuccess('partial')
        })
      }
    })

    
  }, [])

  return (
    <div className={styles.container}>
      
      <div className={styles.content}>
        <div style={{ textAlign: 'center' }}>
          <img src="/logo.png" alt="Logo" style={{ height: '96px' }} />
        </div>

        <h2>Onboard!</h2>

      
        {success === null && (
          <AlertBox variant="info">
            Criando contas. Aguarde...
          </AlertBox>
        )}

        {success === 'false' && (
          <AlertBox variant="danger">
            <p>Ocorreu um erro ao tentar criar as contas. Tente novamente mais tarde.</p>
            <p>Se o erro persistir, entre em contato com nosso suporte.</p>
            <p>(Você já pode fechar esta janela.)</p>
          </AlertBox>
        )}
        {success === 'partial' && (
          <AlertBox variant="warning">
            <p>Algumas contas foram habilitadas com sucesso. Verifique os canais habilitados, e tente novamente para habilitar os demais.</p>
            <p>Se o erro persistir, entre em contato com nosso suporte.</p>
            <p>(Você já pode fechar esta janela.)</p>
          </AlertBox>
        )}
        {success === 'true' && (
          <AlertBox variant="success">
            <p>Contas criadas com sucesso!</p>
            <p>(Você já pode fechar esta janela.)</p>
          </AlertBox>
        )}

        {debug && (
          <AlertBox variant='info'>
            <p>Segue abaixo o resultado:</p>
            {debug?.add?.map((item:any, i:number) => (
              <p key={i} className={styles[`status-${item.status}`]}>Configurar: {item.clientId} - {item.msg}</p>
            ))}
            {debug?.remove?.map((item:any, i:number) => (
              <p key={i} className={styles[`status-${item.status}`]}>Remover: {item.clientId} - {item.msg}</p>
            ))}
          </AlertBox>
        )}
      </div>

      <div style={{ position: 'absolute', top: 10, right: 10 }}>
        {!pause && <Botao variant="transparent" onClick={() => { 
            setPause(true)
        }}
        icon={mdiPauseCircle}
        tabIndex={0}
        >
          Pausar animação
        </Botao>}
        {pause && <Botao variant="transparent" onClick={() => { 
            setPause(false)
        }} icon={mdiPlayCircle}
        tabIndex={0}
        >
          Continuar animação
        </Botao>}
        
      </div>
      <AnimatedBackground width={width} height={height} pause={pause} />
    </div>
  )
}

export default Page