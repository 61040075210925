import { NodeType } from '../../../types/NodeType'

export const regexTimestamp = /^(?:(\d+)([dsma])\s?)?(?:(\d{1,2}):(\d{1,2}):(\d{1,2}))?$/
export const n2s = (n) => {
  if (typeof n == 'string') return n

  const days = Math.floor(n / (24 * 60 * 60 * 1000))
  const hours = (Math.floor(n / (60 * 60 * 1000)) % 24).toString().padStart(2, '0')
  const minutes = (Math.floor(n / (60 * 1000)) % 60).toString().padStart(2, '0')
  const seconds = (Math.floor(n / 1000) % 60).toString().padStart(2, '0')
  return `${days}d ${hours}:${minutes}:${seconds}`
}

const instance: NodeType = {
  type: 'timestamp',
  label: 'Tempo',
  description: 'Tempo',
  category: 'Tempo',
  inputs() {
    return [
      { type: 'timestamp', name: 'timestamp', label: 'Tempo' }
    ]
  },
  outputs() {
    return [
      { type: 'timestamp', name: 'timestamp', label: 'Tempo' }
    ]
  },
  async resolveOutputs(inputs) {
    return { timestamp: inputs.timestamp }
  }
}

export default instance