import CampoMultiSeletor from '../../components/Formulario/CampoMultiSeletor';
import axios from 'axios';
import { useQuery } from 'react-query';

type FiltroCanaisProps = {
  value: string[];
  onChange: (value: string[]) => void;
};
export const FiltroCanais = ({ value, onChange }: FiltroCanaisProps) => {
  const q = useQuery(['canais:box'], async () => {
    const res = await axios.get<{ result: any[] }>('/api/canal/box', {
      withCredentials: true,
    });
    return res.data;
  });

  const options =
    q.data?.result?.map(item => ({
      id: item.id,
      label: item.nome,
    })) ?? [];

  return (
    <CampoMultiSeletor
      label="Canais"
      placeholder="(Qualquer)"
      options={options}
      value={value}
      onChange={onChange}
    />
  );
};
