import CampoRadio from '../../components/Formulario/CampoRadio';
import { CampoSlider } from '../../components/Formulario/CampoSlider';
import Grid, { GridItem } from '../../components/layout/Grid';

const num2time = (num: number) => {
  if (!num) return null;

  const h = Math.floor(num / 60);
  const m = num % 60;
  return `${h.toString().padStart(2, '0')}:${m.toString().padStart(2, '0')}`;
};

const time2num = (time: string) => {
  if (!time) return null;

  const [h, m] = time.split(':');
  return parseInt(h) * 60 + parseInt(m);
};

export const DiaExpediente = ({
  value,
  onChange,
}: {
  value: any;
  onChange: (v: any) => void;
}) => {
  return (
    <Grid style={{ borderBottom: '1px solid #888' }}>
      <GridItem sm={12} lg={6}>
        <CampoRadio
          id="exp-todo"
          checked={value == null || value === true}
          onChange={() => onChange(true)}
          label="Todo o dia"
        />
        <CampoRadio
          id="exp-sem"
          checked={value === false}
          onChange={() => onChange(false)}
          label="Sem expediente"
        />
        <CampoRadio
          id="exp-itvl"
          checked={Array.isArray(value)}
          onChange={() => onChange([{ inicio: 8 * 60, fim: 18 * 60 }])}
          label="Intervalo"
        />
      </GridItem>
      <GridItem sm={12} lg={6}>
        {Array.isArray(value) && (
          <div>
            <div
              style={{
                width: '100%',
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
              }}
            >
              <input
                type="time"
                className="form-control"
                style={{
                  width: '50%',
                  display: 'inline-block',
                  border: 'none',
                  borderBottom: '1px solid #888',
                  padding: '0 0 0 10px',
                }}
                value={num2time(value?.[0]?.inicio) ?? ''}
                onChange={e =>
                  onChange([
                    { inicio: time2num(e.target.value), fim: value?.[0]?.fim },
                  ])
                }
              />
              <span>até</span>
              <input
                type="time"
                className="form-control"
                style={{
                  width: '50%',
                  display: 'inline-block',
                  border: 'none',
                  borderBottom: '1px solid #888',
                  padding: '0 0 0 10px',
                }}
                value={num2time(value?.[0]?.fim) ?? ''}
                onChange={e =>
                  onChange([
                    {
                      inicio: value?.[0]?.inicio,
                      fim: time2num(e.target.value),
                    },
                  ])
                }
              />
            </div>
            {Array.isArray(value) && (
              <CampoSlider
                range={[value?.[0]?.inicio ?? 0, value?.[0]?.fim ?? 24 * 60]}
                min={0}
                max={24 * 60}
                step={15}
                subSteps={4}
                onChange={range =>
                  onChange([{ inicio: range[0], fim: range[1] }])
                }
              />
            )}
          </div>
        )}
      </GridItem>
    </Grid>
  );
};
