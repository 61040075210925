import { Children, useState } from 'react';
import { MenuGroupContext } from './useMenuGroup';

type MenuGroupProps = { children: React.ReactNode };
export const MenuGroup = ({ children }: MenuGroupProps) => {
  const [activeItems, setActiveItems] = useState(0);

  const register = () => setActiveItems(c => c + 1);
  const unregister = () => setActiveItems(c => c - 1);

  const cnt = Children.count(children);

  return (
    <MenuGroupContext.Provider value={{ register, unregister }}>
      <div>
        {children}
        {activeItems > 0 && <hr />}
      </div>
    </MenuGroupContext.Provider>
  );
};
