import { mdiCash, mdiCash100, mdiPrinter } from '@mdi/js';
import Icon from '@mdi/react';
import Botao from '@/components/Botao';
import Titulo from '@/components/tipografia/Titulo';
import styles from './view_fatura.module.css';
import { useQuery } from 'react-query';
import { useMemo } from 'react';
import Subtitulo from '@/components/tipografia/Subtitulo';
import { useParams } from 'react-router-dom';
import LayoutUsuario from '@/components/navegacao/LayoutUsuario';
import Grid, { GridItem } from '@/components/layout/Grid';

const formatCnpj = (cnpj: string) => {
  if (!cnpj) return '';

  return cnpj.replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, '$1.$2.$3/$4-$5');
};

const Page = () => {
  const params = useParams();

  const { id } = params;

  const query = useQuery(
    ['fatura', id],
    async () => {
      const res = await fetch('/api/fatura/' + id);
      return res.json();
    },
    {
      enabled: !!id,
    },
  );

  const moneyFormat = new Intl.NumberFormat('pt-BR', {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
    style: 'currency',
    currency: 'BRL',
  });
  const numFormat = new Intl.NumberFormat('pt-BR', {
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  });

  const fatura = query.data?.fatura;
  const org = query.data?.org;

  const vencido = Boolean(
    fatura?.data_vencimento && new Date(fatura.data_vencimento) < new Date(),
  );

  const ftEstLabels: Record<string, string> = useMemo(() => {
    return {
      pendente: vencido ? 'Pendente' : 'Em aberto',
      paga: 'Paga',
      cancelada: 'Cancelada',
    };
  }, [vencido]);

  if (query.isLoading || !query.data || !fatura) return null;

  const vlTotal = fatura.itens.reduce(
    (acc: number, item: Record<string, any>) =>
      acc + Number(item.valor) * Number(item.quantidade),
    0,
  );

  const diff = fatura.fatura_valor - vlTotal;

  return (
    <LayoutUsuario>
      <div className={styles.container}>
        <div
          className="no-print"
          style={{ margin: '1rem 0', textAlign: 'center' }}
        >
          <Botao
            variant="primary"
            onClick={() => window.print()}
            icon={mdiPrinter}
          >
            Imprimir
          </Botao>

          {fatura.fatura_estado === 'pendente' && (
            <a
              href={`/api/fatura/${fatura.id}/boleto`}
              className="btn btn-success"
              target="_blank"
            >
              <Icon path={mdiCash} size={1} />
              Pagar
            </a>
          )}
        </div>

        <Grid>
          <GridItem sm={3} p={4} className={styles.logo}>
            <img src="/logo.png" style={{ width: '120px' }} />
            <div style={{ padding: '0.5rem', color: '#103668' }}>
              terachat.com.br
            </div>
            <div style={{ padding: '0 0.5rem', color: '#103668' }}>
              49.674.821/0001-00
            </div>
          </GridItem>
          <GridItem sm={5} p={4}>
            <p className={styles.fatura_rotulo}>Dados do cliente</p>
            <p className={styles.fatura_texto}>{org?.nome_fantasia}</p>
            <p className={styles.fatura_texto}>{formatCnpj(org?.cnpj)}</p>

            <p className={styles.fatura_rotulo}>Data de fechamento</p>
            <p className={styles.fatura_texto}>
              {new Date(fatura.data_fechamento_fatura).toLocaleDateString()}
            </p>

            <p className={styles.fatura_rotulo}>Data de vencimento</p>
            <p className={styles.fatura_texto}>
              {new Date(fatura.data_vencimento).toLocaleDateString()}
            </p>
          </GridItem>
          <GridItem sm={4} p={4}>
            <div style={{ textAlign: 'end' }}>
              <Titulo>Fatura</Titulo>
              <p className={styles.fatura_rotulo}>Número: #{id}</p>
              <p
                className={[
                  styles.badge,
                  styles[`badge-${fatura.fatura_estado}-${vencido}`],
                ].join(' ')}
              >
                {ftEstLabels[fatura.fatura_estado]}
              </p>
              <p className="text-muted" style={{ fontSize: '0.8rem' }}>
                Gerado em {new Date().toLocaleString()}
              </p>
            </div>
          </GridItem>
        </Grid>

        <table className={styles.fatura_container}>
          <thead>
            <tr>
              <th style={{ width: '40%' }}>Descrição</th>
              <th style={{ width: '25%', textAlign: 'end' }}>Valor unitário</th>
              <th style={{ width: '10%', textAlign: 'center' }}>Quantidade</th>
              <th style={{ width: '25%', textAlign: 'end' }}>Valor total</th>
            </tr>
          </thead>
          <tbody>
            {fatura.itens.map((item: any) => (
              <tr>
                <td>
                  <div>
                    <b>{item.titulo}</b>
                  </div>
                  <div>{item.descricao}</div>
                </td>
                <td style={{ textAlign: 'end' }}>
                  {moneyFormat.format(item.valor)}
                </td>
                <td style={{ textAlign: 'center' }}>
                  {numFormat.format(item.quantidade)}
                </td>
                <td style={{ textAlign: 'end' }}>
                  {moneyFormat.format(
                    Number(item.valor) * Number(item.quantidade),
                  )}
                </td>
              </tr>
            ))}

            {diff < -0.01 && (
              <>
                <tr className={styles.sumario}>
                  <td colSpan={3} style={{ textAlign: 'end' }}>
                    <div style={{ paddingRight: '2rem' }}>Fatura</div>
                  </td>
                  <td>
                    <div>{moneyFormat.format(vlTotal)}</div>
                  </td>
                </tr>
                <tr>
                  <td colSpan={3} style={{ textAlign: 'end' }}>
                    <div style={{ paddingRight: '2rem' }}>Desconto</div>
                  </td>
                  <td>
                    <div>{moneyFormat.format(diff)}</div>
                  </td>
                </tr>
              </>
            )}

            <tr className={styles.sumario}>
              <td colSpan={3} style={{ textAlign: 'end' }}>
                <div style={{ paddingRight: '2rem', fontWeight: 'bolder' }}>
                  Total
                </div>
              </td>
              <td>
                <div style={{ fontWeight: 'bolder' }}>
                  {moneyFormat.format(fatura.fatura_valor)}
                </div>
              </td>
            </tr>
          </tbody>
        </table>

        {fatura.obs && (
          <div
            style={{
              fontSize: '0.9rem',
              textAlign: 'center',
              marginTop: '0.5rem',
            }}
          >
            <b>Nota:</b>
            {fatura.obs.split('\n').map((item: any, key: any) => {
              return <div key={key}>{item}</div>;
            })}
          </div>
        )}

        <div className={styles.thank_you}>
          <p style={{ fontWeight: 'bold', textAlign: 'center' }}>Obrigado!</p>
          <p>
            Valorizamos a oportunidade de ser a ponte que liga seus canais de
            comunicação, tornando-os mais eficazes e humanos.
          </p>
          <p>Sua parceria é fundamental para nossas conexões verdadeiras.</p>
        </div>
      </div>
    </LayoutUsuario>
  );
};

export default Page;
