import { NodeType } from '../../../types/NodeType'
import { n2s } from '../timestamp/timestamp'

const instance: NodeType = {
  type: 'datetimeDiff',
  label: 'Diferença de Data/hora',
  description: 'Calcula a diferença entre Datas/horas e retorna um tempo',
  category: 'Data/hora',
  inputs() {
    return [
      { type: 'datetime', name: 'datetime1', label: 'Data/hora 1' },
      { type: 'datetime', name: 'datetime2', label: 'Data/hora 2' }
    ]
  },
  outputs() {
    return [
      { type: 'timestamp', name: 'timestamp', label: 'Tempo' }
    ]
  },
  async resolveOutputs(inputs) {
    if (!inputs.datetime1 && !inputs.datetime2) return { timestamp: '' }

    const date1 = new Date(inputs.datetime1)
    const date2 = new Date(inputs.datetime2)

    const dateDiff = Math.abs(date1.getTime() - date2.getTime())

    return { timestamp: n2s(dateDiff) }
  }
}

export default instance