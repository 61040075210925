import { NodeType } from '../../../types/NodeType'
import { format } from 'date-fns'

const instance: NodeType = {
  type: 'formatDatetime',
  label: 'Formatar Data/hora',
  description: 'Formata uma Data/hora a partir de uma máscara',
  category: 'Data/hora',
  inputs() {
    return [
      { type: 'datetime', name: 'datetime', label: 'Data/hora' },
      { type: 'string', name: 'mask', label: 'Máscara' }
    ]
  },
  outputs() {
    return [
      { type: 'string', name: 'string', label: 'Texto' }
    ]
  },
  async resolveOutputs(inputs) {
    if (!inputs.datetime) return { string: '' }
    const date = new Date(inputs.datetime)
    const mask = inputs.mask

    const masked = format(date, mask)

    return { string: masked }
  }
}

export default instance