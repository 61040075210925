import React from 'react';
import styles from './ConteudoMidia.module.css';
import Icon from '@mdi/react';
import { mdiFile } from '@mdi/js';

type ConteudoMidiaProps = {
    value: string;
    mime: string;
};

const ConteudoMidia = ({ mime, value }:ConteudoMidiaProps) => {
    return (
        <a className={styles.container} rel="noreferrer" href={`/api/assets${value}`} target="_blank">
            <Icon path={mdiFile} size={2} color="var(--tc-color-gray-500)" />
            <div>
                <div>Download</div>
                <div className={styles.line2}>{mime}</div>
            </div>
        </a>
    );
};

export default ConteudoMidia;