import { useEffect, useState } from 'react';
import portProps from '@tera/shared/src/fluxoConfig/ports/domain/flow';

type Obj = { id: number; nome: string };

type Props = {
  value: Obj | null;
  onChange: (value: Obj | undefined) => void;
};

const Port = ({ value, onChange }: Props) => {
  const [list, setList] = useState([]);
  useEffect(() => {
    fetch('/api/dialogo/box')
      .then(response => response.json())
      .then(json => setList(json.result));
  }, []);

  return (
    <div>
      <select
        style={{ width: '100%' }}
        value={value?.id ?? ''}
        onChange={e =>
          onChange(list.find((it: Obj) => it.id?.toString() === e.target.value))
        }
      >
        <option value="">(Selecione)</option>
        {list.map((item: Obj) => (
          <option key={item.id} value={item.id}>
            {item.nome}
          </option>
        ))}
      </select>
    </div>
  );
};

const port = {
  ...portProps,

  render({ value, onChange }: Props) {
    return <Port value={value} onChange={onChange} />;
  },
};

export default port;
