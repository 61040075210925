import { NodeType } from '../../../types/NodeType'

const instance: NodeType = {
  type: 'flow',
  label: 'Fluxo de atendimento',
  description: 'Fluxo de atendimento',
  category: 'Domínio',
  inputs() {
    return [
      { type: 'domainFlow', name: 'domainFlow', label: 'Fluxo de atendimento' },
    ]
  },
  outputs() {
    return [
      { type: 'number', name: 'number', label: 'ID' }
    ]
  },
  async resolveOutputs(inputs) {
    return {
      number: inputs.domainFlow?.id
    }
  }
}

export default instance