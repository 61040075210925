import { CSSProperties } from 'react';
import styles from './MarcadorSm.module.css';

type Props = {
  title?: string;
  color?: string;
  style?: CSSProperties;
};

const MarcadorSm = ({ title, color, style }: Props) => {
  return (
    <div
      style={{ ...style, backgroundColor: color }}
      className={[styles.marcador].join(' ')}
      title={title}
    ></div>
  );
};

export default MarcadorSm;
