import { NodeType } from '../../../types/NodeType'

function isValidCNPJ(cnpj) {
  if (typeof cnpj !== 'string') return false
  cnpj = cnpj.replace(/[^\d]+/g, '')
  if (cnpj.length !== 14 || !!cnpj.match(/(\d)\1{13}/)) return false

  const b = [6, 5, 4, 3, 2, 9, 8, 7, 6, 5, 4, 3, 2]
  cnpj = cnpj.split('').map(el => +el)
  let n = 0
  for (let i = 0; i < 12; n += cnpj[i] * b[++i]);
  if (cnpj[12] != (((n %= 11) < 2) ? 0 : 11 - n))
    return false

  n = 0;
  for (let i = 0; i <= 12; n += cnpj[i] * b[i++]);
  if (cnpj[13] != (((n %= 11) < 2) ? 0 : 11 - n))
    return false

  return true
}

const instance: NodeType = {
  type: 'extractCnpj',
  label: 'Extrair CNPJ',
  description: 'Extrai o CNPJ de um texto',
  category: 'String',
  inputs() {
    return [
      { type: 'route', name: 'route', label: 'Entr. fluxo' },
      { type: 'boolean', name: 'formatar', label: 'Formatar' },
      { type: 'string', name: 'string', label: 'Texto' },
    ]
  },
  outputs() {
    return [
      { type: 'string', name: 'cnpj', label: 'CNPJ' },
      { type: 'route', name: 'route', label: 'Saída fluxo' },
      { type: 'route', name: 'route-invalido', label: 'CNPJ inválido' }
    ]
  },
  async resolveOutputs(inputs) {
    const cnpjMatch = inputs.string?.match(/([0-9\-\/\.]+)/g)
    if (cnpjMatch == null) return { route: 'route-invalido' }

    for (const cnpj of cnpjMatch) {
      if (isValidCNPJ(cnpj)) {
        if (inputs.formatar) {
          const cnpjFormatado = cnpj.replace(/[^0-9]+/g, '')
          return { cnpj: `${cnpjFormatado.substr(0, 2)}.${cnpjFormatado.substr(2, 3)}.${cnpjFormatado.substr(5, 3)}/${cnpjFormatado.substr(8, 4)}-${cnpjFormatado.substr(12, 2)}`, route: 'route' }
        }

        return { cnpj: cnpj.replace(/[^0-9]+/g, ''), route: 'route' }
      }
    }
    return { route: 'route-invalido' }
  }
}

export default instance