import { MarcadorDto } from '@tera/shared/src/dto/MarcadorDto';
import MarcadorSm from './MarcadorSm';

type Props = {
  tags: MarcadorDto[];
  ids?: number[];
};

const MarcadoresSm = ({ tags, ids }: Props) => {
  return ids?.map((mid, idx) => {
    console.log('tags', tags);

    const tag = tags?.find((t: MarcadorDto) => +t.id == +mid);

    if (!tag) return null;

    return <MarcadorSm color={tag.cor} key={idx} title={tag.nome} />;
  });
};

export default MarcadoresSm;
