import { NodeType } from '../../../types/NodeType'

const instance: NodeType = {
  type: 'delay',
  label: 'Atraso (delay)',
  description: 'Aguarda um tempo antes de continuar o fluxo',
  category: 'Fluxo',
  inputs() {
    return [
      { type: 'route', name: 'route', label: 'Entr. fluxo' },
      { type: 'delay', name: 'delay', label: 'Tempo de atraso' },
    ]
  },
  outputs() {
    return [
      { type: 'route', name: 'route', label: 'Saida fluxo' }
    ]
  },
  async resolveOutputs() {
    return null
  }
}

export default instance