import { NodeType } from '../../../types/NodeType'
import { regexTimestamp } from '../timestamp/timestamp'

const instance: NodeType = {
  type: 'datetimeSub',
  label: 'Subtrai de Data/hora',
  description: 'Subtrai um tempo de uma Data/hora',
  category: 'Data/hora',
  inputs() {
    return [
      { type: 'datetime', name: 'datetime', label: 'Data/hora' },
      { type: 'timestamp', name: 'timestamp', label: 'Tempo' }
    ]
  },
  outputs() {
    return [
      { type: 'datetime', name: 'datetime', label: 'Data/hora' }
    ]
  },
  async resolveOutputs(inputs) {
    if (!inputs.datetime) return { datetime: '' }

    const date = new Date(inputs.datetime)
    const timestamp = inputs.timestamp

    const m = regexTimestamp.exec(timestamp)
    if (m) {
      const days = (m[1] ? parseInt(m[1]) : 0) * (m[2] == 'd' ? 1 : m[2] == 's' ? 7 : m[2] == 'm' ? 30 : m[2] == 'a' ? 365 : 1)
      const hours = m[3] ? parseInt(m[3]) : 0
      const minutes = m[4] ? parseInt(m[4]) : 0
      const seconds = m[5] ? parseInt(m[5]) : 0

      const timestamp = (days * 24 * 60 * 60 + hours * 60 * 60 + minutes * 60 + seconds) * 1000

      return { datetime: new Date(date.getTime() - timestamp).toISOString() }
    }

    return { datetime: inputs.datetime }
  }
}

export default instance