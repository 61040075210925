import portProps from '@tera/shared/src/fluxoConfig/ports/stringOperator'

type Props = {
  value: string | null
  onChange: (value: string) => void
}

const Port = ({ value, onChange }: Props) => {
  return <select style={{ width: '100%' }} value={value ?? ''} onChange={(e) => onChange(e.target.value)}>
    <option value="equals">Igual a</option>
    <option value="notEquals">Diferente de</option>
    <option value="contains">Contém</option>
    <option value="notContains">Não Contém</option>
    <option value="startsWith">Começa com</option>
    <option value="endsWith">Termina com</option>
  </select>
}

const port = {
  ...portProps,
  render({ value, onChange }: Props) {
    return <Port value={value} onChange={onChange} />
  }
}

export default port