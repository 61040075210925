import styles from './HBox.module.css';

type HBoxProps = {
    children?: React.ReactNode;
    gap?: number | string;
    className?: string;
    style?: React.CSSProperties;
    stretch?: boolean;
}

const HBox =  ({ children, gap, className, style, stretch }: Readonly<HBoxProps>) => {
    return (
        <div className={[styles.hbox, stretch ? styles.stretch : null, className].join(' ')} style={{...(style ?? {}), gap}}>{children}</div>
    )
}

export default HBox;