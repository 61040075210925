import Titulo from '../../components/tipografia/Titulo';
import React, { useCallback } from 'react';
import styles from './page.module.css';
import LayoutUsuario from '../../components/navegacao/LayoutUsuario';
import Descricao from '../../components/tipografia/Descricao';
import HBox from '../../components/layout/HBox';
import Botao from '../../components/Botao';
import VBox from '../../components/layout/VBox';
import CampoTexto from '../../components/Formulario/CampoTexto';
import Icon from '@mdi/react';
import { mdiLoading, mdiMagnify, mdiPen, mdiPlus, mdiTrashCan } from '@mdi/js';
import DataTable, { DataTableHeader } from '../../components/layout/DataTable';
import { useInfiniteQuery, useMutation, useQuery } from 'react-query';
import axios from 'axios';
import BotaoPerigoso from '../../components/BotaoPerigoso';
import { useDebounce } from '../chat/useDebounce';
import { toast } from 'react-toastify';
import Modal from '../../components/layout/Modal';
import { FormBaseConhecimento } from './FormBaseConhecimento';

type ResponseList = {
  status: number;
  ok?: boolean;
  result: any[];
  total: number;
  cursor: string | number | null;
};

type Props = {};

const numFormatter = new Intl.NumberFormat('pt-BR', {
  style: 'decimal',
  maximumFractionDigits: 2,
});

const Page = ({}: Props) => {
  const [editObject, setEditObject] = React.useState<any>(null);
  const [fieldErrors, setFieldErrors] = React.useState<Record<
    string,
    string
  > | null>(null);

  const [search, setSearch] = React.useState<string>('');
  const debouncedSearch = useDebounce(search, 500);

  const headers: DataTableHeader[] = [
    { label: 'Título', width: '50%', column: 'titulo' },
    {
      label: 'Qtd. Seções',
      width: '50%',
      render: rows => {
        return rows.secoes?.length ?? '--';
      },
    },
    {
      label: 'Ações',
      width: '100px',
      stickRight: true,
      alignRight: true,
      render: (row: any) => {
        return (
          <HBox gap="8px" style={{ justifyContent: 'flex-end' }}>
            <Botao
              variant="none-primary"
              icon={mdiPen}
              onClick={() => setEditObject(row)}
            />
            <BotaoPerigoso
              variant="none-danger"
              icon={mdiTrashCan}
              onClick={() => handleDelete(row.id)}
            />
          </HBox>
        );
      },
    },
  ];

  const headersSemantic: DataTableHeader[] = [
    {
      label: 'Pontuação',
      width: '50px',
      render: (row): React.ReactNode => {
        return numFormatter.format(row.relevancia_combinada);
      },
    },
    {
      label: 'Título',
      width: '50%',
      column: 'titulo',
    },
    {
      label: 'Subtítulo',
      width: '50%',
      column: 'secao_titulo',
    },
  ];

  const queryList = useInfiniteQuery({
    queryKey: ['base_conhecimento', debouncedSearch],
    queryFn: async ({ pageParam }) => {
      const params = new URLSearchParams({
        txt: debouncedSearch,
        c: pageParam,
      });

      return await axios.get<ResponseList>(
        `/api/base_conhecimento?${params.toString()}`,
        {
          withCredentials: true,
        },
      );
    },
    getNextPageParam: lastPage => lastPage.data.cursor ?? null,
  });

  const changeMutator = useMutation(
    async () => {
      if (!editObject) return;

      if (editObject.secoes) {
        for (let i = 0; i < editObject.secoes?.length; i++) {
          const s = editObject.secoes[i];
          s.indice = i;
        }
      }

      setFieldErrors(null);
      if (editObject.id) {
        return await axios.put(
          `/api/base_conhecimento/${editObject.id}`,
          editObject,
          {
            withCredentials: true,
          },
        );
      } else {
        return await axios.post(`/api/base_conhecimento`, editObject, {
          withCredentials: true,
        });
      }
    },
    {
      onSuccess: () => {
        setFieldErrors(null);
        queryList.refetch();
        setEditObject(null);
      },
      onError: (err: any) => {
        if (err.response?.status === 422) {
          setFieldErrors(err.response?.data?.fieldErrors ?? {});
        }
        toast(err.response?.data?.msg ?? 'Erro desconhecido', {
          toastId: 'error',
          position: 'top-center',
          type: 'warning',
          autoClose: 5000,
        });
      },
    },
  );

  const deleteMutator = useMutation(
    async (id: number) => {
      return await axios.delete(`/api/base_conhecimento/${id}`, {
        withCredentials: true,
      });
    },
    {
      onSuccess: () => {
        toast('Documento apagado com sucesso', {
          toastId: 'success',
          position: 'top-center',
          type: 'success',
          autoClose: 5000,
        });
        queryList.refetch();
      },
      onError: (err: any) => {
        console.error(err);
        toast('Ocorreu um erro ao tentar apagar o documento.', {
          toastId: 'error',
          position: 'top-center',
          type: 'warning',
          autoClose: 5000,
        });
      },
      onSettled: () => {
        queryList.refetch();
      },
    },
  );

  const handleChange = useCallback(async () => {
    await changeMutator.mutate();
  }, [changeMutator]);

  const handleDelete = (id: number) => {
    deleteMutator.mutate(id);
  };

  const handleCloseModal = () => {
    setEditObject(null);
    setFieldErrors(null);
  };

  const handleOpenModalNewObject = () => {
    setEditObject({
      secoes: [{ titulo: '', conteudo: '' }],
    });
  };

  const queryFlatList =
    queryList.data?.pages?.map(p => p.data.result)?.flat() ?? [];
  const total =
    queryList.data?.pages?.[queryList.data.pages.length - 1]?.data?.total ?? 0;
  const editMode = !!editObject?.id && editObject.id > 0;

  const [showSemanticSearch, setShowSemanticSearch] = React.useState(false);
  const [semanticSearch, setSemanticSearch] = React.useState<string>('');

  const debouncedSemanticSearch = useDebounce(semanticSearch, 500);

  const qSemantic = useQuery({
    queryKey: ['base_conhecimento_semantic', debouncedSemanticSearch],
    queryFn: async () => {
      return await axios.get(
        `/api/base_conhecimento/busca?txt=${semanticSearch}`,
      );
    },
    enabled: debouncedSemanticSearch?.length > 0,
  });

  return (
    <LayoutUsuario>
      <Modal
        size="md"
        title="Busca semântica"
        show={showSemanticSearch}
        onClose={() => setShowSemanticSearch(false)}
        onCancel={() => setShowSemanticSearch(false)}
        confirmProps={{
          visible: false,
        }}
        cancelProps={{
          label: 'Fechar',
        }}
      >
        <VBox>
          <CampoTexto
            placeholder="Buscar por"
            prepend={
              <Icon
                path={mdiMagnify}
                size="16px"
                color="var(--tc-color-gray-500)"
              />
            }
            value={semanticSearch}
            onChange={setSemanticSearch}
          />
          {qSemantic.isLoading && (
            <div>
              Aguarde...{' '}
              <Icon
                path={mdiLoading}
                size="14px"
                color="var(--tc-color-gray-700)"
                spin
              />
            </div>
          )}
          {!qSemantic.isLoading && (
            <div>
              Exibindo {qSemantic.data?.data?.result?.length ?? '0'} de{' '}
              {qSemantic.data?.data?.total ?? '0'} resultados.
            </div>
          )}
          <DataTable
            isLoading={qSemantic.isLoading}
            cabecalhos={headersSemantic}
            linhas={qSemantic.data?.data?.result ?? []}
            hasMoreData={false}
            onLoadMore={() => {}}
          />
        </VBox>
      </Modal>

      <Modal
        size="lg"
        ignoreOverlayClick
        title={editMode ? 'Editar documento' : 'Novo documento'}
        show={!!editObject}
        onClose={handleCloseModal}
        onCancel={handleCloseModal}
        onConfirm={handleChange}
        confirmProps={{
          disabled: changeMutator.isLoading,
        }}
        cancelProps={{
          disabled: changeMutator.isLoading,
        }}
      >
        <FormBaseConhecimento
          value={editObject as any}
          onChange={setEditObject}
          fieldErrors={fieldErrors}
        />
      </Modal>
      <VBox gap="16px" style={{ height: 'calc(100vh - 32px)' }}>
        {/* Header */}
        <HBox className={styles.header}>
          <VBox>
            <Titulo>Documentos (Base de Conhecimento)</Titulo>
            <Descricao>
              Estes documentos irão ajudar seus atendentes e os agentes de IA a
              entenderem melhor seu negócio e seus processos internos.
            </Descricao>
          </VBox>
          <HBox gap="12px" className={styles.headerAcoes}>
            <Botao
              variant="outline-primary"
              icon={mdiMagnify}
              onClick={() => setShowSemanticSearch(true)}
            >
              Busca semântica
            </Botao>
            <Botao
              variant="primary"
              icon={mdiPlus}
              onClick={handleOpenModalNewObject}
            >
              Novo
            </Botao>
          </HBox>
        </HBox>

        {/* Filtros */}
        <HBox>
          <CampoTexto
            prepend={
              <Icon
                path={mdiMagnify}
                size={1}
                color="var(--tc-color-gray-500)"
              />
            }
            placeholder="Pesquisar"
            value={search}
            onChange={setSearch}
          />
        </HBox>

        {queryList.isFetching && (
          <div>
            Aguarde...{' '}
            <Icon
              path={mdiLoading}
              size="14px"
              color="var(--tc-color-gray-700)"
              spin
            />
          </div>
        )}
        {!queryList.isFetching && (
          <div>
            Exibindo {queryFlatList?.length ?? '0'} de {total ?? '0'}{' '}
            resultados.
          </div>
        )}

        <DataTable
          isLoading={queryList.isFetching}
          cabecalhos={headers}
          linhas={queryFlatList ?? []}
          hasMoreData={queryList.hasNextPage}
          onLoadMore={async () => queryList.fetchNextPage()}
        />
      </VBox>
    </LayoutUsuario>
  );
};

export default Page;
