import styles from './VBox.module.css';

const VBox = ({
  children,
  gap,
  style,
  center,
  vcenter,
}: Readonly<{
  children: React.ReactNode;
  gap?: number | string;
  style?: React.CSSProperties;
  center?: boolean;
  vcenter?: boolean;
}>) => {
  return (
    <div
      style={{
        ...(style ?? {}),
        gap,
        alignItems: center ? 'center' : undefined,
        justifyContent: vcenter ? 'center' : undefined,
      }}
      className={styles.vbox}
    >
      {children}
    </div>
  );
};

export default VBox;
