import CampoCheckbox from '@/components/Formulario/CampoCheckbox';
import CampoSeletor from '@/components/Formulario/CampoSeletor';
import CampoTexto from '@/components/Formulario/CampoTexto';
import VBox from '@/components/layout/VBox';
import Descricao from '@/components/tipografia/Descricao';
import { useAuth } from '@/lib/useAuth';
import { hasAuthorization, rolesManager } from '@tera/shared/src/lib/auth';

export const FormChatMensagemAtalho = ({
  value,
  onChange,
  list,
  fieldErrors,
}: {
  value: any;
  onChange: any;
  list: any[];
  fieldErrors: any;
}) => {
  const { user } = useAuth();
  const scopedList = list.filter(it => !!it.global === !!value?.global);

  const newObj = !value?.id || value?.id < 0;
  const maxItemList = newObj ? scopedList.length + 1 : scopedList.length;

  const indices = Array.from({ length: maxItemList }, (_, i) => ({
    id: (i + 1).toString(),
    label: <span>{i + 1}</span>,
  }));

  return (
    <VBox gap="16px">
      <CampoTexto
        value={value?.atalho ?? ''}
        onChange={v => onChange({ ...value, atalho: v })}
        label="Descrição breve"
        error={fieldErrors?.atalho}
      />
      <CampoTexto
        value={value?.conteudo}
        onChange={v => onChange({ ...value, conteudo: v })}
        label="Texto"
        error={fieldErrors?.conteudo}
      />

      <CampoSeletor
        value={value?.indice}
        onChange={v => onChange({ ...value, indice: v })}
        label="Posição"
        options={indices}
        error={fieldErrors?.indice}
      />

      <CampoCheckbox
        disabled={!hasAuthorization(user, rolesManager)}
        value={value?.global ?? false}
        onChange={v => onChange({ ...value, global: v })}
        text="Visibilidade global"
        error={fieldErrors?.global}
      />
      <Descricao>
        Atalhos globais são visíveis para todos os atendentes. Atalhos pessoais
        são visíveis apenas para você.
      </Descricao>
    </VBox>
  );
};
