export const parseTelefone = (v: string, ddd: string = '??'): string => {
  if (!v) return v;

  let telefone = v.replace(/[^0-9]+/g, '').replace('55', '');
  if (telefone[0] === '0') telefone = telefone.slice(1);
  if (telefone.length === 8 && ['9', '8'].includes(telefone[0]))
    telefone = '9' + telefone;
  if (telefone.length === 9) telefone = ddd + telefone;
  if (telefone.length === 11 && !['9', '8'].includes(telefone[3]))
    telefone = telefone.slice(0, 2) + telefone.slice(3);
  if (telefone.length === 11 && telefone[2] !== '9')
    telefone = telefone.slice(0, 2) + '9' + telefone.slice(3);

  return telefone.replace(/(\d{2})(\d{4,5})(\d{4})/, '($1) $2-$3');
};

export const throttle = (func: any, delay: number = 100) => {
  let lastCall = 0;
  return (...args: any[]) => {
    const now = new Date().getTime();
    if (now - lastCall < delay) {
      return;
    }
    lastCall = now;
    return func(...args);
  };
};

export const dateWithoutTimezone = (date: Date) => {
  const tzoffset = date.getTimezoneOffset() * 60000;
  const withoutTimezone = new Date(date.valueOf() - tzoffset)
    .toISOString()
    .slice(0, -1);
  return withoutTimezone;
};
