import React from 'react';
import styles from './CardRelatorio.module.css';
import Subtitulo from '../../components/tipografia/Subtitulo';
import VBox from '../../components/layout/VBox';
import Modal from '../../components/layout/Modal';
import VisualizadorRelatorio from './view/VisualizadorRelatorio';
import HBox from '../../components/layout/HBox';
import Botao from '../../components/Botao';
import Icon from '@mdi/react';
import { mdiEye, mdiTableEye } from '@mdi/js';

type CardRelatorioProps = {
  titulo?: string;
  children?: React.ReactNode;
  style?: React.CSSProperties;
  mostrarVisualizador?: boolean;
  aoFecharVisualizador?: () => void;
  filtroVisualizador?: any;
  metricasVisualizador?: string[];
  dimensoesVisualizador?: string[];
  hrefVisualizador?: string;
  notaVisualizador?: React.ReactNode;
};

const CardRelatorio = ({
  titulo,
  children,
  style,
  mostrarVisualizador,
  aoFecharVisualizador,
  filtroVisualizador,
  metricasVisualizador,
  dimensoesVisualizador,
  hrefVisualizador,
  notaVisualizador,
}: CardRelatorioProps) => {
  const [abrirModal, setAbrirModal] = React.useState(false);
  return (
    <div className={styles.container} style={style}>
      <Modal
        hideControls
        size="lg"
        title={`Visualizar dados - ${titulo}`}
        show={abrirModal}
        onClose={() => {
          console.log('fechar');
          setAbrirModal(false);
          aoFecharVisualizador?.();
        }}
        onCancel={() => {
          console.log('fechar');
          setAbrirModal(false);
          aoFecharVisualizador?.();
        }}
      >
        <VisualizadorRelatorio
          filtro={filtroVisualizador}
          metricas={metricasVisualizador}
          dimensoes={dimensoesVisualizador}
          href={hrefVisualizador}
          nota={notaVisualizador}
        />
      </Modal>
      <VBox center gap="16px">
        <HBox stretch style={{ justifyContent: 'end' }}>
          <div style={{ flex: 1 }}>
            {titulo && <Subtitulo>{titulo}</Subtitulo>}
          </div>
          {mostrarVisualizador && (
            <Botao
              title="Visualizar dados"
              variant="none-primary"
              onClick={() => {
                setAbrirModal(true);
              }}
            >
              <Icon path={mdiTableEye} size="16px" />
            </Botao>
          )}
        </HBox>
        {children}
      </VBox>
    </div>
  );
};

export default CardRelatorio;
