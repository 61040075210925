import React, { forwardRef } from 'react';
import styles from './Card.module.css';

type CardProps = {
  children?: React.ReactNode;
  style?: React.CSSProperties;
} & React.HTMLAttributes<HTMLDivElement>;

const Card = forwardRef(
  ({ children, style, ...props }: CardProps, ref: any) => {
    return (
      <div ref={ref} className={styles.container} style={style} {...props}>
        {children}
      </div>
    );
  },
);

Card.displayName = 'Card';

export default Card;
