import HBox from '../layout/HBox';
import VBox from '../layout/VBox';
import styles from './CampoCheckbox.module.css';

type Props = {
  label?: string;
  text: string;
  value: boolean;
  onChange: (value: boolean) => void;
  disabled?: boolean;
  [key: string]: any;
};

const CampoCheckbox = ({
  label,
  text,
  value,
  onChange,
  disabled,
  ...props
}: Readonly<Props>) => {
  return (
    <label className={styles.container}>
      <VBox>
        <div>{label}</div>
        <HBox>
          <input
            {...props}
            disabled={disabled}
            className={styles.input}
            type="checkbox"
            checked={value ?? false}
            onChange={e => onChange(e.target.checked)}
          />
          <span className={disabled ? styles.disabledText : undefined}>
            {text}
          </span>
        </HBox>
      </VBox>
    </label>
  );
};

export default CampoCheckbox;
