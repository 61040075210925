import React, { useEffect } from 'react';
import styles from './FiltroCanal.module.css';
import { useQuery } from 'react-query';
import CampoMultiSeletor from '../Formulario/CampoMultiSeletor';

type FiltroCanalProps = {
  value: string[];
  onChange: (value: string[]) => void;

  disableQuery?: boolean;
  onQueryStatusChanged?: (
    status: 'loading' | 'error' | 'success' | 'idle',
  ) => void;
};

const FiltroCanal = ({
  value,
  onChange,
  disableQuery,
  onQueryStatusChanged,
}: FiltroCanalProps) => {
  const queryGroups = useQuery(
    ['canais'],
    async () => {
      const res = await fetch(`/api/canal/box`);
      return res.json();
    },
    {
      staleTime: 1000 * 60 * 15,
      enabled: !disableQuery,
    },
  );

  useEffect(() => {
    onQueryStatusChanged?.(queryGroups.status);
  }, [queryGroups.status]);

  return (
    <CampoMultiSeletor
      placeholder="Filtrar por Canal"
      options={
        queryGroups.data?.result?.map((it: any) => ({
          id: it.id,
          label: it.nome,
        })) ?? []
      }
      value={value}
      onChange={onChange}
    />
  );
};

export default FiltroCanal;
