import portProps from '@tera/shared/src/fluxoConfig/ports/datetimeOperator'

type Props = {
  value: string | null
  onChange: (value: string) => void
}

const Port = ({ value, onChange }: Props) => {
  return <select style={{ width: '100%' }} value={value ?? ''} onChange={(e) => onChange(e.target.value)}>
    <option value="equals">Igual a</option>
    <option value="notEquals">Diferente de</option>
    <option value="greaterThan">Depois de</option>
    <option value="lessThan">Antes de</option>
    <option value="greaterThanOrEqual">Depois de ou igual a</option>
    <option value="lessThanOrEqual">Antes de ou igual a</option>
  </select>
}

const port = {
  ...portProps,
  render({ value, onChange }: Props) {
    return <Port value={value} onChange={onChange} />
  }
}

export default port