import CampoTexto from '../../components/Formulario/CampoTexto';
import Grid, { GridItem } from '../../components/layout/Grid';
import axios from 'axios';
import { useQuery } from 'react-query';
import CampoSeletor from '../../components/Formulario/CampoSeletor';
import CampoSenha from '../../components/Formulario/CampoSenha';
import { DiaExpediente } from './DiaExpediente';
import AlertBox from '@/components/layout/AlertBox';

export const FormGrupoUsuario = ({
  value,
  onChange,
  fieldErrors,
}: {
  value: any;
  onChange: any;
  fieldErrors: any;
}) => {
  const newObj = !value?.id || value?.id < 0;

  const estiloFilaOptions = [
    { id: 'unica', label: 'Sequencial' },
    { id: 'paralela', label: 'Paralela' },
  ];
  return (
    <Grid>
      <div style={{ position: 'absolute', height: 0 }}>
        <input
          type="text"
          name="username"
          style={{
            height: 0,
            width: 0,
            border: 'none',
            outline: 'none',
            margin: 0,
            padding: 0,
          }}
        />
        <input
          type="password"
          name="password"
          style={{
            height: 0,
            width: 0,
            border: 'none',
            outline: 'none',
            margin: 0,
            padding: 0,
          }}
        />
      </div>
      <GridItem sm={12} md={6}>
        <CampoTexto
          value={value?.nome ?? ''}
          onChange={v => onChange({ ...value, nome: v })}
          label="Nome"
          error={fieldErrors?.nome}
        />
      </GridItem>
      <GridItem sm={12} md={6}>
        <CampoSeletor
          noSearch
          value={value?.tipo_fila}
          onChange={v => onChange({ ...value, tipo_fila: v })}
          label="Estilo fila atendimento"
          options={estiloFilaOptions ?? []}
          error={fieldErrors?.tipo_fila}
          tooltipPosition="left"
          tooltipText={
            <div>
              <div>
                Sequencial: O atendimento é oferecido aos atendentes, um a um.{' '}
              </div>
              <div>Paralela: Atendimento simultâneo.</div>
            </div>
          }
        />
      </GridItem>
      <GridItem sm={12}>
        <CampoTexto
          value={value?.descricao_curta ?? ''}
          onChange={v => onChange({ ...value, descricao_curta: v })}
          label="Descrição curta"
          error={fieldErrors?.descricao_curta}
          tooltipPosition="left"
          tooltipText="✨Esta descrição ajudará os agentes de IA a entenderem melhor o setor em que estão atuando."
          placeholder='Ex: "Atendimento ao cliente"'
        />
      </GridItem>

      <GridItem sm={12}>
        <div>Expediente</div>
        <table>
          <tr>
            <td>Domingo</td>
            <td>
              <DiaExpediente
                value={value?.expediente?.dom}
                onChange={v =>
                  onChange({
                    ...value,
                    expediente: { ...(value?.expediente ?? {}), dom: v },
                  })
                }
              />
            </td>
          </tr>
          <tr>
            <td>Segunda-feira</td>
            <td>
              <DiaExpediente
                value={value?.expediente?.seg}
                onChange={v =>
                  onChange({
                    ...value,
                    expediente: { ...(value?.expediente ?? {}), seg: v },
                  })
                }
              />
            </td>
          </tr>
          <tr>
            <td>Terça-feira</td>
            <td>
              <DiaExpediente
                value={value?.expediente?.ter}
                onChange={v =>
                  onChange({
                    ...value,
                    expediente: { ...(value?.expediente ?? {}), ter: v },
                  })
                }
              />
            </td>
          </tr>
          <tr>
            <td>Quarta-feira</td>
            <td>
              <DiaExpediente
                value={value?.expediente?.qua}
                onChange={v =>
                  onChange({
                    ...value,
                    expediente: { ...(value?.expediente ?? {}), qua: v },
                  })
                }
              />
            </td>
          </tr>
          <tr>
            <td>Quinta-feira</td>
            <td>
              <DiaExpediente
                value={value?.expediente?.qui}
                onChange={v =>
                  onChange({
                    ...value,
                    expediente: { ...(value?.expediente ?? {}), qui: v },
                  })
                }
              />
            </td>
          </tr>
          <tr>
            <td>Sexta-feira</td>
            <td>
              <DiaExpediente
                value={value?.expediente?.sex}
                onChange={v =>
                  onChange({
                    ...value,
                    expediente: { ...(value?.expediente ?? {}), sex: v },
                  })
                }
              />
            </td>
          </tr>
          <tr>
            <td>Sábado</td>
            <td>
              <DiaExpediente
                value={value?.expediente?.sab}
                onChange={v =>
                  onChange({
                    ...value,
                    expediente: { ...(value?.expediente ?? {}), sab: v },
                  })
                }
              />
            </td>
          </tr>
        </table>
      </GridItem>
    </Grid>
  );
};
