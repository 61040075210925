export const parseTelefone = (v: string, ddd: string = "??"): string => {
  if (!v) return v;

  let telefone = v.replace(/[^0-9]+/g, "").replace("55", "");
  if (telefone[0] === "0") telefone = telefone.slice(1);
  if (telefone.length === 8 && ["9", "8"].includes(telefone[0]))
    telefone = "9" + telefone;
  if (telefone.length === 9) telefone = ddd + telefone;
  if (telefone.length === 10 && ["9", "8"].includes(telefone[2]))
    telefone = telefone.slice(0, 2) + "9" + telefone.slice(2);
  if (telefone.length === 11 && !["9", "8"].includes(telefone[3]))
    telefone = telefone.slice(0, 2) + telefone.slice(3);
  if (telefone.length === 11 && telefone[2] !== "9")
    telefone = telefone.slice(0, 2) + "9" + telefone.slice(3);

  return telefone.replace(/(\d{2})(\d{4,5})(\d{4})/, "($1) $2-$3");
};

export const parseTelefoneZap = (v: string, ddd: string = "??"): string => {
  if (!v) return v;

  let telefone = v.replace(/[^0-9]+/g, "").replace("55", "");
  if (telefone[0] === "0") telefone = telefone.slice(1);
  if (telefone.length === 8 || telefone.length === 9) telefone = ddd + telefone;
  if (telefone.length === 11)
    telefone = telefone.slice(0, 2) + telefone.slice(3);

  return `55` + telefone;
};

export const formatSeconds = (value: number) => {
  const hours = Math.floor((value / 3600) % 24);
  const minutes = Math.floor((value % 3600) / 60);
  const seconds = value % 60;

  const days = Math.floor(value / 3600 / 24);
  return `${days > 0 ? `${days}d ` : ""}${hours}h ${days == 0 ? `${minutes}m ` : ""}${days == 0 ? `${seconds}s` : ""}`;
};
