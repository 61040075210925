import React, { ReactNode, createContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import ApiCall from '../lib/apiCall';

interface AuthWrapperProps {
  children: ReactNode;
}

export interface AuthState {
  user: any,
  loading: boolean
}

export const AuthContext = createContext({ user: null, loading: true });

const AuthWrapper: React.FC<AuthWrapperProps> = ({ children }) => {
  const [auth, setAuth] = useState<AuthState>({ user: null, loading: true });

  useEffect(() => {
    ApiCall.get('/api/auth/me')
    .then(data => {
      setAuth({ user: data.user, loading: false });
    })
    .catch(error => {
      console.error(error)
      setAuth({ user: null, loading: false });
    });
  }, []);
  const navigate = useNavigate();


  useEffect(() => {
    if (!auth.loading && !auth.user) {
      navigate('/login?r=' + encodeURIComponent(window.location.pathname + window.location.search));
    }
  }, [auth.user, auth.loading, navigate]);

  if (auth.loading)
    return <div style={{ width: '100vw', height: '100vh', display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
      <div style={{textAlign: 'center'}}>
        <img src="/logo.png" alt="Logo Terachat" />
        <div style={{ fontSize: '32px', color: 'var(--tc-color-secondary)' }}>Carregando...</div>
      </div>
    </div>;

  return <AuthContext.Provider value={auth}>{children}</AuthContext.Provider>;
};

export default AuthWrapper;