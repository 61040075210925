import { NodeType } from "../../../types/NodeType";

const instance: NodeType = {
  type: "teraClientMetadata",
  label: "Informações do cliente",
  description: "Retorna informações personalizadas do cliente",
  category: "Fluxo",
  inputs() {
    const result = [
      { type: "string", name: "rotulo", label: "Rótulo" },
      { type: "portType", name: "portType", label: "Tipo do valor" },
    ];

    return result;
  },
  outputs(inputValues) {
    const list: any[] = [];

    if (inputValues?.portType?.id) {
      list.push({
        type: inputValues.portType.id,
        name: "value",
        label: "Valor",
      });
    }
    return list;
  },
  async resolveOutputs(inputValues, outConnections, context) {
    return null;
  },
};

export default instance;
