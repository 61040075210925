import { useEffect, useRef, useState } from 'react';
import ReactDOM from 'react-dom';
import styles from './Tooltip.module.css';

export const Tooltip = ({
  children,
  position = 'left',
  text,
}: {
  children: React.ReactNode;
  position?: 'top' | 'bottom' | 'left' | 'right';
  text: React.ReactNode;
}) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const tooltipRef = useRef<HTMLDivElement>(null);

  const [show, setShow] = useState(false);

  useEffect(() => {
    if (!containerRef.current) return;

    const container = containerRef.current;
    const tooltip = tooltipRef.current;

    const show = () => {
      setShow(true);
    };

    const hide = () => {
      setShow(false);
    };

    const adjust = () => {
      if (!show || !tooltip) return;

      const rect = container.getBoundingClientRect();

      if (position === 'top') {
        tooltip.style.top = `${rect.top - tooltip.offsetHeight - 8}px`;
        tooltip.style.left = `${rect.left + rect.width / 2 - tooltip.offsetWidth / 2}px`;
        tooltip.style.bottom = 'auto';
        tooltip.style.right = 'auto';
      } else if (position === 'bottom') {
        tooltip.style.top = `${rect.top + rect.height + 8}px`;
        tooltip.style.left = `${rect.left + rect.width / 2 - tooltip.offsetWidth / 2}px`;
      } else if (position === 'left') {
        tooltip.style.top = `${rect.top + rect.height / 2 - tooltip.offsetHeight / 2}px`;
        tooltip.style.left = 'auto';
        tooltip.style.bottom = 'auto';
        tooltip.style.right = `${window.innerWidth - rect.left + 8}px`;
      } else {
        tooltip.style.top = `${rect.top + rect.height / 2 - tooltip.offsetHeight / 2}px`;
        tooltip.style.left = `${rect.left + rect.width + 8}px`;
      }
    };
    adjust();

    container.addEventListener('mouseenter', show);
    container.addEventListener('mouseleave', hide);
    window.addEventListener('resize', adjust);

    return () => {
      container.removeEventListener('mouseenter', show);
      container.removeEventListener('mouseleave', hide);
      window.removeEventListener('resize', adjust);
    };
  }, [show, position, text]);

  return (
    <div ref={containerRef} className={styles.tooltip}>
      {children}
      {show &&
        ReactDOM.createPortal(
          <div ref={tooltipRef} className={styles.tooltipText}>
            {text}
          </div>,
          document.querySelector('#root')!,
        )}
    </div>
  );
};
