import portProps from '@tera/shared/src/fluxoConfig/ports/route'

type Props = {
  
}

const Port = ({}: Props) => {
  return <></>
}

const port = { 
  ...portProps,
  render({}: Props) {
    return <Port />
  }
}

export default port