import Modal from './layout/Modal';
import React, { ReactNode } from 'react';
import Botao from './Botao';

type BotaoVariants = 'danger' | 'none-danger' | 'outline-danger';

const BotaoPerigoso = ({ title, confirmation, children, variant = 'danger', onClick, icon, iconProps, disabled, type = 'button', ...props } : Readonly<{
    children?: React.ReactNode;
    variant?: BotaoVariants;
    onClick?: () => void;
    icon?: string;
    iconProps?: { [key: string]: any };
    disabled?: boolean;
    type?: 'button' | 'submit' | 'reset';
    title?: string;
    confirmation?: ReactNode;
    [key: string]: any;
}>) => {
    const [showModal, setShowModal] = React.useState(false)

    const handleConfirm = () => {
        onClick?.()
        setShowModal(false)
    }

    return (
        <>
        <Modal
            show={showModal}
            onCancel={() => setShowModal(false)}
            title={title ?? 'Confirmação'}
            onClose={() => setShowModal(false)}
            onConfirm={handleConfirm}
            confirmProps={{
                variant: 'danger'
            }}
        >
            <span>{confirmation ?? 'Tem certeza?'}</span>
        </Modal>
        <Botao 
            variant={variant} 
            onClick={() => setShowModal(true)} 
            icon={icon} 
            iconProps={iconProps} 
            disabled={disabled} 
            type={type} 
            {...props}
        >
            {children}
        </Botao>
        </>
    )
}
export default BotaoPerigoso;