import { NodeType } from "../../../types/NodeType";

const instance: NodeType = {
  type: "AIAttendant",
  label: "✨ Atendente virtual (IA)",
  description: "Escala um atendente virtual para prosseguir com o atendimento",
  category: "IA",
  inputs(inputValues) {
    const result = [
      { type: "route", name: "route", label: "Entr. fluxo" },
      { type: "domainIAAgente", name: "agente", label: "Agente IA" },
      {
        type: "string",
        name: "mensagemBoasVindas",
        label: "Mens. boas-vindas",
      },
      {
        type: "string",
        name: "informacoesExtras",
        label: "Informações extras",
      },
    ];

    const re = /\{\{([^\}]+?)\}\}/g;

    const ids: string[] = [];
    let res: RegExpExecArray | null;

    if (inputValues?.mensagemBoasVindas) {
      const template = inputValues.mensagemBoasVindas;
      while ((res = re.exec(template)) !== null) {
        if (!ids.includes(res[1])) ids.push(res[1]);
      }
    }

    if (inputValues?.informacoesExtras) {
      const template = inputValues.informacoesExtras;
      while ((res = re.exec(template)) !== null) {
        if (!ids.includes(res[1])) ids.push(res[1]);
      }
    }

    if (ids.length)
      result.push(
        ...ids.map((id) => ({ type: "string", name: id, label: id }))
      );

    return result;
  },
  outputs(inputValues) {
    const result = [
      {
        type: "number",
        name: "departamento",
        label: "Departamento (redirecionamento)",
      },
      {
        type: "route",
        name: "route-redir",
        label: "Redirecionar atendimento",
      },
      {
        type: "route",
        name: "route-limit",
        label: "Lim. interações excedido",
      },
      {
        type: "route",
        name: "route",
        label: "Atendimento finalizado",
      },
    ];

    return result;
  },
  async resolveOutputs(inputs, outConnections, context, localVars) {
    return null;
  },
};

export default instance;
