import React from 'react';
import styles from './Grid.module.css';

type GridItemProps = {
  children: React.ReactNode;
  p?: 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12;
  sm?: 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12;
  md?: 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12;
  lg?: 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12;
  rows?: number;
  className?: string;
};

const GridItem = ({
  children,
  p,
  sm,
  md,
  lg,
  rows,
  className,
}: GridItemProps) => {
  return (
    <div
      className={[
        styles.item,
        styles[`item-sm-${sm}`],
        styles[`item-md-${md}`],
        styles[`item-lg-${lg}`],
        styles[`item-p-${p}`],
        className,
      ].join(' ')}
      style={{ gridRow: rows ? `span ${rows}` : 'auto' }}
    >
      {children}
    </div>
  );
};

type GridProps = {
  children: React.ReactNode;
  style?: React.CSSProperties;
};
const Grid = ({ children, style }: GridProps) => {
  return (
    <div className={styles.container} style={style}>
      {children}
    </div>
  );
};

export default Grid;
export { GridItem };
