import { NodeType } from '../../../types/NodeType'

const instance: NodeType = {
  type: 'extractTimestamp',
  label: 'Extrai tempo a partir de um texto',
  description: 'Tempo',
  category: 'Tempo',
  inputs() {
    return [
      { type: 'string', name: 'string', label: 'Texto' }
    ]
  },
  outputs() {
    return [
      { type: 'timestamp', name: 'timestamp', label: 'Tempo' }
    ]
  },
  async resolveOutputs() {
    return null
  }
}

export default instance