// @ts-ignore
import { portTypes } from '@tera/shared/src/fluxoConfig/ports/portType'
// @ts-ignore
import themes from '@tera/shared/src/fluxoConfig/themes'
import CampoTexto from '../../components/Formulario/CampoTexto'
import Icon from '@mdi/react'
import { mdiCircle, mdiPlus, mdiTrashCan } from '@mdi/js'
import Botao from '../../components/Botao'
import VBox from '../../components/layout/VBox'
import BotaoPerigoso from '../../components/BotaoPerigoso'
import CampoSeletor from '../../components/Formulario/CampoSeletor'
import Grid, { GridItem } from '../../components/layout/Grid'

export type IntegracaoApiEndpointSaida = {
    nome: string,
    caminho_jsonpath: string,
    tipo: string,
    valor_padrao?: string
}

export type FormApiSaidasProps = {
    value: IntegracaoApiEndpointSaida[],
    onChange: (value: IntegracaoApiEndpointSaida[]) => void,
    fieldErrors: Record<string, string> | null
}

export const FormApiSaidas = ({ value, onChange, fieldErrors }: FormApiSaidasProps) => {
    const tiposSaidaOptions = portTypes.map(it => ({ 
        id: it.type, 
        label: <span>
            <Icon path={mdiCircle} size="14px" color={themes.common.ports[it.type as keyof typeof themes.common.ports]?.color as string ?? 'gray'} /> {it.label}
        </span> 
    }))

    const handleAddSaida = () => {
        onChange([
            ...(value ?? []),
            {
                nome: '',
                tipo: '',
                caminho_jsonpath: ''
            }
        ])
    }

    const handleDelSaida = (idx: number) => {
        onChange([
            ...(value ?? []).filter((_:any, i:number) => i !== idx)
        ])
    }

    return (
        <VBox>
            <div style={{ textAlign: 'end'}}>
                <Botao variant='none-primary' icon={mdiPlus} onClick={handleAddSaida}>Adicionar saída</Botao>
            </div>

            {value?.map((it: any, idx: number) => (
                <Grid key={idx}>
                    <GridItem sm={3}>
                        <CampoTexto
                            value={it.nome}
                            onChange={(v) => onChange([
                                    ...(value ?? []).map((e:any, i:number) => i === idx ? { ...e, nome: v, caminho_jsonpath: `$.${v.replace(/[^0-9a-zA-Z-_]/g, '_')}` } : e)
                                ])}
                            label='Nome'
                            error={fieldErrors?.[`[${idx}].nome`]}
                        />
                    </GridItem>
                    <GridItem sm={3}>
                        <CampoTexto
                            value={it.caminho_jsonpath}
                            onChange={(v) => onChange([
                                ...(value ?? []).map((e:any, i:number) => i === idx ? { ...e, caminho_jsonpath: v } : e)
                            ])}
                            placeholder='JSONPath. Ex: $.caminho.ate.valor'
                            label='Caminho'
                            error={fieldErrors?.[`[${idx}].caminho_jsonpath`]}
                        />
                    </GridItem>
                    <GridItem sm={3}>
                        <CampoSeletor
                            options={tiposSaidaOptions}
                            value={it.tipo}
                            onChange={(v) => onChange([
                                ...(value ?? []).map((e:any, i:number) => i === idx ? { ...e, tipo: v } : e)
                            ])}
                            label='Tipo'
                            error={fieldErrors?.[`[${idx}].tipo`]}
                        />
                    </GridItem>
                    <GridItem sm={2}>
                        <CampoTexto
                            value={it.valor_padrao}
                            onChange={(v) => onChange([
                                    ...(value ?? []).map((e:any, i:number) => i === idx ? { ...e, valor_padrao: v } : e)
                                ])}
                            label='Vl. Padrão'
                            error={fieldErrors?.[`[${idx}].valor_padrao`]}
                        />
                    </GridItem>
                    <GridItem sm={1}>
                        <label>&nbsp;</label>
                        <div>
                            <BotaoPerigoso
                                variant='none-danger'
                                icon={mdiTrashCan}
                                onClick={() => handleDelSaida(idx)}
                            ></BotaoPerigoso>
                        </div>
                    </GridItem>
                </Grid>
            
            ))}
        </VBox>
    )
}
