import { NodeType } from '../../../types/NodeType'

const instance: NodeType = {
  type: 'group',
  label: 'Departamento',
  description: 'Departamento',
  category: 'Domínio',
  inputs() {
    return [
      { type: 'domainGroup', name: 'domainGroup', label: 'Departamento' },
    ]
  },
  outputs() {
    return [
      { type: 'number', name: 'number', label: 'ID' },
      { type: 'string', name: 'nome', label: 'Nome' }
    ]
  },
  async resolveOutputs() {
    return null
  }
}

export default instance