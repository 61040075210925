import { NodeType } from '../../../types/NodeType'

const instance: NodeType = {
  type: 'activitySendEmail',
  label: 'Enviar e-mail',
  description: 'Envia um e-mail',
  category: 'Chat',
  inputs(inputValues) {
    const result = [
      { type: 'route', name: 'route', label: 'Entr. fluxo' },
      { type: 'domainChannelEmail', name: 'canal_email', label: 'Canal E-mail' },
      { type: 'string', name: 'titulo', label: 'Título' },
      { type: 'string', name: 'email_para', label: 'E-mail destinatário' },
      { type: 'string', name: 'mensagem', label: 'Mensagem' },
    ]

    if (inputValues?.mensagem) {
      const template = inputValues.mensagem
      const re = /\{\{([^\}]+?)\}\}/g

      const ids: string[] = []
      let res: RegExpExecArray | null
      while ((res = re.exec(template)) !== null) {
        if (!ids.includes(res[1])) ids.push(res[1]);
      }
      if (ids.length)
        result.push(...ids.map(id => ({ type: 'string', name: id, label: id })))
    }

    return result
  },
  outputs(/* inputValues, context */) {
    return [
      { type: 'route', name: 'route-true', label: 'Sucesso' },
      { type: 'route', name: 'route-false', label: 'Erro' },
    ]
  },
  async resolveOutputs() {
    return null
  }
}

export default instance