import boolean from './boolean'
import datetime from './datetime'
import list from './list'
import number from './number'
import object from './object'
import string from './string'
import timestamp from "./timestamp"

export const portTypes = [
  boolean,
  datetime,
  list,
  number,
  object,
  string,
  timestamp
]

export const typesList = portTypes.map(it => ({
  id: it.type,
  nome: it.label
}))

export default {
  type: 'portType',
  label: 'Tipo de porta',
  shape: 'circle',
  hidePort: true
}