import { NodeType } from '../../types/NodeType'

const instance: NodeType = {
  type: 'newFlow',
  label: 'Novo fluxo de atendimento',
  description: 'Cria um novo fluxo de atendimento',
  category: 'Fluxo',
  inputs() {
    const result = [
      { type: 'route', name: 'route', label: 'Entr. fluxo' },
      { type: 'number', name: 'id', label: 'ID' },
      { type: 'string', name: 'canal_cliente_id', label: 'ID do cliente no canal' },
      { type: 'string', name: 'canal_cliente_nome', label: 'Nome do cliente no canal' },
      { type: 'object', name: 'params', label: 'Parâmetros' },
    ]

    return result
  },
  outputs() {
    return [
      { type: 'route', name: 'route', label: 'Saída fluxo' },
      { type: 'route', name: 'route-err', label: 'Erro' },
    ]
  },
  async resolveOutputs() {
    return null
  }
}

export default instance