import { CSSProperties } from 'react';
import styles from './Marcador.module.css';
import Icon from '@mdi/react';
import { mdiTab, mdiTag } from '@mdi/js';

type Props = {
  color?: string;
  style?: CSSProperties;
  children: React.ReactNode;
  onClick?: () => void;
};

const Marcador = ({ color, children, style, onClick }: Props) => {
  return (
    <div
      style={{
        ...style,
        backgroundColor: color,
        cursor: onClick ? 'pointer' : 'default',
      }}
      className={[styles.marcador].join(' ')}
      onClick={onClick}
    >
      <Icon path={mdiTag} size="12px" color="var(--tc-color-gray-500)" />
      {children}
    </div>
  );
};

export default Marcador;
