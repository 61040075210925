import { useQuery } from 'react-query';
import CardRelatorio from './CardRelatorio';
import Valor from './Valor';
import axios from 'axios';
import Skeleton from '../../components/layout/Skeleton';
import { translateFilter } from './util';

type Props = {
  periodo: [string, string, string];
  canal_id: number[];
};

const CardRelatorioTotalCDA = ({ periodo, canal_id }: Props) => {
  const q = useQuery(
    ['totalCDA', periodo, canal_id],
    async () => {
      const qs = new URLSearchParams();
      qs.append('facts', 'qtd_clientes');
      qs.append('dimensions', 'data_criacao_dia');
      qs.append('filter', translateFilter({ periodo, canal_id }));

      console.log('periodo', periodo);
      console.log('translated', translateFilter({ periodo, canal_id }));

      const res = await axios.get(
        '/api/relatorios/chat_mensagem?' + qs.toString(),
      );

      console.log('SLA query', qs.toString());
      console.log('SLA', res.data.result);

      return (
        res.data.result?.reduce(
          (s: number, v: any) => s + +v.qtd_clientes,
          0,
        ) ?? 0
      );
    },
    { refetchOnWindowFocus: false },
  );

  return (
    <CardRelatorio
      titulo="Clientes Diários Ativos"
      mostrarVisualizador={true}
      filtroVisualizador={{ periodo, canal_id }}
      metricasVisualizador={['qtd_clientes']}
      dimensoesVisualizador={['data_criacao_dia']}
      hrefVisualizador="/api/relatorios/chat_mensagem"
    >
      {q.isFetching ? <Skeleton format="box" /> : <Valor valor={q.data} />}
    </CardRelatorio>
  );
};

export default CardRelatorioTotalCDA;
