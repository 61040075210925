import { CSSProperties } from 'react';
import styles from './AlertBox.module.css';

type AlertBoxProps = {
    children: React.ReactNode;
    variant: AlertVariants;
    style?: CSSProperties
}

type AlertVariants = 'info' | 'success' | 'warning' | 'danger';

const AlertBox = ({ children, variant, style } : AlertBoxProps) => {
    return (
        <div 
            style={style}
            className={[styles.alert, styles[variant]].join(' ')} 
            role="alert"
        >
            {children}
        </div>
    )
}

export default AlertBox;