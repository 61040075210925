import Icon from '@mdi/react';
import Botao from '../../components/Botao';
import styles from './TituloChat.module.css'
import { mdiBellRing, mdiLanDisconnect, mdiLanPending, mdiReload } from '@mdi/js';

type TituloChatProps = {
    wsState: 'disconnected' | 'connecting' | 'connected' | 'error',
    loading: boolean,
    onReloadChats: () => void
    notificationStatus?: NotificationPermission
    onAskPermission?: () => void
}

const TituloChat = ({ wsState, loading, notificationStatus, onAskPermission, onReloadChats }: TituloChatProps) => {
    return (
        <div className={styles.container}>
            <div className={styles.titulo}>Chats</div>
            {/*wsState === 'connected' && <Botao variant="none-primary" icon={mdiLanCheck} />*/}
            {wsState === 'error' && <Icon path={mdiLanDisconnect} size="20px" color="var(--tc-color-danger)" title="Erro ao tentar conectar ao servidor"/>}
            {wsState === 'connecting' && <Icon path={mdiLanPending} size="20px" color="var(--tc-color-warning)" title="Conectando ao servidor..."/>}
            {wsState === 'disconnected' && <Icon path={mdiLanDisconnect} size="20px" color="var(--tc-color-warning)" title="Desconectado"/>}

            {notificationStatus === 'default' && <Botao variant="none-primary" icon={mdiBellRing} onClick={onAskPermission} />}
            <Botao variant="none-primary" icon={mdiReload} disabled={loading} iconProps={{ spin: loading }} onClick={() => onReloadChats()} />
        </div>
    )
}

export default TituloChat;