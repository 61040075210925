import portProps from '@tera/shared/src/fluxoConfig/ports/delay';

type Obj = { id: number; nome: string };

type Props = {
  value: Obj | null;
  onChange: (value: Obj | undefined) => void;
};

const list = [
  { id: 1, nome: '1 segundo' },
  { id: 2, nome: '2 segundos' },
  { id: 3, nome: '3 segundos' },
  { id: 4, nome: '4 segundos' },
  { id: 5, nome: '5 segundos' },
  { id: 7, nome: '7 segundos' },
  { id: 10, nome: '10 segundos' },
  { id: 15, nome: '15 segundos' },
  { id: 20, nome: '20 segundos' },
  { id: 30, nome: '30 segundos' },
];

const Port = ({ value, onChange }: Props) => {
  return (
    <select
      style={{ width: '100%' }}
      value={value?.id ?? 0}
      onChange={e =>
        onChange(list.find((it: Obj) => it.id === +e.target.value))
      }
    >
      <option value="0">0 segundos</option>
      {list.map(item => (
        <option key={item.id} value={item.id}>
          {item.nome}
        </option>
      ))}
    </select>
  );
};

const port = {
  ...portProps,
  render({ value, onChange }: Props) {
    return <Port value={value} onChange={onChange} />;
  },
};

export default port;
