import string from './string';
import stringCompare from './stringCompare';
import stringConcat from './stringConcat';
import extractCpf from './extractCpf';
import extractCnpj from './extractCnpj';
import extractTelefone from './extractTelefone';

import string2number from './string2number';
import extractEmail from './extractEmail';

import stringLength from './stringLength';

export default {
  string,
  stringCompare,
  stringConcat,
  extractCpf,
  extractCnpj,
  extractTelefone,
  extractEmail,
  string2number,
  stringLength
}
