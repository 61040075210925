import list from './list';
import itemByIndex from './itemByIndex';
import listAdd from './listAdd';
import newList from './newList';

export default {
  list,
  itemByIndex,
  listAdd,
  newList
};