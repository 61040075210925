import { NodeType } from '../../../types/NodeType'

const instance: NodeType = {
  type: 'activityWhisper',
  label: 'Enviar mensagem interna',
  description: 'Envia uma mensagem para a conversa, sem enviar uma mensagem ao cliente. Apenas os operadores podem ver a mensagem.',
  category: 'Chat',
  inputs(inputValues) {

    const result = [
      { type: 'route', name: 'route', label: 'Entr. fluxo' },
      { type: 'string', name: 'mensagem', label: 'Mensagem' }
    ]

    if (inputValues?.mensagem) {
      const template = inputValues.mensagem
      const re = /\{\{([^\}]+?)\}\}/g

      const ids: string[] = []
      let res: RegExpExecArray | null
      while ((res = re.exec(template)) !== null) {
        if (!ids.includes(res[1])) ids.push(res[1]);
      }
      if (ids.length)
        result.push(...ids.map(id => ({ type: 'string', name: id, label: id })))
    }

    return result
  },
  outputs(/* inputValues, context */) {
    return [
      { type: 'string', name: 'resposta', label: 'Resposta' },
      { type: 'route', name: 'route', label: 'Saída fluxo' }
    ]
  },
  async resolveOutputs(inputs, outConnections, context, localVars) {
    return localVars.resposta ? { resposta: localVars.resposta } : null
  }
}

export default instance