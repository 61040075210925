import { NodeType } from '../../../types/NodeType'

function isValidCPF(cpf) {
  if (typeof cpf !== 'string') return false
  cpf = cpf.replace(/[^\d]+/g, '')
  if (cpf.length !== 11 || !!cpf.match(/(\d)\1{10}/)) return false
  cpf = cpf.split('').map(el => +el)
  const rest = (count) => (cpf.slice(0, count - 12)
    .reduce((soma, el, index) => (soma + el * (count - index)), 0) * 10) % 11 % 10
  return rest(10) === cpf[9] && rest(11) === cpf[10]
}

const instance: NodeType = {
  type: 'extractCpf',
  label: 'Extrair CPF',
  description: 'Extrai o CPF de um texto',
  category: 'String',
  inputs() {
    return [
      { type: 'route', name: 'route', label: 'Entr. fluxo' },
      { type: 'boolean', name: 'formatar', label: 'Formatar' },
      { type: 'string', name: 'string', label: 'Texto' },
    ]
  },
  outputs() {
    return [
      { type: 'string', name: 'cpf', label: 'CPF' },
      { type: 'route', name: 'route', label: 'Saída fluxo' },
      { type: 'route', name: 'route-invalido', label: 'CPF inválido' }
    ]
  },
  async resolveOutputs(inputs) {
    const cpfMatch = inputs.string?.match(/([0-9\-\/\.]+)/g)
    if (cpfMatch == null) return { route: 'route-invalido' }

    for (const cpf of cpfMatch) {
      if (isValidCPF(cpf)) {
        if (inputs.formatar) {
          const cpfFormatado = cpf.replace(/[^0-9]+/g, '')
          return { cpf: `${cpfFormatado.substr(0, 3)}.${cpfFormatado.substr(3, 3)}.${cpfFormatado.substr(6, 3)}-${cpfFormatado.substr(9, 2)}`, route: 'route' }
        }

        return { cpf: cpf.replace(/[^0-9]+/g, ''), route: 'route' }
      }
    }
    return { route: 'route-invalido' }
  }
}

export default instance