import showdown from 'showdown';
import DOMPurify from 'dompurify';

const Markdown = ({ value }: { value: string }) => {
    const converter = new showdown.Converter({
        simpleLineBreaks: true,
    });
    const mdHtml = converter.makeHtml(value.replace(/^\*[^*]+?\*$/gm, ''));
    const purified = DOMPurify.sanitize(mdHtml);

    return <div dangerouslySetInnerHTML={{ __html: purified }} />
}

export default Markdown;