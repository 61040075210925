import { CampoPersonalizacao } from '@/components/Formulario/CampoPersonalizacao';
import CampoSeletor from '@/components/Formulario/CampoSeletor';
import CampoTexto from '@/components/Formulario/CampoTexto';
import Grid, { GridItem } from '@/components/layout/Grid';
import VBox from '@/components/layout/VBox';
import axios from 'axios';
import { useQuery } from 'react-query';
import { contatoTipoOptions } from '../cliente/contatoTipoOptions';
import AlertBox from '@/components/layout/AlertBox';

type Props = {
  value: any;
  onChange: any;
  enabled: boolean;
};

export const FormNovoCliente = ({ value, onChange, enabled }: Props) => {
  const qClienteMetadados = useQuery(
    'cliente/meta',
    async () => {
      const res = await axios.get('/api/cliente/meta', {
        withCredentials: true,
      });

      return res.data.result;
    },
    {
      enabled: enabled,
    },
  );

  const qCliente = useQuery(
    ['cliente/box', value?.nome],
    async () => {
      const res = await axios.get(
        '/api/cliente/box?nome_eq=' + encodeURIComponent(value?.nome ?? ''),
        {
          withCredentials: true,
        },
      );

      return res.data.result;
    },
    {
      enabled: enabled,
    },
  );

  return (
    <VBox>
      <Grid>
        <GridItem sm={12}>
          <CampoTexto
            value={value?.nome}
            onChange={v =>
              onChange({
                ...value,
                nome: v,
              })
            }
            label="Nome"
          />
        </GridItem>
        {qClienteMetadados.data?.map((it: any) => (
          <GridItem sm={12} key={it.nome}>
            <CampoPersonalizacao
              label={it.rotulo}
              type={it.tipo}
              value={value?.metadados?.[it.nome]}
              onChange={() =>
                onChange({
                  ...value,
                  metadados: {
                    ...(value?.metadados ?? {}),
                    [it.nome]: it.valor_padrao,
                  },
                })
              }
              disabled={qCliente.data?.length > 0}
            />
          </GridItem>
        ))}
        <GridItem sm={12} md={4}>
          <CampoSeletor
            disabled
            options={contatoTipoOptions}
            value={value?.contato_tipo}
            onChange={(v: string) =>
              onChange({
                ...value,
                contato_tipo: v,
              })
            }
            label="Canal"
          />
        </GridItem>
        <GridItem sm={12} md={8}>
          <CampoTexto
            disabled
            value={value?.contato_valor}
            onChange={(v: string) =>
              onChange({
                ...value,
                contato_valor: v,
              })
            }
            label="Identificador"
          />
        </GridItem>
      </Grid>
      {qCliente.isFetching && <AlertBox variant="info">Aguarde...</AlertBox>}

      {!qCliente.isFetching && qCliente.data?.length > 0 && (
        <AlertBox variant="info">
          Existe um cliente com este nome. O contato será adicionado a este
          cliente.
        </AlertBox>
      )}
      {!qCliente.isFetching && qCliente.data?.length === 0 && (
        <AlertBox variant="info">Um novo cliente será criado.</AlertBox>
      )}
    </VBox>
  );
};
