import React, { useEffect } from 'react'
import CampoTexto from '../Formulario/CampoTexto'
import CampoSeletor from '../Formulario/CampoSeletor'
import Grid, { GridItem } from '../layout/Grid'
import { CanalDto } from '@tera/shared/src/dto/CanalDto'
import VBox from '../layout/VBox'
import CampoSenha from '../Formulario/CampoSenha'
import AlertBox from '../layout/AlertBox'
import CampoCheckbox from '../Formulario/CampoCheckbox'
import { parseTelefone } from '../../lib/util'
import Botao from '../Botao'
import { mdiKeyPlus } from '@mdi/js'

import { nanoid } from 'nanoid'
import CodeBlock from '../layout/CodeBlock'
import Modal from '../layout/Modal'

type FormCanalProps = {
    host?: string
    value: CanalDto
    onChange: (data: any) => void
    fieldErrors: Record<string, string> | null
}

const tipos: any[] = [
    { label: 'Whatsapp', id: 'wa360' },
    { label: 'Telegram', id: 'telegram' },
    { label: 'E-mail', id: 'email' },
    { label: 'Webchat', id: 'webchat' },
]

const FormCamposWhatsapp = ({ value, onChange, fieldErrors }: FormCamposProps) => {
    const [ channelId, apiKey, phoneNumber ] = value ? value.split(':') : []

    return (
    <Grid>
        <GridItem sm={12} md={4}>
            <CampoTexto readOnly disabled label="Telefone" type="text" defaultValue={parseTelefone(phoneNumber)} autoFill="do-not-autofill-please" />
        </GridItem>
        <GridItem sm={12} md={8}>
            <AlertBox variant="info">
                <span>Para editar mais detalhes de canais Whatsapp, utilize a opção <b>Gerenciar Whatsapp</b> do botão <b>Gerenciar canais</b>.</span>
            </AlertBox>
        </GridItem>
    </Grid>
)}

const FormCamposTelegram = ({ value, onChange, fieldErrors }: FormCamposProps) => (
    <>
        <CampoSenha label="ID do Bot" value={value} onChange={onChange} autoFill="do-not-autofill-please" error={fieldErrors?.['chave_api']} />
    </>
)


const FormCamposWebchat = ({ host, value, onChange, fieldErrors }: FormCamposProps) => {
    const [showModal, setShowModal] = React.useState(false)
    const apikey = value

    const generate = () => {
        onChange?.(nanoid(32))
    }

    useEffect(() => {
        if (!value?.length) {
            generate()
        }
    }, [])

    const openModal = () => {
        setShowModal(true)
    }
    const closeModal = () => { 
        setShowModal(false)
    }

    const onConfirm = () => {
        generate()
        closeModal()
    }
    return (
        <>
            <Modal
                show={showModal}
                title="Gerar nova chave API"
                size="sm"
                onClose={closeModal}
                onCancel={closeModal}
                onConfirm={onConfirm}
                confirmProps={{ label: 'Sim, Gerar nova chave', variant: 'warning' }}
            >
                <p>Trocar a chave pode invalidar scripts já instalados, e tornar estas telas de webchat indisponíveis.</p>
                <p>Tem certeza que deseja gerar uma nova chave API?</p>
            </Modal>
            <CampoTexto 
                label="Chave API do webchat" 
                value={value} 
                onChange={onChange} 
                autoFill="do-not-autofill-please" 
                error={fieldErrors?.['chave_api']}
                append={
                    <Botao variant='none-warning' icon={mdiKeyPlus} size="16" onClick={openModal} />
                }
            />

            <AlertBox variant="info">
                <p>Segue um exemplo de código para incorporar o webchat em seu site, no final do conteúdo:</p>
                <CodeBlock value={`
<!-- Inicio webchat -->
<style>
.__tc-btn {
    position: fixed;
    bottom: 10px;
    right: 10px;
    height: 48px;
    width: 48px;
    border-radius: 50%;
    background-color: #103668;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}
.__tc-container {
    display: none; 
    border: 1px solid #bbffbb; 
    border-radius: 8px; 
    position:fixed; 
    bottom:60px; 
    right: 15px; 
    height:calc(80vh - 30px); 
    width: 20vw; 
    min-width: 280px; 
    z-index: 10000
}
</style>
<script>
    var $id = document.getElementById.bind(document);
    function _t() { var el = $id('tc-webchat'); if (el.style.display === 'block') { el.style.display = 'none'; } else { el.style.display = 'block'; } }
</script>
<div  class="__tc-btn" onclick="_t()">
    <img src="${host ?? ''}/logo.png" alt="open webchat" style="height: 40px;" />
</div>
<iframe id="tc-webchat" src="${host ?? ''}/webchat?apikey=${apikey ?? '[APIKEY]'}" class="__tc-container"></iframe>
<!-- Fim webchat -->
        `} />
            </AlertBox>
        </>
    )
}

type FormCamposProps = {
    host?: string;
    value?: string;
    onChange?: (value: string) => void;
    fieldErrors: Record<string, string> | null;
}
const FormCamposEmail = ({ host, value, onChange, fieldErrors }: FormCamposProps) => {
    const data = value ? JSON.parse(value) : {
        email: ""
      }

    return (
    <Grid>
        <GridItem sm={12}>
            {fieldErrors?.['chave_api'] && <div style={{ color: 'var(--tc-color-danger)' }}>{fieldErrors?.['chave_api']}</div>}
            <CampoTexto label="E-mail" value={data?.email ?? ''} onChange={(v) => onChange?.(JSON.stringify({ ...data, email: v }))} autoFill="do-not-autofill-please" error={fieldErrors?.['chave_api.email']} />
        </GridItem>
        <GridItem sm={12} md={6}>
            <h5>Dados de Entrada (IMAP)</h5>
            <Grid>
                <GridItem sm={12} md={8}>
                    <CampoTexto label="Servidor SMTP" value={data?.imap?.servidor ?? ''} onChange={(v) => onChange?.(JSON.stringify({ ...data, imap: { ...data?.imap, servidor: v } }))} autoFill="do-not-autofill-please" error={fieldErrors?.['chave_api.imap.servidor']} />
                </GridItem>
                <GridItem sm={12} md={4}>
                    <CampoTexto label="Porta" value={data?.imap?.porta ?? ''} onChange={(v) => onChange?.(JSON.stringify({ ...data, imap: { ...data?.imap, porta: +v } }))} autoFill="do-not-autofill-please" error={fieldErrors?.['chave_api.imap.porta']} />
                </GridItem>

                <GridItem sm={12}>
                    <CampoCheckbox text="SSL" value={data?.imap?.ssl ?? false} onChange={(v) => onChange?.(JSON.stringify({ ...data, imap: { ...data?.imap, ssl: v } }))} autoFill="do-not-autofill-please" error={fieldErrors?.['chave_api.imap.ssl']} />
                </GridItem>

                <GridItem sm={12}>
                    <CampoTexto label="Usuário" value={data?.imap?.usuario ?? ''} onChange={(v) => onChange?.(JSON.stringify({ ...data, imap: { ...data?.imap, usuario: v } }))} autoFill="do-not-autofill-please" error={fieldErrors?.['chave_api.imap.usuario']} />
                </GridItem>

                <GridItem sm={12}>
                    <CampoSenha label="Senha" value={data?.imap?.senha ?? ''} onChange={(v) => onChange?.(JSON.stringify({ ...data, imap: { ...data?.imap, senha: v } }))} autoFill="do-not-autofill-please" error={fieldErrors?.['chave_api.imap.senha']} />
                </GridItem>
            </Grid>
        </GridItem>
        <GridItem sm={12} md={6}>
            <h5>Dados de Saída (SMTP)</h5>
            <Grid>
                <GridItem sm={12} md={8}>
                    <CampoTexto label="Servidor SMTP" value={data?.smtp?.servidor ?? ''} onChange={(v) => onChange?.(JSON.stringify({ ...data, smtp: { ...data?.smtp, servidor: v } }))} autoFill="do-not-autofill-please" error={fieldErrors?.['chave_api.smtp.servidor']} />
                </GridItem>
                <GridItem sm={12} md={4}>
                    <CampoTexto label="Porta" value={data?.smtp?.porta ?? ''} onChange={(v) => onChange?.(JSON.stringify({ ...data, smtp: { ...data?.smtp, porta: +v } }))} autoFill="do-not-autofill-please" error={fieldErrors?.['chave_api.smtp.porta']} />
                </GridItem>
                <GridItem sm={12}>
                    <CampoCheckbox text="SSL" name="smtp_ssl" value={data?.smtp?.ssl ?? false} onChange={(v) => onChange?.(JSON.stringify({ ...data, smtp: { ...data?.smtp, ssl: v } }))} autoFill="do-not-autofill-please" error={fieldErrors?.['chave_api.smtp.ssl']} />
                </GridItem>

                <GridItem sm={12}>
                    <CampoTexto label="Usuário" value={data?.smtp?.usuario ?? ''} onChange={(v) => onChange?.(JSON.stringify({ ...data, smtp: { ...data?.smtp, usuario: v } }))} autoFill="do-not-autofill-please" error={fieldErrors?.['chave_api.smtp.usuario']} />
                </GridItem>

                <GridItem sm={12}>
                    <CampoSenha label="Senha" value={data?.smtp?.senha ?? ''} onChange={(v) => onChange?.(JSON.stringify({ ...data, smtp: { ...data?.smtp, senha: v } }))} autoFill="do-not-autofill-please" error={fieldErrors?.['chave_api.smtp.senha']} />
                </GridItem>
            </Grid>
        </GridItem>
    </Grid>
)
}

const camposPorTipo: Record<string, ({ value, onChange, fieldErrors }: FormCamposProps) => React.ReactElement> = {
    wa360: ({ host, value, onChange, fieldErrors }) => <FormCamposWhatsapp host={host} value={value} onChange={onChange} fieldErrors={fieldErrors} />,
    telegram: ({ host, value, onChange, fieldErrors }) => <FormCamposTelegram host={host} value={value} onChange={onChange} fieldErrors={fieldErrors} />,
    email: ({ host, value, onChange, fieldErrors }) => <FormCamposEmail host={host} value={value} onChange={onChange} fieldErrors={fieldErrors}/>,
    webchat: ({ host, value, onChange, fieldErrors }) => <FormCamposWebchat host={host} value={value} onChange={onChange} fieldErrors={fieldErrors} />,
}

type CamposFormProps = {
    tipo?: string;
} & FormCamposProps

const CamposForm = ({ tipo, host, value, onChange, fieldErrors }: CamposFormProps) => {
    return tipo && camposPorTipo[tipo] ? camposPorTipo[tipo]({ host, value, onChange, fieldErrors }) : <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '48px', border: '1px solid var(--tc-color-gray-100)', backgroundColor: 'var(--tc-color-gray-200)' }}>Selecione um tipo para continuar</div>
}

const FormCanal = ({ host, value, onChange, fieldErrors }: FormCanalProps) => {
    const editMode = !!value?.id && value.id > 0

    return (
        <VBox gap="8px">
            <Grid>
                <GridItem sm={12} md={2}>
                    <CampoTexto disabled readOnly label="Código" type="text" name="id" defaultValue={value?.id ?? '(Novo)'} autoFill="do-not-autofill-please" />
                </GridItem>
                <GridItem sm={12} md={4}>
                    <CampoSeletor
                        disabled={editMode}
                        label="Tipo" 
                        name="tipo" 
                        options={tipos}
                        value={value?.tipo} 
                        onChange={(v) => onChange({ ...value, tipo: v })}
                        autoFill="do-not-autofill-please"
                        error={fieldErrors?.[`tipo`]}
                    />
                </GridItem>
                <GridItem sm={12} md={6}>
                    <CampoTexto label="Nome" type="text" name="nome" value={value?.nome ?? ''} onChange={(v) => onChange({ ...value, nome: v })} autoFill="do-not-autofill-please" error={fieldErrors?.[`nome`]} />
                </GridItem>
            </Grid>
            <input type="text" name="username" style={{ position: 'absolute', height: 0, border: 'none', outline: 'none', opacity: 0 }} />
            <input type="password" name="password" style={{ position: 'absolute', height: 0, border: 'none', outline: 'none', opacity: 0 }} />
            <CamposForm tipo={value?.tipo} host={host} value={value?.chave_api} onChange={(v) => onChange({ ...value, chave_api: v })} fieldErrors={fieldErrors} />
        </VBox>
    )
}

export default FormCanal
