import { mdiInformationSlabCircleOutline } from '@mdi/js';
import HBox from '../layout/HBox';
import { Tooltip } from '../layout/Tooltip';
import styles from './CampoTexto.module.css';
import Icon from '@mdi/react';
import React from 'react';

type Props = {
  value?: string;
  onChange?: (value: string) => void;
  label?: string;
  placeholder?: string;
  required?: boolean;
  error?: string;
  tooltipText?: React.ReactNode;
  tooltipPosition?: 'top' | 'bottom' | 'left' | 'right';
  noBorder?: boolean;
  rows?: number;
  [key: string]: any;
};

export default function CampoMemo({
  value,
  onChange,
  label,
  placeholder,
  required,
  error,
  tooltipText,
  tooltipPosition,
  noBorder,
  rows,
  ...props
}: Readonly<Props>) {
  return (
    <div style={{ width: '100%' }}>
      <label>
        <HBox stretch>
          <span>{label}</span>
          {tooltipText && (
            <Tooltip text={tooltipText} position={tooltipPosition}>
              <Icon
                path={mdiInformationSlabCircleOutline}
                size="14px"
                color="var(--tc-color-info)"
              />
            </Tooltip>
          )}
        </HBox>
      </label>
      <textarea
        {...props}
        className={[styles.container, error ? styles.error : null].join(' ')}
        style={{
          padding: '4px',
          width: '100%',
          border: noBorder ? 'none' : undefined,
        }}
        rows={rows}
        value={value}
        onChange={e => onChange?.(e.target.value)}
        placeholder={placeholder}
        required={required}
      />
      {error && <div className={styles.error}>{error}</div>}
    </div>
  );
}
