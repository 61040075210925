import { NodeType } from '../../../types/NodeType'

const instance: NodeType = {
  type: 'params',
  label: 'Parâmetros',
  description: 'Retorna parâmetros do fluxo, inseridos ao iniciar',
  category: 'Fluxo',
  inputs() {
    const result = [
      { type: 'string', name: 'nome', label: 'Nome' },
      { type: 'portType', name: 'portType', label: 'Tipo de item' },
    ]

    return result
  },
  outputs(inputValues) {
    const list: any[] = []

    if (inputValues?.portType?.id) {
      list.push({ type: inputValues.portType.id, name: 'value', label: 'Valor' })
    }
    return list
  },
  async resolveOutputs(inputValues, outConnections, context) {
    return { value: context?.params?.[inputValues.nome] }
  }
}

export default instance