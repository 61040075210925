import { mdiCamera, mdiChevronLeft, mdiDownload } from '@mdi/js';
import Icon from '@mdi/react';
import { createRef, useEffect, useRef, useState } from 'react';
import styles from './Selfie.module.css';

type Props = {
  active: boolean;
  onSelfieTaken: (file: File) => void;
};

const Selfie = ({ active, onSelfieTaken }: Props) => {
  const [imageURL, setImageURL] = useState('');
  const [cameraStarted, setCameraStarted] = useState(false);
  const [permissionStatus, setPermissionStatus] = useState('default');

  const streamRef = useRef();
  const videoEl = createRef<HTMLVideoElement>();
  const canvasEl = createRef<HTMLCanvasElement>();
  const imageEl = createRef<HTMLImageElement>();

  useEffect(() => {
    if (!active && cameraStarted) {
      stopCamera();
    } else if (active && !cameraStarted) {
      startCamera();
    }
  }, [active]);

  const startCamera = async () => {
    if (navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {
      try {
        const stream = await navigator.mediaDevices.getUserMedia({
          video: true,
        });

        if (videoEl.current) {
          videoEl.current.srcObject = stream;
        }
        setCameraStarted(true);
        setPermissionStatus('granted');
      } catch (err: any) {
        if ((err?.message?.indexOf('denied') ?? -1) > -1) {
          setPermissionStatus('denied');
        } else {
          setPermissionStatus('default');
        }
      }
    }
  };

  const takeSelfie = async () => {
    if (
      videoEl.current === null ||
      canvasEl.current === null ||
      imageEl.current === null
    )
      return;
    // Get the exact size of the video element.
    const width = videoEl.current.videoWidth;
    const height = videoEl.current.videoHeight;

    // get the context object of hidden canvas
    const ctx = canvasEl.current.getContext('2d');

    if (!ctx) return;

    // Set the canvas to the same dimensions as the video.
    canvasEl.current.width = width;
    canvasEl.current.height = height;

    // Draw the current frame from the video on the canvas.
    ctx.drawImage(videoEl.current, 0, 0, width, height);

    // Get an image dataURL from the canvas.
    canvasEl.current.toBlob((blob: Blob | null) => {
      if (!canvasEl.current || !blob) return;

      const imageDataURL = canvasEl.current.toDataURL('image/png');

      setImageURL(imageDataURL);
      stopCamera();
      onSelfieTaken(new File([blob], 'selfie.png', { type: 'image/png' }));
    }, 'image/png');
  };

  const stopCamera = () => {
    setCameraStarted(false);

    if (!videoEl.current?.srcObject) return;

    const stream: MediaStream = videoEl.current?.srcObject as MediaStream;
    const tracks = stream.getTracks();

    tracks.forEach(track => {
      track.stop();
    });
  };

  const backToCam = () => {
    setImageURL('');
    startCamera();
  };

  return (
    <div className={styles.selfie}>
      <div
        className={styles.cam}
        style={{
          display:
            imageURL === '' && permissionStatus === 'granted'
              ? 'block'
              : 'none',
        }}
      >
        <video
          width="100%"
          height="100%"
          className={styles.videoPlayer}
          autoPlay={true}
          ref={videoEl}
        ></video>
        <button
          className={`${styles.btn} ${styles.captureBtn}`}
          onClick={takeSelfie}
        >
          <Icon path={mdiCamera} size="2rem" />
        </button>
      </div>

      {permissionStatus === 'default' && (
        <div
          style={{
            textAlign: 'center',
            fontStyle: 'italic',
            color: '#888',
            height: '360px',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <Icon path={mdiCamera} size="4rem" color="gray" />
          <span>(Lembre de permitir o acesso a câmera para tirar fotos)</span>
        </div>
      )}

      {permissionStatus === 'denied' && (
        <div
          style={{
            textAlign: 'center',
            fontStyle: 'italic',
            color: '#888',
            height: '360px',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <Icon path={mdiCamera} size="4rem" color="gray" />
          <span>(O acesso a câmera é necessário para tirar fotos)</span>
        </div>
      )}

      <canvas ref={canvasEl} style={{ display: 'none' }}></canvas>

      <div
        className={styles.preview}
        style={{ display: imageURL !== '' ? 'block' : 'none' }}
      >
        <img className={styles.previewImg} src={imageURL} ref={imageEl} />

        <div className={styles.btnContainer}>
          <button
            className={`${styles.btn} ${styles.backBtn}`}
            onClick={backToCam}
          >
            <Icon path={mdiChevronLeft} size="1rem" />
          </button>
          <a
            href={imageURL}
            download="selfie.png"
            className={`${styles.btn} ${styles.downloadBtn}`}
          >
            <Icon path={mdiDownload} size="1rem" />
          </a>
        </div>
      </div>
    </div>
  );
};

export default Selfie;
