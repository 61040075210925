import React from 'react';

type TextoDataProps = {
    value: string | null | undefined;
    tamanho?: 'curto' | 'longo';
};

const TextoData = ({ value, tamanho = 'longo' }:TextoDataProps) => {
    if (!value) return <span></span>

    if (Number.isNaN(Date.parse(value))) return <span>{value}</span>

    const _dt = new Date(value).getTime()
    const str = new Date(value).toLocaleString()
    
    const formatterToday = Intl.DateTimeFormat('pt-BR', { hour: '2-digit', minute: '2-digit' })
    const rtf = new Intl.RelativeTimeFormat('pt-BR', { numeric: 'auto' });

    const elapsedTime = _dt - new Date().getTime();
    const daysDifference = Math.round(elapsedTime / (1000 * 60 * 60 * 24));

    if (daysDifference === 0)    return <span title={str}>{formatterToday.format(_dt)}</span>
        
    if (tamanho === 'curto') return <span title={str}>{rtf.format(daysDifference, 'day')}</span>

    return <span title={str}>{rtf.format(daysDifference, 'day')} ({formatterToday.format(_dt)})</span>
};

export default TextoData;