import Icon from '@mdi/react';
import Botao from '../../components/Botao';
import styles from './AlertasAtendimento.module.css';
import { mdiHeadset, mdiVolumeHigh } from '@mdi/js';
import React, { useCallback } from 'react';

type AlertaAtendimentoDto = {

}

type AlertasAtendimentoProps = {
    value: any[],
    userInteracted: boolean,
    onAtender: (chatId: number) => void
}
const AlertasAtendimento = ({ value, userInteracted, onAtender }: AlertasAtendimentoProps) => {
    const handleFilaAtenderClick = useCallback((chatId: number) => {
        console.log('handleFilaAtenderClick', chatId)
        onAtender(chatId)
        // socket.emit('fila.atender', { chat_id: chatId })
      }, [onAtender])

    const loopHandler = React.useRef<any>(null)
    const audioAtendimentoRef = React.useRef<HTMLAudioElement>(typeof window !== 'undefined' ?  new Audio('/sounds/atendimento.mp3') : null)
  
    React.useEffect(() => {
        if (!audioAtendimentoRef.current || !userInteracted) return
    
        if (value?.length > 0) {
          if (!loopHandler.current) {
            audioAtendimentoRef.current.play()
    
            loopHandler.current = setInterval(() => {
              if (!audioAtendimentoRef.current) return
    
              audioAtendimentoRef.current.play()
            }, 5000)
          }
        } else {
          if (loopHandler.current) {
            clearInterval(loopHandler.current)
            audioAtendimentoRef.current.pause()
            audioAtendimentoRef.current.currentTime = 0
            loopHandler.current = null
          }
        }
      }, [value?.length, userInteracted])

    return (
        <div>
            {value?.map((item, index) => (
                <div key={`alerta-atendimento-${index}`} className={styles.container} title="Este aviso está gerando o som">
                    <div className={styles.alerta}>
                        <span>Novo atendimento</span>
                        <Botao variant="primary" icon={mdiHeadset} onClick={() => handleFilaAtenderClick(item.id)}>Atender</Botao>
                        <Icon path={mdiVolumeHigh} size={1.25} color="var(--tc-color-gray-500)"/>
                    </div>
                </div>
            ))}
        </div>
    )
}

export default AlertasAtendimento;