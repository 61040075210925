import { NodeType } from '../../../types/NodeType'

const instance: NodeType = {
  type: 'splitDatetime',
  label: 'Separar Data/hora',
  description: 'Separa uma Data/hora',
  category: 'Data/hora',
  inputs() {
    return [
      { type: 'datetime', name: 'datetime', label: 'Data/hora' },
    ]
  },
  outputs() {
    return [
      { type: 'number', name: 'day', label: 'Dia' },
      { type: 'number', name: 'month', label: 'Mês' },
      { type: 'number', name: 'year', label: 'Ano' },
      { type: 'number', name: 'hour', label: 'Horas' },
      { type: 'number', name: 'minute', label: 'Minutos' },
      { type: 'number', name: 'second', label: 'Segundos' },
    ]
  },
  async resolveOutputs(inputs) {
    if (!inputs.datetime) return { day: 0, month: 0, year: 0, hour: 0, minute: 0, second: 0 }

    const dt = new Date(inputs.datetime)

    return {
      day: dt.getDate(),
      month: dt.getMonth() + 1,
      year: dt.getFullYear(),
      hour: dt.getHours(),
      minute: dt.getMinutes(),
      second: dt.getSeconds()
    }
  }
}

export default instance