import { NodeType } from '../../../types/NodeType'

const instance: NodeType = {
  type: 'boolean',
  label: 'Sim/Não',
  description: 'Sim/Não',
  category: 'Sim/Não',
  inputs() {
    return [
      { type: 'boolean', name: 'boolean', label: 'Sim/Não' }
    ]
  },
  outputs() {
    return [
      { type: 'boolean', name: 'boolean', label: 'Sim/Não' }
    ]
  },
  async resolveOutputs(inputs) {
    return { boolean: inputs.boolean }
  }
}

export default instance