import domainUser from './user';
import domainGroup from './group';
import domainFlow from './flow';
import domainChannelEmail from './channelEmail';
import domainTag from './tag';
import domainIAAgente from './ia_agente';

export default {
  domainUser,
  domainGroup,
  domainFlow,
  domainChannelEmail,
  domainTag,
  domainIAAgente,
};
