import React, { useCallback, useState } from 'react';
import VBox from '../../../components/layout/VBox';
import styles from './VisualizadorRelatorio.module.css';
import axios from 'axios';
import { useQuery } from 'react-query';
import { dateWithoutTimezone } from '../../../lib/util';
import Botao from '../../../components/Botao';
import CampoMultiSeletor from '../../../components/Formulario/CampoMultiSeletor';
import { ReportBuildMetadata } from '@tera/shared/src/types/ReportBuildMetadata';
import {
  mdiArrowRight,
  mdiChevronDown,
  mdiChevronUp,
  mdiFilter,
  mdiFilterCog,
} from '@mdi/js';
import Icon from '@mdi/react';
import { translateFilter } from '../util';
import HBox from '@/components/layout/HBox';
import Grid, { GridItem } from '@/components/layout/Grid';
import AlertBox from '@/components/layout/AlertBox';

type Props = {
  metricasDisponiveis?: {
    [key: string]: {
      nome: string;
      descricao: string;
      tipo: string;
      opcoes: string[];
    };
  };
  dimensoesDisponiveis?: {
    [key: string]: {
      nome: string;
      descricao: string;
      tipo: string;
      opcoes: string[];
    };
  };
  filtrosDisponiveis?: {
    [key: string]: {
      nome: string;
      descricao: string;
      tipo: string;
      opcoes: string[];
    };
  };
  metricas?: string[];
  dimensoes?: string[];
  filtro?: any;

  href?: string;
  nota?: React.ReactNode;
};

const ValorString = ({ valor }: { valor: string }) => {
  return <span>{valor}</span>;
};

const ValorNumero = ({ valor }: { valor: string }) => {
  return <span>{valor}</span>;
};

const ValorData = ({ valor }: { valor: string }) => {
  return <span>{valor}</span>;
};

const ValorBooleano = ({ valor }: { valor: string }) => {
  return <span>{valor === 'true' ? 'Verdadeiro' : 'Falso'}</span>;
};

const ValorRelatorio = ({ tipo, valor }: { tipo: string; valor: string }) => {
  switch (tipo) {
    case 'string':
      return <ValorString valor={valor} />;
    case 'number':
      return <ValorNumero valor={valor} />;
    case 'date': {
      const d = new Date(valor);
      return <ValorData valor={d.toLocaleDateString()} />;
    }
    case 'boolean':
      return <ValorBooleano valor={valor} />;
    default:
      return <span>{valor}</span>;
  }
};

const VisualizadorRelatorio = ({
  filtro,
  href,
  metricas,
  dimensoes,

  metricasDisponiveis,
  dimensoesDisponiveis,

  nota,
}: Props) => {
  const [showFilter, setShowFilter] = useState(false);
  const [metricasSelecionadas, setMetricasSelecionadas] = useState<string[]>(
    metricas ?? [],
  );
  const [dimensoesSelecionadas, setDimensoesSelecionadas] = useState<string[]>(
    dimensoes ?? [],
  );

  const qMetadata = useQuery(
    ['metadata', href],
    async () => {
      const res = await axios.get(href + '/metadata');
      return res.data.result;
    },
    {
      refetchOnWindowFocus: false,
    },
  );
  const metadata: ReportBuildMetadata = qMetadata.data;

  const q = useQuery(
    ['visualizador', metricasSelecionadas, dimensoesSelecionadas, filtro, href],
    async () => {
      const qs = new URLSearchParams();
      qs.append('facts', metricasSelecionadas.join(','));
      qs.append('dimensions', dimensoesSelecionadas.join(','));
      qs.append('filter', translateFilter(filtro));

      const res = await axios.get(href + '?' + qs.toString());

      return res.data.result;
    },
    {
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      refetchOnReconnect: false,
    },
  );

  const metricasOptions = metadata?.metrics
    ? Object.values(metadata.metrics).map(m => ({
        id: m.name,
        label: m.label,
      }))
    : [];

  const dimensoesOptions = metadata?.dimensions
    ? Object.values(metadata.dimensions).map(m => ({
        id: m.name,
        label: m.label,
      }))
    : [];

  const metricDownTo = useCallback(
    (metrica: string) => {
      if (!metadata?.metrics?.[metrica]?.downTo) return;

      const type = metadata.metrics[metrica].downTo!.type;
      const name = metadata.metrics[metrica].downTo!.name;

      const novasMetricas = metricasSelecionadas.filter(m => m !== metrica);

      if (type === 'dimension') {
        setDimensoesSelecionadas([name, ...dimensoesSelecionadas]);
      } else if (type === 'metric') {
        novasMetricas.unshift(name);
      }
      setMetricasSelecionadas(novasMetricas);
    },
    [metricasSelecionadas, dimensoesSelecionadas, metadata?.metrics],
  );

  return (
    <VBox>
      {nota && <AlertBox variant="info">{nota}</AlertBox>}
      {showFilter && (
        <Grid>
          <GridItem sm={12}>
            <AlertBox variant="warning">
              Modificar estes parâmetros alterará os dados exibidos no
              relatório.
            </AlertBox>
          </GridItem>
          <GridItem sm={12}>
            <CampoMultiSeletor
              label="Métricas"
              options={metricasOptions}
              value={metricasSelecionadas}
              onChange={setMetricasSelecionadas}
            />
          </GridItem>
          <GridItem sm={12}>
            <CampoMultiSeletor
              label="Dimensões"
              options={dimensoesOptions}
              value={dimensoesSelecionadas}
              onChange={setDimensoesSelecionadas}
            />
          </GridItem>
        </Grid>
      )}

      <HBox style={{ justifyContent: 'flex-end' }}>
        <Botao
          variant="outline-primary"
          onClick={() => setShowFilter(!showFilter)}
          icon={mdiFilterCog}
        >
          Alterar dados
          <Icon path={showFilter ? mdiChevronUp : mdiChevronDown} size="16px" />
        </Botao>
        <Botao
          onClick={() => {
            q.refetch();
            setShowFilter(false);
          }}
        >
          Atualizar
        </Botao>
      </HBox>

      <table className={styles.table}>
        <thead>
          <tr>
            <th>#</th>
            {metricasSelecionadas.map(m => (
              <th key={m}>{metadata?.metrics?.[m]?.label ?? m}</th>
            ))}
            {dimensoesSelecionadas.map(d => (
              <th key={d}>{metadata?.dimensions?.[d]?.label ?? d}</th>
            ))}
          </tr>
        </thead>
        <tbody>
          {q.isFetching ? (
            <tr>
              <td
                colSpan={
                  metricasSelecionadas.length + dimensoesSelecionadas.length + 2
                }
              >
                Carregando...
              </td>
            </tr>
          ) : (
            q.data?.map((d: any, idx: number) => (
              <tr key={idx}>
                <td>{idx + 1}</td>
                {metricasSelecionadas.map(m => (
                  <td key={`${m}-${idx}`}>
                    {metadata?.metrics?.[m]?.downTo ? (
                      <Botao
                        variant="none-primary"
                        onClick={() => metricDownTo(m)}
                      >
                        <ValorRelatorio
                          tipo={metadata.metrics[m].type}
                          valor={d[m]}
                        />
                        <Icon path={mdiArrowRight} size="14px" />
                      </Botao>
                    ) : (
                      <ValorRelatorio
                        tipo={metadata?.metrics?.[m]?.type}
                        valor={d[m]}
                      />
                    )}
                  </td>
                ))}
                {dimensoesSelecionadas.map(dd => (
                  <td key={`${dd}-${idx}`}>
                    <ValorRelatorio
                      tipo={metadata?.dimensions?.[dd]?.type}
                      valor={d[dd]}
                    />
                  </td>
                ))}
              </tr>
            ))
          )}
        </tbody>
      </table>
    </VBox>
  );
};

export default VisualizadorRelatorio;
