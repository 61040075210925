import Badge from '../../components/layout/Badge';
import { mdiTag } from '@mdi/js';
import Icon from '@mdi/react';

export const BadgeMarcadores = ({ marcadores }: { marcadores: string[] }) => {
  return marcadores
    ?.filter((m: any) => m !== 'TRANSFERIDO')
    ?.map((m: any) => (
      <Badge variant="light" key={m}>
        <Icon path={mdiTag} size="12px" />
        {m}
      </Badge>
    ));
};
