import { NodeType } from '../../../types/NodeType'

const instance: NodeType = {
  type: 'stringCompare',
  label: 'Condicional (String)',
  description: 'Compara duas cadeias de caracteres',
  category: 'String',
  inputs() {
    return [
      { type: 'route', name: 'route', label: 'Entr. fluxo' },
      { type: 'string', name: 'string1', label: 'valor 1' },
      { type: 'stringOperator', name: 'operator', label: 'operador', hidePort: true },
      { type: 'string', name: 'string2', label: 'valor 2' }
    ]
  },
  outputs() {
    return [
      { type: 'route', name: 'route-true', label: 'Verdadeiro' },
      { type: 'route', name: 'route-false', label: 'Falso' },
    ]
  },
  async resolveOutputs(inputs) {
    const v1 = inputs.string1?.toString() ?? ''
    const v2 = inputs.string2?.toString() ?? ''

    const op = inputs.operator ?? 'equals'

    switch (op) {
      case 'equals':
        if (v1 == v2) return { route: 'route-true' }
        break
      case 'notEquals':
        if (v1 != v2) return { route: 'route-true' }
        break
      case 'contains':
        if (v1.includes(v2)) return { route: 'route-true' }
        break
      case 'notContains':
        if (!v1.includes(v2)) return { route: 'route-true' }
        break
      case 'startsWith':
        if (v1.startsWith(v2)) return { route: 'route-true' }
        break
      case 'endsWith':
        if (v1.endsWith(v2)) return { route: 'route-true' }
        break
    }
    return { route: 'route-false' }
  }
}

export default instance