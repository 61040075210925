import { useState } from "react";

export const useLocalStorage = <T extends any>(key: string | null, initialValue?: T): [T | null, (value: T) => void] => {
    const [storedValue, setStoredValue] = useState<T | null>(() => {
        try {
        const item = window.localStorage.getItem(key ?? '');
        return item ? JSON.parse(item) : initialValue;
        } catch (error) {
        console.error(error);
        return initialValue;
        }
    });
    
    const setValue = (value: T) => {
        try {
        setStoredValue(value);
        window.localStorage.setItem(key ?? '', JSON.stringify(value));
        } catch (error) {
        console.error(error);
        }
    };
    
    return [storedValue, setValue];
};