import { NodeType } from '../../../types/NodeType'

const instance: NodeType = {
  type: 'datetime',
  label: 'Data/hora',
  description: 'Data/hora',
  category: 'Data/hora',
  inputs() {
    return [
      { type: 'datetime', name: 'datetime', label: 'Data/hora' }
    ]
  },
  outputs() {
    return [
      { type: 'datetime', name: 'datetime', label: 'Data/hora' }
    ]
  },
  async resolveOutputs(inputs) {
    return { datetime: inputs.datetime }
  }
}

export default instance