import Titulo from '../../components/tipografia/Titulo';
import styles from './page.module.css';
import LayoutUsuario from '../../components/navegacao/LayoutUsuario';
import Descricao from '../../components/tipografia/Descricao';
import HBox from '../../components/layout/HBox';
import Botao from '../../components/Botao';
import VBox from '../../components/layout/VBox';
import CampoTexto from '../../components/Formulario/CampoTexto';
import Icon from '@mdi/react';
import { mdiLoading, mdiMagnify } from '@mdi/js';
import { useEffect, useState } from 'react';
import { useMutation } from 'react-query';
import { FormPerfil } from '../../components/modelos/FormPerfil';
import { useAuth } from '../../lib/useAuth';
import { toast } from 'react-toastify';

type PageProps = {};

const Page = ({}: PageProps) => {
  const [model, setModel] = useState<any>(null);
  const [fieldErrors, setFieldErrors] = useState({});

  const user = useAuth().user;

  useEffect(() => {
    if (user) {
      setModel({ ...user });
    }
  }, [user]);

  const updateUserMutator = useMutation(
    async (data: any) => {
      setFieldErrors(prev => ({}));

      const {
        nome,
        /* email, */ senha,
        confirmar_senha,
        foto,
        foto_dados_crop,
        mostrar_notificacoes,
      } = data;

      const formData = new FormData();
      formData.append('nome', nome);
      //    formData.append('email', email)
      formData.append('foto_dados_crop', JSON.stringify(foto_dados_crop));
      formData.append('foto', foto);
      formData.append('mostrar_notificacoes', mostrar_notificacoes);

      if (senha?.length) {
        formData.append('senha', senha);
        formData.append('confirmar_senha', confirmar_senha);
      }

      const response = await fetch('/api/auth/me', {
        method: 'PUT',
        body: formData,
        credentials: 'same-origin',
      });
      const json = await response.json();
      return json;
    },
    {
      onSuccess: data => {
        if (data.ok) {
          const newModel = { ...(model ?? {}) };
          delete newModel.senha;
          delete newModel.confirmar_senha;
          setModel(newModel);

          toast('Perfil atualizado com sucesso!', {
            toastId: 'success',
            position: 'top-center',
            type: 'success',
            autoClose: 5000,
          });
        } else {
          toast('Ocorreu um erro ao tentar atualizar seu perfil.', {
            toastId: 'error',
            position: 'top-center',
            type: 'warning',
            autoClose: 5000,
          });
          setFieldErrors(data.fieldErrors);
        }
      },
      onSettled: data => {
        setTimeout(() => updateUserMutator.reset(), 3000);
      },
    },
  );

  const onSubmit = async (values: any) => {
    return updateUserMutator.mutateAsync(values);
  };

  return (
    <LayoutUsuario>
      <VBox gap="16px" style={{ height: 'calc(100vh - 32px)' }}>
        {/* Header */}
        <HBox>
          <VBox>
            <Titulo>Meu perfil</Titulo>
            <Descricao>
              Altere seus dados aqui. Informe apenas os dados que queira
              compartilhar com a gente e seu time.
            </Descricao>
          </VBox>
        </HBox>

        {/* Filtros */}
        <HBox></HBox>

        {/* Conteudo */}
        {model ? (
          <FormPerfil
            initialValues={model}
            onSubmit={onSubmit}
            fieldErrors={fieldErrors}
          />
        ) : (
          <p>
            <Icon path={mdiLoading} size={1} spin={1} />
            Carregando...
          </p>
        )}
      </VBox>
    </LayoutUsuario>
  );
};

export default Page;
