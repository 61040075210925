const list = [
  { id: 1, nome: '1 segundo' },
  { id: 2, nome: '2 segundos' },
  { id: 3, nome: '3 segundos' },
  { id: 4, nome: '4 segundos' },
  { id: 5, nome: '5 segundos' },
  { id: 7, nome: '7 segundos' },
  { id: 10, nome: '10 segundos' },
  { id: 15, nome: '15 segundos' },
  { id: 20, nome: '20 segundos' },
  { id: 30, nome: '30 segundos' },
]

export default {
  type: 'timer',
  label: 'Tempo',
  hidePort: true
}