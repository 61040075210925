import { NodeType } from "../../../types/NodeType";

const instance: NodeType = {
  type: "userById",
  label: "Buscar Usuário",
  description: "Busca um usuário da base pelo seu ID",
  category: "Domínio",
  inputs() {
    return [
      { type: "route", name: "route", label: "Entr. fluxo" },
      { type: "number", name: "id", label: "ID Usuário" },
    ];
  },
  outputs() {
    return [
      { type: "string", name: "name", label: "Nome" },
      { type: "number", name: "grupo_id", label: "ID Grupo" },
      { type: "string", name: "grupo_nome", label: "Nome grupo" },
      { type: "route", name: "route-true", label: "Usuário existe" },
      { type: "route", name: "route-false", label: "Usuário não existe" },
    ];
  },
  async resolveOutputs() {
    return null;
  },
};

export default instance;
