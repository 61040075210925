import { NodeType } from '../../../types/NodeType'

const instance: NodeType = {
  type: 'extractEmail',
  label: 'Extrair E-mail',
  description: 'Extrai o e-mail de um texto',
  category: 'String',
  inputs() {
    return [
      { type: 'route', name: 'route', label: 'Entr. fluxo' },
      { type: 'string', name: 'string', label: 'Texto' },
    ]
  },
  outputs() {
    return [
      { type: 'string', name: 'email', label: 'E-mail' },
      { type: 'route', name: 'route', label: 'Saída fluxo' },
      { type: 'route', name: 'route-invalido', label: 'E-mail inválido' }
    ]
  },
  async resolveOutputs(inputs) {
    const match = inputs.string?.match(/(\b[a-zA-Z0-9._-]+@[a-zA-Z0-9_-]+(?:\.[a-zA-Z0-9_-]+){1,5}\b)/g)

    if (match == null) return { route: 'route-invalido' }

    return { email: match[0], route: 'route' }
  }
}

export default instance