import CampoMultiSeletor from '../../components/Formulario/CampoMultiSeletor';
import Grid, { GridItem } from '../../components/layout/Grid';
import axios from 'axios';
import { useQuery } from 'react-query';

type FiltroTipoMensagemProps = {
  value: string[];
  onChange: (value: string[]) => void;
  idValues: string[];
  onChangeValues: (value: string[]) => void;
};

export const FiltroTipoMensagem = ({
  value,
  onChange,
  idValues,
  onChangeValues,
}: FiltroTipoMensagemProps) => {
  const q = useQuery(
    ['notificacoes:box'],
    async () => {
      const res = await axios.get<{ result: any[] }>(
        '/api/mensagem_programada/box',
        {
          withCredentials: true,
        },
      );
      return res.data;
    },
    {
      enabled: value.includes('notification'),
      staleTime: 0,
      cacheTime: 0,
    },
  );

  const options =
    q.data?.result?.map(item => ({
      id: item.id,
      label: item.nome,
    })) ?? [];

  return (
    <Grid>
      <GridItem sm={12} md={value.includes('notification') ? 6 : 12}>
        <CampoMultiSeletor
          noSearch
          label="Tipo de mensagem"
          placeholder="(Qualquer)"
          value={value}
          onChange={onChange}
          options={[
            { id: 'message', label: 'Mensagem' },
            { id: 'notification', label: 'Notificação' },
          ]}
        />
      </GridItem>
      {value.includes('notification') && (
        <GridItem sm={12} md={6}>
          <CampoMultiSeletor
            options={options}
            label="Modelos de mensagem"
            placeholder="(Qualquer)"
            value={idValues}
            onChange={onChangeValues}
          />
        </GridItem>
      )}
    </Grid>
  );
};
